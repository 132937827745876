import { makeStyles } from "@material-ui/core/styles";

export const useAdminReportsStyles = makeStyles((theme) => ({
  smallButton: {
    fontSize: 11,
    marginLeft: theme.spacing(1),
  },
  smallInput: {
    width: 200,
    "& .MuiOutlinedInput-root": {
      fontSize: 12,
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 8,
      paddingBottom: 6,
    },
  },
  inputPlaceholder: {
    "&::placeholder": {
      opacity: 1,
    },
  },
  green: {
    color: "#0AAF60",
  },
  red: {
    color: "#ff0000",
  },
  small: {
    fontSize: 12,
  },
  big: {
    fontSize: 14,
  },

  paper: {
    marginTop: theme.spacing(1),
    width: "100%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(3),
  },

  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end !important",
  },
}));
