import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Paper,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import PlayerIcon from "../../../../Shared/components/utils/PlayerIcon";
import PlayerDataRows from "./PlayerDataRows";
import PlayerTransactionForm from "./PlayerTransactionForm";
import {
  IPlayersTableRow,
  IPlayerTransaction,
} from "../../../constants/Interfaces";
import { IApiTransactionEntity } from "../../../../Shared/types/Transactions";
import { IAppState } from "../../../../Shared/store/Store";
import {
  IApiStorePlayersEntity,
  TStoreState,
} from "../../../../Shared/types/Stores";
import { postTransaction } from "../../../actions/TransactionsActions";
import { useAddPlayerTransactionStyles } from "./AddPlayerTransaction.styles";
import {
  addPlayerToStore,
  updateStorePlayersTransaction,
} from "../../../actions/StoresActions";
import Toast from "../../../../Shared/components/utils/Toast";
import { handleError } from "../../../../Shared/utils/ErrorHandler";
import { API_ERRORS } from "../../../constants/Api";
interface IAddPlayerTransaction {
  open: boolean;
  onClose: () => void;
  onSubmit: (inAmount: number, outAmount: number, warning?: boolean) => void;
  player: Partial<IPlayersTableRow>;
}

const AddPlayerTransaction: React.FC<IAddPlayerTransaction> = ({
  open,
  onClose,
  onSubmit,
  player,
}) => {
  const classes = useAddPlayerTransactionStyles();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [backendError, setBackendError] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );

  useEffect(() => {
    if (backendError) {
      toast.error(
        <Toast text={backendError} isError cleanup={() => setError("")} />
      );
    }
  }, [backendError]);

  if (!player) return <></>;

  const handleClose = () => {
    setError(undefined);
    setSubmitting(false);

    onClose?.();
  };

  const handleSubmit = async (values: IPlayerTransaction) => {
    setError(undefined);
    if (values.inAmount && values.outAmount) {
      setError(t("fillOnly1Field"));
      return;
    }

    if (!values.inAmount && !values.outAmount) {
      setError(t("fillAtLeast1Field"));
      return;
    }

    if (!player.playerId && !player.id) {
      return;
    }

    if (!storesState.selectedStore?.id) {
      // Store is not selected
      return;
    }

    setSubmitting(true);
    setError(undefined);

    const transaction: IApiTransactionEntity = {
      playerId: player.playerId || player.id,
      storeId: storesState.selectedStore.id,
      inAmount: values.inAmount,
      outAmount: values.outAmount,
    };

    try {
      const response: any = await dispatch(postTransaction(transaction));
      const newTransaction: IApiTransactionEntity = response.data;

      dispatch(updateStorePlayersTransaction(transaction));
      // check if player doesnt exists, that means its new player and we need to add it to storePlayers
      if (storesState.storePlayers && player) {
        const foundStorePlayer = storesState.storePlayers.find(
          (storePlayer) => storePlayer.playerId === player.playerId
        );
        if (!foundStorePlayer) {
          const newPlayer: Partial<IApiStorePlayersEntity> = {
            playerId: player.playerId,
            playerName: player.name ? player.name : "",
            playerFirstName: player.name ? player?.name.split(" ")[0] : "",
            playerLastName: player.name ? player?.name.split(" ")[1] : "",
            storeShiftInAmountSum: values.inAmount,
            storeShiftOutAmountSum: values.outAmount,
            latestTransactionInStore: new Date().toString(),
          };
          dispatch(addPlayerToStore(newPlayer));
        }
      }
      onSubmit(
        values.inAmount,
        values.outAmount,
        newTransaction?.hasToBeChecked
      );
    } catch (e: any) {
      if (
        e?.request?.status === 400 &&
        e?.data === API_ERRORS.ID_SHOULD_BE_CHECKED
      ) {
        onSubmit(values.inAmount, values.outAmount, true);
        return;
      }
      handleError(e?.request?.status, setBackendError);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <Close onClick={() => handleClose()} className={classes.dialogClose} />
      <DialogTitle disableTypography={true} className={classes.dialogTitle}>
        <Grid direction="row">
          <Typography variant="h4" className={classes.transactionTitle}>
            {player.name}
            <PlayerIcon size={30} player={player} />
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Paper elevation={0} className={classes.transactionRows}>
          <PlayerDataRows player={player} />
        </Paper>

        <PlayerTransactionForm
          submitting={submitting}
          error={error}
          setError={setError}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddPlayerTransaction;
