import { Reducer } from "redux";
import {
  initialStoresState,
  TStoresActions,
  TStoreState,
  EditCurrentStore,
  StoresGet,
  AvailableStoresGet,
  UpdateStorePlayerTransaction,
  StoreGetPlayers,
  AddPlayerToStore,
} from "../types/Stores";

export const storesReducer: Reducer<TStoreState, TStoresActions> = (
  state = initialStoresState,
  action
) => {
  switch (action.type) {
    case AvailableStoresGet.REQUEST:
    case StoresGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AvailableStoresGet.FAILED:
    case StoresGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    //TODO: remove if not used later
    case StoresGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        selectedStore: !state.selectedStore
          ? action.payload.data[0]
          : state.selectedStore,
        isLoading: false,
      };
    }
    case AvailableStoresGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        selectedStore: !state.selectedStore
          ? action.payload.data[0]
          : state.selectedStore,
        isLoading: false,
      };
    }
    case StoreGetPlayers.REQUEST: {
      return {
        ...state,
        isPlayersLoading: true,
      };
    }
    case StoreGetPlayers.FAILED: {
      return {
        ...state,
        isPlayersLoading: false,
      };
    }
    case StoreGetPlayers.SUCCESS: {
      return {
        ...state,
        storePlayers: action.payload.data.data,
        isPlayersLoading: false,
        totalStorePlayersPages: action.payload.data.totalPages,
      };
    }
    case UpdateStorePlayerTransaction.UPDATE_TRANSACTION: {
      const found = state.storePlayers?.find(
        (player) =>
          player.playerId === action.payload.playerId ||
          player.id === action.payload.playerId
      );
      if (!found) {
        return state;
      }
      return {
        ...state,
        storePlayers: state.storePlayers
          ? [
              {
                ...found,
                storeShiftInAmountSum:
                  found.storeShiftInAmountSum + action.payload.inAmount,
                storeShiftOutAmountSum:
                  found.storeShiftOutAmountSum + action.payload.outAmount,
                totalShiftInAmountSum:
                  (found.totalShiftInAmountSum || 0) + action.payload.inAmount,
                totalShiftOutAmountSum:
                  (found.totalShiftOutAmountSum || 0) +
                  action.payload.outAmount,
                latestTransactionInStore: new Date().toISOString(),
              },
              ...state.storePlayers?.filter(
                (rec) => rec.playerId !== action.payload.playerId
              ),
            ]
          : state.storePlayers,
      };
    }
    case EditCurrentStore.EDIT: {
      return {
        ...state,
        storePlayers: undefined,
        selectedStore: action.payload,
      };
    }
    case AddPlayerToStore.ADD_PLAYER: {
      const newPlayer = {
        ...action.payload,
        ...(action.payload.id ? { playerId: action.payload.id } : {}),
      };
      const foundplayer = state?.storePlayers?.find(
        (rec) => rec?.playerId === newPlayer?.playerId
      );
      const filteredPlayers = state?.storePlayers?.filter(
        (rec) => rec?.playerId !== newPlayer?.playerId
      );

      return {
        ...state,
        storePlayers: filteredPlayers
          ? [
              {
                ...newPlayer,
                storeShiftInAmountSum:
                  action.payload.storeShiftInAmountSum ||
                  foundplayer?.storeShiftInAmountSum ||
                  0,
                storeShiftOutAmountSum:
                  action.payload.storeShiftOutAmountSum ||
                  foundplayer?.storeShiftOutAmountSum ||
                  0,
              },
              ...filteredPlayers,
            ]
          : [newPlayer],
      };
    }
    default:
      return state;
  }
};
