import { Reducer } from "redux";
import {
  initialPlayersState,
  TPlayerActions,
  IApiPlayerEntity,
  TPlayerState,
  PlayersGet,
  PlayerGet,
  PlayerDataStatus,
  PlayerPost,
  PlayerPut,
  PlayerFind,
  ChangePlayer,
} from "../types/Players";

const getAge = (dateString: string) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const getPlayerStatus = (player: IApiPlayerEntity) => {
  if (!player) return PlayerDataStatus.Inconclusive;

  const age = getAge(player.birthDate);
  return player.restrictedFromGambling || age < 18
    ? PlayerDataStatus.Forbidden
    : PlayerDataStatus.Allowed;
};

const addStatusToPlayer = (player: IApiPlayerEntity) => {
  return {
    ...player,
    status: getPlayerStatus(player),
  };
};

const addStatusToAllPlayers = (data: Array<IApiPlayerEntity>) => {
  const formateData = [];
  for (let i = 0; i < data.length; i++) {
    formateData.push(addStatusToPlayer(data[i]));
  }
  return formateData;
};

export const playersReducer: Reducer<TPlayerState, TPlayerActions> = (
  state = initialPlayersState,
  action
) => {
  switch (action.type) {
    case PlayersGet.SUCCESS: {
      const formatData = addStatusToAllPlayers(action.payload.data);
      return {
        ...state,
        data: formatData,
        isLoading: false,
      };
    }
    case PlayerPut.SUCCESS: {
      return {
        ...state,
        data: state.data ? state.data.map(rec => rec.id === action.payload.data.id ? action.payload.data : rec) : [],
        isLoading: false,
      }
    }
    case PlayerFind.SUCCESS: {
      return {
        ...state,
        latestFoundPlayer: action.payload.data,
        isLoadingPlayerFind: false,
      }
    }
    case PlayerPost.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: state.data
          ? [addStatusToPlayer(action.payload.data), ...state.data]
          : [addStatusToPlayer(action.payload.data)],
      };
    }
    case ChangePlayer.CHANGE: {
      return {
        ...state,
        selectedPlayer: action.payload,
      };
    }
    case PlayerGet.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case PlayerFind.REQUEST: {
      return {
        ...state,
        isLoadingPlayerFind: true,
      }
    }
    case PlayerPut.REQUEST:
    case PlayerGet.REQUEST:
    case PlayerPost.REQUEST:
    case PlayersGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PlayerFind.FAILED: {
      return {
        ...state,
        isLoadingPlayerFind: false,
      }
    }
    case PlayerPut.FAILED:
    case PlayerGet.FAILED:
    case PlayerPost.FAILED:
    case PlayersGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
