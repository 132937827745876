import {
  initialBettingEventsReportState,
  BettingEventsReportGet,
  TBettingEventsActions,
  TBettingEventsReportState,
  BettingReportStatsGet,
} from "../types/BettingEvents";
import { Reducer } from "redux";

export const bettingEventsReportReducer: Reducer<
  TBettingEventsReportState,
  TBettingEventsActions
> = (state = initialBettingEventsReportState, action) => {
  switch (action.type) {
    case BettingEventsReportGet.SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
        isLoading: false,
      };
    }
    case BettingReportStatsGet.SUCCESS: {
      return {
        ...state,
        reportsStatsLoading: false,
        reportsStats: action.payload.data,
      }
    }
    case BettingEventsReportGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case BettingReportStatsGet.FAILED: {
      return {
        ...state,
        reportsStatsLoading: false,
      }
    }
    case BettingReportStatsGet.REQUEST: {
      return {
        ...state,
        reportsStatsLoading: true,
      }
    }
    case BettingEventsReportGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default: {
      return state;
    }
  }
};
