import React, { useMemo, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, Theme, ThemeProvider } from "@material-ui/core";
import ErrorBoundary from "./ErrorBoundary";
import GlobalRouter from "./Shared/router/GlobalRouter";
import { ROUTES as GLOBAL_ROUTES } from "./Shared/constants/Routes";
import { IAppState } from "./Shared/store/Store";
import { TAppState } from "./Shared/types/App";
import { totoTheme } from "./Shared/themes/toto";
import { gamingTheme } from "./Shared/themes/gaming";
import { APPLICATIONS } from "./Shared/constants/Applications";
import { APPLICATION_ITEM } from "./Shared/constants/Storage";
import { setCurrentApp } from "./Shared/actions/AppActions";
import "./Shared/assets/styles/css/App.css";
import { initSocket } from "./Shared/utils/WebSockets";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const appState: TAppState = useSelector<IAppState, TAppState>(
    (state: IAppState) => state.appState
  );
  const history = useHistory();

  useEffect(() => {
    initSocket();
    const appFromStorage = localStorage.getItem(APPLICATION_ITEM);
    if (appFromStorage) {
      if (appFromStorage === APPLICATIONS.ToTo) {
        dispatch(setCurrentApp(APPLICATIONS.ToTo));
      } else {
        dispatch(setCurrentApp(APPLICATIONS.PerlasGaming));
      }
    } else {
      history.push(GLOBAL_ROUTES.APP_SELECT);
    }
  }, [dispatch, history]);

  const theme: Theme = useMemo(() => {
    switch (appState.activeApp) {
      case APPLICATIONS.ToTo: {
        return createTheme(totoTheme);
      }
      default: {
        return createTheme(gamingTheme);
      }
    }
  }, [appState.activeApp]);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <GlobalRouter />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          closeOnClick
          pauseOnFocusLoss
          draggable={false}
          style={{ width: 400 }}
        />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
