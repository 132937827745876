import { AxiosError, AxiosResponse } from "axios";

export interface TBettingEventTicketState {
  readonly data?: IBettingEventTicket;
  readonly isLoading?: boolean;
  readonly isLoadingExpand?: boolean;
}

export const initialBettingEventState: TBettingEventTicketState = {
  data: undefined,
  isLoading: false,
  isLoadingExpand: false,
};

export enum BettingEventTicketStatus {
  NoStatus,
  Active,
  Cancelled,
  PaidOut,
  Refunded,
  Losing,
  Winning,
}

export interface IBettingEventTicket {
  predictedWinnerParticipantId: number;
  predictedWinnerParticipantName: string;
  predictedWinnerParticipantNumber: number;
  eventId: number;
  eventTitle: string;
  eventStartsOn: string;
  storeAddress: string;
  ticketPrice: number;
  ticketCreatedOn: string;
  prizePoolPercentage: number;
  content: string;
  printType: string;
  ticketId: number;
  ticketNumber?: string;
  ticketWonAmount?: number;
  status: number;
  ticketExpired: boolean;
}

export enum BettingEventTicketExtend {
  REQUEST = "EXTEND_BETTING_TICKET_REQUEST",
  SUCCESS = "EXTEND_BETTING_TICKET_SUCCESS",
  FAILED = "EXTEND_BETTING_TICKET_FAILED",
}

export enum BettingEventTicketCancel {
  REQUEST = "CANCEL_BETTING_TICKET_REQUEST",
  SUCCESS = "CANCEL_BETTING_TICKET_SUCCESS",
  FAILED = "CANCEL_BETTING_TICKET_FAILED",
}

export enum BettingEventTicketCheck {
  REQUEST = "GET_BETTING_TICKET_REQUEST",
  SUCCESS = "GET_BETTING_TICKET_SUCCESS",
  FAILED = "GET_BETTING_TICKET_FAILED",
}

export enum BettingEventTicketPost {
  REQUEST = "POST_BETTING_TICKET_REQUEST",
  SUCCESS = "POST_BETTING_TICKET_SUCCESS",
  FAILED = "POST_BETTING_TICKET_FAILED",
}

// ticket status
export enum BettingEventTicketStatusChange {
  REQUEST = "BETTING_TICKET_STATUS_CHANGE_REQUEST",
  SUCCESS = "BETTING_TICKET_STATUS_CHANGE_SUCCESS",
  FAILED = "BETTING_TICKET_STATUS_CHANGE_FAILED",
}

export type TBettingEventTicketExtendAction =
  | {
      type: BettingEventTicketExtend.REQUEST;
    }
  | {
      type: BettingEventTicketExtend.SUCCESS;
      payload: AxiosResponse<IBettingEventTicket>;
    }
  | {
      type: BettingEventTicketExtend.FAILED;
      payload: AxiosError;
    };

export type TBettingEventTicketCheckAction =
  | {
      type: BettingEventTicketCheck.REQUEST;
    }
  | {
      type: BettingEventTicketCheck.SUCCESS;
      payload: AxiosResponse<IBettingEventTicket>;
    }
  | {
      type: BettingEventTicketCheck.FAILED;
      payload: AxiosError;
    };

export type TBettingEventTicketPost =
  | {
      type: BettingEventTicketPost.REQUEST;
    }
  | {
      type: BettingEventTicketPost.SUCCESS;
      payload: AxiosResponse<IBettingEventTicket>;
    }
  | {
      type: BettingEventTicketPost.FAILED;
      payload: AxiosError;
    };

export type TBettingEventTicketStatusChangeAction =
  | {
      type: BettingEventTicketStatusChange.REQUEST;
    }
  | {
      type: BettingEventTicketStatusChange.SUCCESS;
      payload: AxiosResponse<IBettingEventTicket>;
    }
  | {
      type: BettingEventTicketStatusChange.FAILED;
      payload: AxiosError;
    };

export type TBettingEventTicketCancelAction =
  | {
      type: BettingEventTicketCancel.REQUEST;
    }
  | {
      type: BettingEventTicketCancel.SUCCESS;
      payload: AxiosResponse<any>;
    }
  | {
      type: BettingEventTicketCancel.FAILED;
      payload: AxiosError;
    };

export type TBettingEventTicketActions =
  | TBettingEventTicketCancelAction
  | TBettingEventTicketPost
  | TBettingEventTicketStatusChangeAction
  | TBettingEventTicketExtendAction
  | TBettingEventTicketCheckAction;
