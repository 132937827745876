export const getPageCount = (
  totalPages: number,
  setPages?: (pagesCount: number[] | undefined) => void
) => {
  const pages = Array(totalPages)
    .fill(null)
    .map((_, i) => i + 1);
  if (pages.length > 1) {
    setPages?.(pages);
  } else {
    setPages?.(undefined);
  }
  return pages;
};

export const downloadFileFromBlob = (
  type: string = "application/pdf",
  blobData: any,
  filename: string
) => {
  const blob = new Blob([blobData], {
    type,
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  link.remove();
};
