import {
  initialBettingEventState,
  BettingEventsGet,
  BettingEventCancel,
  BettingEventPost,
  TBettingEventState,
  TBettingEventsActions,
  BettingEventUpdate,
  ReserveFundGet,
} from "../types/BettingEvents";
import { Reducer } from "redux";

export const bettingEventsReducer: Reducer<
  TBettingEventState,
  TBettingEventsActions
> = (state = initialBettingEventState, action) => {
  switch (action.type) {
    case BettingEventsGet.SUCCESS: {
      return {
        ...state,
        eventsTableData: action.payload.data,
        isLoading: false,
      };
    }
    case BettingEventPost.SUCCESS: {
      const event = {
        ...action.payload.data,
        totalBetsAmount: 0,
        soldTicketAmount: 0,
      };
      return {
        ...state,
        eventsTableData: state.eventsTableData
          ? [event, ...state?.eventsTableData]
          : [event],
        isLoading: false,
      };
    }
    case BettingEventCancel.SUCCESS: {
      const eventToEdit = action.payload.data;
      return {
        ...state,
        eventsTableData: state.eventsTableData
          ? state.eventsTableData.filter((rec) => rec.id !== eventToEdit.id)
          : [],
        isLoading: false,
      };
    }
    case BettingEventUpdate.UPDATE_EVENT_STATUS: {
      return {
        ...state,
        eventsTableData: state.eventsTableData?.map((rec) => {
          if (rec.id === action.payload.id) {
            return {
              ...rec,
              ...action.payload,
            };
          }
          return rec;
        }),
      };
    }
    case ReserveFundGet.REQUEST: {
      return {
        ...state,
        isLoadingFunds: true,
      }
    }
    case ReserveFundGet.FAILED: {
      return {
        ...state,
        isLoadingFunds: false,
      }
    }
    case ReserveFundGet.SUCCESS: {
      return {
        ...state,
        isLoadingFunds: false,
        reserveFunds: action.payload.data.currentAmount,
      }
    }
    case BettingEventCancel.FAILED:
    case BettingEventPost.FAILED:
    case BettingEventsGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case BettingEventCancel.REQUEST:
    case BettingEventPost.REQUEST:
    case BettingEventsGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default: {
      return state;
    }
  }
};
