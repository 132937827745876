import React from "react";
import { Paper } from "@material-ui/core";
import AdminMenu from "./AdminMenu";
import { useAdminPanelStyles } from "./AdminPanel.styles";

const AdminPanel: React.FC = () => {
  const classes = useAdminPanelStyles();

  return (
    <Paper elevation={3} className={classes.container}>
      <AdminMenu />
    </Paper>
  );
};

export default AdminPanel;
