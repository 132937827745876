import { AxiosError, AxiosResponse } from "axios";

export interface TBettingEventTicketActionsState {
  readonly data?: IBettingEventTicketActionModal[];
  readonly isLoading?: boolean;
  readonly totalPages?: number;
}

export const initialTicketActionsState: TBettingEventTicketActionsState = {
  data: undefined,
  isLoading: false,
  totalPages: undefined,
};

export enum TicketActionType {
  StatusNotFound, // custom type if no type from backend
  Cancelled,
  Extended,
  Expired,
}

export interface IBettingEventTicketActionData {
  data: IBettingEventTicketActionModal[];
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export interface IBettingEventTicketActionModal {
  userName?: string;
  actionType: number;
  bettingEventName?: string;
  actionDate: string;
  bettingEventTicketId: number;
}

//betting event ticket actions
export enum BettingEventTicketActionsGet {
  REQUEST = "BETTING_EVENT_TICKET_ACTIONS_REQUEST",
  SUCCESS = "BETTING_EVENT_TICKET_ACTIONS_SUCCESS",
  FAILED = "BETTING_EVENT_TICKET_ACTIONS_FAILED",
}

// betting event ticket actions
export type TBettingEventTicketActionsGet =
  | {
      type: BettingEventTicketActionsGet.REQUEST;
    }
  | {
      type: BettingEventTicketActionsGet.SUCCESS;
      payload: AxiosResponse<IBettingEventTicketActionData>;
    }
  | {
      type: BettingEventTicketActionsGet.FAILED;
      payload: AxiosError;
    };

export type TBettingEventTicketActionActions = TBettingEventTicketActionsGet;
