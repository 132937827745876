import { makeStyles } from "@material-ui/core/styles";

export const useFormSelectStyles = makeStyles((theme) => ({
  error: {
    color: "red",
    marginBottom: -22,
  },
  arrowIcon: {
    color: "#DADADA !important",
  },
  selection: {
    "& > .MuiSelect-select:focus": {
      backgroundColor: "unset",
    },
  },
}));
