export const ROUTES = {
  CASHIER: {
    MAIN: "/",
    EVENTS: "/events",
    REPORTS: "/reports",
  },
  ADMIN: {
    MAIN: "/admin",
    EVENTS: "/admin/events",
    REPORTS: "/admin/reports",
    LOG_REPORTS: '/admin/logs'
  },
};
