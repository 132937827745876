import React from "react";
import { Paper } from "@material-ui/core";
import { useCashierPanelStyles } from "./CashierPanel.styles";
import CashierMenu from "./CashierMenu";

const CashierPanel: React.FC = () => {
  const classes = useCashierPanelStyles();

  return <Paper elevation={3} className={classes.container}>
    <CashierMenu />
  </Paper>;
};

export default CashierPanel;
