import { makeStyles } from "@material-ui/core/styles";

export const useFormInputStyles = makeStyles(theme => ({
  error: {
    color: "red",
    marginBottom: -22,
  },
  endIcon: {
    color: "#DADADA"
  },
  datePicker: {
    '& .MuiDialog-container': {
        '& .MuiPickersModal-dialogRoot': {
            minWidth: '360px !important',
            '& .MuiPickersModal-dialog': {
              '& .MuiPickersBasePicker-container': {
                '& .MuiPickersBasePicker-pickerView': {
                  maxWidth: '360px !important'
                }
              }
            }
        }
    }
  }
}));
