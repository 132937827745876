import React, {
  useMemo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "Shared/store/Store";
import { TBettingEventsReportState } from "ToTo/types/BettingEvents";
import { IAdminDateFilter } from "Shared/constants/Table";
import { AdminEventsTableKeys } from "ToTo/constants/Interfaces";
import EventsTable from "ToTo/components/EventsTable";
import { getBettingEventsReport } from "ToTo/actions/BettingEventsReportActions";
import { handleError } from "Shared/utils/ErrorHandler";
import Toast from "Shared/components/utils/Toast";

import AdminReportsFilter from "./AdminReportsFilter";
import { useAdminReportWrapperStyles } from "./AdminReportsWrapper.styles";
import { getPageCount } from "Shared/helpers/Utils";
import { TStoreState } from "Shared/types/Stores";

interface IAdminReportsWrapper {
  searchTerm?: string;
  setSearchTerm: Dispatch<SetStateAction<string | undefined>>;
  currentPage: number | null;
  setCurrentPage: Dispatch<SetStateAction<number | null>>;
  dateFilter?: Partial<IAdminDateFilter>;
  setDateFilter: Dispatch<
    SetStateAction<Partial<IAdminDateFilter> | undefined>
  >;
}
const AdminReportsWrapper = ({
  searchTerm,
  setSearchTerm,
  currentPage,
  setCurrentPage,
  dateFilter,
  setDateFilter,
}: IAdminReportsWrapper) => {
  const classes = useAdminReportWrapperStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const reportState: TBettingEventsReportState = useSelector<
    IAppState,
    TBettingEventsReportState
  >((state: IAppState) => state.bettingEventsReportState);
  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );

  useEffect(() => {
    if (currentPage === null) {
      setCurrentPage(1);
      return;
    }
    if (
      !storesState?.selectedStore?.id &&
      storesState?.selectedStore?.id !== undefined
    ) {
      return;
    }
    (async () => {
      try {
        await dispatch(
          getBettingEventsReport({
            MinDate: dateFilter?.from
              ? format(new Date(dateFilter.from), "yyyy-MM-dd'T'HH:mm:ss")
              : undefined,
            MaxDate: dateFilter?.to
              ? format(new Date(dateFilter.to), "yyyy-MM-dd'T'HH:mm:ss")
              : undefined,
            PageNumber: currentPage,
            Term: searchTerm,
            StoreId: storesState?.selectedStore?.id,
          })
        );
      } catch (err: any) {
        handleError(err?.status, setError);
      }
    })();
  }, [
    currentPage,
    searchTerm,
    dateFilter,
    dispatch,
    storesState?.selectedStore?.id,
    setCurrentPage,
  ]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  const columns = useMemo(
    () => [
      {
        Header: `${t("event")}`,
        id: AdminEventsTableKeys.title,
        accessor: AdminEventsTableKeys.title,
      },
      {
        Header: t("totalBets"),
        accessor: AdminEventsTableKeys.totalBetsAmount,
        Cell: (cell: any) => (
          <Typography className={classes.small}>{cell.value || 0}€</Typography>
        ),
      },
      {
        Header: t("totalWinnings"),
        accessor: AdminEventsTableKeys.totalWinningsAmount,
        Cell: (cell: any) => (
          <Typography className={classes.small}>{cell.value || 0}€</Typography>
        ),
      },
      {
        Header: t("totalPayouts"),
        accessor: AdminEventsTableKeys.totalPaidOutAmount,
        Cell: (cell: any) => (
          <Typography className={classes.small}>{cell.value || 0}€</Typography>
        ),
      },
      {
        Header: t("soldTicketsAmount"),
        accessor: AdminEventsTableKeys.soldTicketAmount,
        Cell: (cell: any) => (
          <Typography className={classes.small}>{cell.value || 0}</Typography>
        ),
      },
      {
        Header: t("winningTicketsAmount"),
        accessor: AdminEventsTableKeys.winningTicketAmount,
        Cell: (cell: any) => (
          <Typography className={classes.small}>{cell.value || 0}</Typography>
        ),
      },
      {
        Header: t("cancelledTicketsAmount"),
        accessor: AdminEventsTableKeys.cancelledTicketAmount,
        Cell: (cell: any) => (
          <Typography className={classes.small}>{cell.value || 0}</Typography>
        ),
      },
      {
        Header: t("status"),
        accessor: AdminEventsTableKeys.status,
        Cell: (cell: any) => (
          <Typography className={classes.small}>
            {t(`adminEventStatuses.${cell.value}`)}
          </Typography>
        ),
      },
      {
        Header: t("betsEndAt"),
        accessor: AdminEventsTableKeys.bettingEndsOn,
        Cell: (cell: any) => (
          <Typography className={classes.small}>
            {format(new Date(cell.value), "yyyy-MM-dd HH:mm:ss")}
          </Typography>
        ),
      },
    ],
    [t, classes.small]
  );

  const changePage = async (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const changeTerm = (value: string) => {
    setCurrentPage(null);
    setSearchTerm(value);
  };

  const changeDateFilter = (value: typeof dateFilter) => {
    setCurrentPage(null);
    setDateFilter(value);
  };

  return (
    <>
      <AdminReportsFilter
        dateFilter={dateFilter}
        setDateFilter={changeDateFilter}
        searchTerm={searchTerm}
      />
      <EventsTable
        useCustomPagination
        useCustomSearch
        onSearchTermUpdate={changeTerm}
        columns={columns}
        events={reportState.data || []}
        changePage={changePage}
        activePage={currentPage || 1}
        pages={getPageCount(reportState.totalPages || 0)}
        initialTableState={{
          sortBy: [
            {
              id: AdminEventsTableKeys.startsOn,
              desc: true,
            },
          ],
        }}
      />
    </>
  );
};

export default AdminReportsWrapper;
