import { makeStyles } from "@material-ui/core/styles";

const AvatarSize = 150;

export const usePlayerIconStyles = makeStyles((theme) => ({
  iconDefault: {
    width: AvatarSize,
    height: AvatarSize,
    borderRadius: "50%",
    backgroundColor: "#F4F4F4",
  },
  iconCheck: {
    width: AvatarSize,
    height: AvatarSize,
    color: "#0AAF60",
  },
  iconBlock: {
    width: AvatarSize,
    height: AvatarSize,
    color: "#E70404",
  },
  iconUnknown: {
    width: AvatarSize,
    height: AvatarSize,
    color: "#F57C00",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: AvatarSize,
  },
}));
