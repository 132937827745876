import { makeStyles } from "@material-ui/core/styles";

export const useEventsTableStyles = makeStyles((theme) => ({
  iconPrimary: {
    color: theme.palette.primary.main,
  },
  tablePaper: {
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    overflow: 'auto',
    padding: theme.spacing(3),
  },
  table: {
    tableLayout: "fixed",
    "& .MuiTableCell-root": {
      textAlign: "center",
    },
  },
  tableSearchSimple: {
    borderBottom: "1px solid #C4C4C4",
    marginBottom: theme.spacing(2),
    width: 250,
  },
  tableCellHeaderLarge: {
    width: "20%",
  },
  row: {
    "&:hover": {
      background: "rgba(0,0,0,.085)",
    },
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nameContainerAligmentEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  nameText: {
    paddingTop: 3,
    paddingLeft: theme.spacing(0.5),
  },
  green: {
    color: '#0AAF60',
  },

  icon: {
    fontSize: 15,
  },
  iconWaiting: {
    color: "#FF9C07",
  },
  iconActive: {
    color: "#8CBDE7",
  },
  iconFinished: {
    color: "#0AAF60",
  },
  iconCanceled: {
    color: "#FF9C07",
  },
}));
