import { makeStyles } from "@material-ui/core/styles";

export const useAddPlayerFormStyles = makeStyles((theme) => ({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: theme.spacing(1),
    minWidth: 340,
  },
  error: {
    color: "red",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1.5),
    minHeight: 50,
    "&.MuiButton-containedPrimary ": {
      color: "white",
    },
    "&.MuiButton-label": {
      display: "flex",
      alignItems: "end",
      fontSize: 14,
    },
  },
  calendarIcon: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  passwordStrengthContainer: {
    position: "relative",
  },
  input: {
    marginTop: theme.spacing(2),
  },
}));
