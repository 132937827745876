import { AxiosResponse } from "axios";
import { apiClient, apiCall } from "../../Shared/actions/BaseAction";
import { TDispatch } from "../../Shared/types/Thunk";
import qs from "qs";
import {
  BettingEventTicketCancel,
  TBettingEventTicketState,
  IBettingEventTicket,
  BettingEventTicketPost,
  BettingEventTicketCheck,
  BettingEventTicketStatusChange,
  BettingEventTicketStatus,
  BettingEventTicketExtend
} from "../types/BettingEventTickets";
import { API_END_POINTS } from "../constants/Api";
import { sendMessage } from "../../Shared/utils/WebSockets";

export const cancelBettingEventTicket = (id: string) => {
  return apiCall<TBettingEventTicketState, null, IBettingEventTicket>(
    BettingEventTicketCancel,
    "PUT",
    API_END_POINTS.BETTING_EVENT_TICKET_CANCEL(id),
    true
  );
};

export const extendBettingEventTicket = (ticketNumber: string) => {
  return apiCall<TBettingEventTicketState, null, IBettingEventTicket>(
    BettingEventTicketExtend,
    "PUT",
    API_END_POINTS.EXTEND_EXPIRED_TICKET(ticketNumber),
    true
  );
};

export const checkBettingEventTicket = (id: string) => {
  return apiCall<TBettingEventTicketState, null, IBettingEventTicket>(
    BettingEventTicketCheck,
    "GET",
    API_END_POINTS.BETTING_EVENT_TICKET_CHECK(id),
    true
  );
};
//TODO: make a reusable function for socket
export const printTicket = (ticketData: any) => {
  let ticketprinted = false;
  if (ticketData) {
    ////THIS IS TO PRINT TICKET IN NON-FISCAL PRINTER
    try {
      const responseJson = JSON.stringify(ticketData).replaceAll('"', '\\"');
      const data = `{"MessageType": "PrinterMessage","Data": "${responseJson}"}`;
      sendMessage(data);
      ticketprinted = true;

      if (ticketData.content && ticketprinted) {
        //THIS IS TO PRINT RECEIPT IN FISCAL PRINTER
        try {
          const testURL = "http://localhost:10088/DLLPOST";
          const headers = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PUT, DELETE, OPTIONS, HEADER",
            "Access-Control-Allow-Headers":
              "Content-Type,Accept-Language,Authorization",
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "text/xml",
            ReadTimeout: "120000",
          };
          const data = qs.stringify({
            XMLCMD: ticketData.content,
          });
          const params: RequestInit = {
            method: "POST",
            mode: "no-cors",
            body: data,
            headers: headers,
          };

          const myRequest = new Request(testURL, params);

          fetch(myRequest)
            .then(function (response) {
              return response;
            })
            .then(function (response) {
              console.log(response);
            })
            .catch(function (e) {
              console.log(e);
            });
        } catch (error) {
          throw error;
        }
      }
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
};

export const createBettingTicket = (
  eventId: number,
  participantId: number,
  cashReceived: number,
  storeId?: number
) => {
  return async (
    dispatch: TDispatch<BettingEventTicketPost>
  ): Promise<AxiosResponse<IBettingEventTicket>> => {
    dispatch({
      type: BettingEventTicketPost.REQUEST,
    });

    try {
      const response = await apiClient.request({
        method: "POST",
        url: API_END_POINTS.BETTING_EVENT_TICKET_POST(eventId),
        data: {
          predictedWinnerParticipantId: participantId,
          cashReceived,
          storeId: storeId,
        },
      });
      dispatch({
        type: BettingEventTicketPost.SUCCESS,
        payload: response,
      });
      printTicket(response.data);

      return response;
    } catch (error: any) {
      dispatch({
        type: BettingEventTicketPost.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

const getTicketStatusUrl = (
  storeId: number | undefined,
  ticketNumber: string,
  ticketStatus: number
) => {
  if (!storeId) {
    return "";
  }
  switch (ticketStatus) {
    case BettingEventTicketStatus.Winning: {
      return API_END_POINTS.BETTING_EVENT_TICKET_PAYOUT(ticketNumber, storeId);
    }
    case BettingEventTicketStatus.Losing: {
      return API_END_POINTS.BETTING_EVENT_TICKET_NOT_WON(ticketNumber, storeId);
    }
    case BettingEventTicketStatus.Cancelled: {
      return API_END_POINTS.BETTING_EVENT_TICKET_REFUND(ticketNumber, storeId);
    }
    default:
      return "";
  }
};

export const ticketStatusChange = (
  storeId: number | undefined,
  ticketNumber: string,
  ticketStatus: number
) => {
  return async (
    dispatch: TDispatch<BettingEventTicketStatusChange>
  ): Promise<AxiosResponse<IBettingEventTicket>> => {
    dispatch({
      type: BettingEventTicketStatusChange.REQUEST,
    });
    const url = getTicketStatusUrl(storeId, ticketNumber, ticketStatus);
    try {
      const response = await apiClient.request({
        method: "PUT",
        url,
      });
      dispatch({
        type: BettingEventTicketStatusChange.SUCCESS,
        payload: response,
      });
      printTicket(response.data);

      return response;
    } catch (error: any) {
      dispatch({
        type: BettingEventTicketStatusChange.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
