let socket: WebSocket;
export const getSocket = (): WebSocket => {
  return socket;
};

export const initSocket = () => {
  if (!socket || socket.readyState !== socket.OPEN) {
    socket = new WebSocket(`ws://127.0.0.1:10099`);

    socket.onopen = () => {
      console.log("socket server connected");
    };

    socket.onclose = () => {
      console.log("server closed");
    };

    socket.onerror = (error) => {
      console.log(`errror in socket server: ${error}`);
    };
  }
  return socket;
};

export const sendMessage = async (msg: any) => {
  if (socket.readyState !== socket.OPEN) {
    try {
      await waitForOpenConnection(socket);
      socket.send(msg);
    } catch (err: any) {
      console.error(err);
    }
  } else {
    socket.send(msg);
  }
};

const waitForOpenConnection = (socket: WebSocket) => {
  return new Promise<void>((resolve, reject) => {
    const maxNumberOfAttempts = 10;
    const intervalTime = 200; //ms

    let currentAttempt = 0;
    const interval = setInterval(() => {
      if (currentAttempt > maxNumberOfAttempts - 1) {
        clearInterval(interval);
        initSocket();
        reject(new Error("Maximum number of attempts exceeded"));
      } else if (socket.readyState === socket.OPEN) {
        clearInterval(interval);
        resolve();
      }
      currentAttempt++;
    }, intervalTime);
  });
};
