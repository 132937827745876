import React from "react";
import { Block, CheckCircleOutline, HelpOutline } from "@material-ui/icons";
import { PlayerDataStatus } from "../../types/Players";
import { usePlayerIconStyles } from "./PlayerIcon.styles";
import clsx from "clsx";
import { IPlayersTableRow } from "../../../Gaming/constants/Interfaces";
import Loading from "./Loading";

interface IPlayerIcon {
  player: Partial<IPlayersTableRow> | undefined;
  className?: string;
  size?: number | string;
  playerFindLoading?: boolean;
}

const PlayerIcon: React.FC<IPlayerIcon> = ({ player, className, size, playerFindLoading }) => {
  const classes = usePlayerIconStyles();

  const getIconByState = (status: PlayerDataStatus | undefined) => {
    switch (status) {
      case PlayerDataStatus.Allowed:
        return (
          <CheckCircleOutline
            style={{ height: size, width: size }}
            className={clsx(className, classes.iconCheck)}
          />
        );
      case PlayerDataStatus.Forbidden:
      case PlayerDataStatus.ForbiddenEu:
      case PlayerDataStatus.ForbiddenUn:
      case PlayerDataStatus.ForbiddenOnDato:
        return (
          <Block
            style={{ height: size, width: size }}
            className={clsx(className, classes.iconBlock)}
          />
        );
    }
    return (
      <HelpOutline
        style={{ height: size, width: size }}
        className={clsx(className, classes.iconUnknown)}
      />
    );
  };

  if (player) return getIconByState(player.gamblingEligibility);

  return (
    <>
      {playerFindLoading ? (
        <div style={{ height: size, width: size }} className={classes.loading}>
          <Loading width={60} />
        </div>
      ) : (
        <div
          style={{ height: size, width: size }}
          className={classes.iconDefault}
        ></div>
      )}
    </>
  );
};

export default PlayerIcon;
