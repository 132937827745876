import React from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Table,
  TableSortLabel,
  Button,
  Box,
} from "@material-ui/core";
import { PersonAddOutlined } from "@material-ui/icons";
import { useTable, useSortBy, TableInstance } from "react-table";
import clsx from "clsx";
import { useWorkersTableStyles } from "./WorkersTable.styles";
import { useTranslation } from "react-i18next";
import { IWorkerRow } from "../../../constants/Interfaces";
import Loading from "../../../../Shared/components/utils/Loading";

type Data = object;
interface IWorkersTable {
  columns: any[];
  data: IWorkerRow[];
  openDialog: () => void;
  isLoading?: boolean;
}

const WorkersTable: React.FC<IWorkersTable> = ({
  data,
  columns,
  openDialog,
  isLoading,
}) => {
  const classes = useWorkersTableStyles();
  const { t } = useTranslation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<Data>(
    {
      columns,
      data,
    },
    useSortBy
  ) as TableInstance<object>;

  return (
    <Paper elevation={3} className={classes.tablePaper}>
      <div className={classes.topControlPanel}>
        <Button
          color="primary"
          className={classes.button}
          size="large"
          variant="outlined"
          onClick={(event) => openDialog?.()}
          disableElevation
          startIcon={<PersonAddOutlined />}
        >
          {t("newWorker")}
        </Button>
      </div>

      <Table {...getTableProps()} stickyHeader className={classes.table}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps()}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  {column.isSorted && (
                    <TableSortLabel
                      direction={column.isSortedDesc ? "desc" : "asc"}
                      active={column.isSorted}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {!isLoading && (
          <TableBody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell: any) => (
                    <TableCell {...cell.getCellProps()}>
                      <div className={clsx(classes.nameContainer)}>
                        <span className={classes.nameText}>
                          {cell.render("Cell")}
                        </span>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ height: "70%", width: "100%" }}
        >
          <Loading width={100} />
        </Box>
      )}
    </Paper>
  );
};

export default WorkersTable;
