import {
  applyMiddleware,
  combineReducers,
  createStore,
  DeepPartial,
} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadUser, reducer as oidcReducer } from "redux-oidc";
import { TUserState } from "../types/Users";
import { TPlayerState } from "../types/Players";
import { TAnalysisState } from "../types/Analysis";
import { TTransactionState } from "../types/Transactions";
import {
  TBettingEventsReportState,
  TBettingEventState,
} from "../../ToTo/types/BettingEvents";
import { TBettingEventTicketState } from "../../ToTo/types/BettingEventTickets";
import { TBettingEventTicketActionsState } from "../../ToTo/types/BettingEventTicketActions";
import { usersReducer } from "../reducers/UserReducer";
import { playersReducer } from "../reducers/PlayerReducer";
import { storesReducer } from "../reducers/StoresReducer";
import { analysisReducer } from "../reducers/AnalysisReducer";
import { requestReducer } from "../reducers/RequestReducer";
import { bettingEventsReducer } from "../../ToTo/reducers/BettingEventsReducer";
import { bettingEventTicketReducer } from "../../ToTo/reducers/BettingEventTicketReducer";
import { bettingEventsReportReducer } from "../../ToTo/reducers/BettingEventsReportReducer";
import { bettingEventTicketActionsReducer } from "../../ToTo/reducers/BettingEventTicketActionsReducer";
import { ErrorHandler } from "../middlewares/ErrorHandler";
import { TStoreState } from "../types/Stores";
import { transactionReducer } from "../reducers/TransactionReducer";
import { TAppState } from "../types/App";
import userManager from "../helpers/userManagement";

export interface IAppState {
  usersState: TUserState;
  playersState: TPlayerState;
  analysisState: TAnalysisState;
  storesState: TStoreState;
  transactionsState: TTransactionState;
  bettingEventsState: TBettingEventState; //TOTO
  bettingEventTicketState: TBettingEventTicketState; //TOTO
  bettingEventsReportState: TBettingEventsReportState; // TOTO
  bettingEventsTicketActionsState: TBettingEventTicketActionsState // TOTO
  appState: TAppState;
  oidc: any;
}

const initialAppState: DeepPartial<IAppState> = {
  usersState: undefined,
  playersState: undefined,
  analysisState: undefined,
  storesState: undefined,
  transactionsState: undefined,
  bettingEventsState: undefined,
  bettingEventTicketState: undefined,
  bettingEventsReportState: undefined,
  bettingEventsTicketActionsState: undefined,
  appState: {},
  oidc: {},
};

const rootReducer = combineReducers<IAppState>({
  usersState: usersReducer,
  playersState: playersReducer,
  analysisState: analysisReducer,
  appState: requestReducer,
  transactionsState: transactionReducer,
  storesState: storesReducer,
  bettingEventsState: bettingEventsReducer,
  bettingEventTicketState: bettingEventTicketReducer,
  bettingEventsReportState: bettingEventsReportReducer,
  bettingEventsTicketActionsState: bettingEventTicketActionsReducer,
  oidc: oidcReducer,
});

const store = createStore(
  rootReducer,
  initialAppState,
  composeWithDevTools(applyMiddleware(thunk, ErrorHandler))
);

loadUser(store, userManager);

export default store;
