import React from "react";
import clsx from "clsx";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TextField,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import {
  useTable,
  useSortBy,
  TableInstance,
  useGlobalFilter,
  useAsyncDebounce,
  Column,
} from "react-table";
import { useSelectionTableStyles } from "./SelectionTable.styles";
import { useTranslation } from "react-i18next";
import { IBettingEvent, IParticipants } from "../types/BettingEvents";

type Data = object;

type SelectionSingleType = IParticipants | IBettingEvent;
type SelectionMultipleType = IParticipants[] | IBettingEvent[];

interface ISelectionTable {
  columns: Column[];
  data: SelectionMultipleType;
  selectedRows?: SelectionSingleType | SelectionMultipleType;
  isTakeBet?: boolean;
  setSelectedRows: (row: any) => void;
  enableMultiselection?: boolean;
}

const SelectionTable: React.FC<ISelectionTable> = ({
  columns,
  data,
  selectedRows,
  setSelectedRows,
  isTakeBet,
  enableMultiselection,
}) => {
  const { t } = useTranslation();
  const classes = useSelectionTableStyles();

  const onSearchChange = useAsyncDebounce((inputValue) => {
    setGlobalFilter(inputValue);
  }, 200);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable<Data>(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  ) as TableInstance<object>;

  const handleRowClick = (row: SelectionSingleType) => {
    if (enableMultiselection && Array.isArray(selectedRows)) {
      if (selectedRows.some((rec: SelectionSingleType) => rec.id === row.id)) {
        setSelectedRows([
          ...(selectedRows as any)?.filter(
            (rec: SelectionSingleType) => rec.id !== row.id
          ),
        ]);
        return;
      } else {
        setSelectedRows([...selectedRows, row]);
      }
    } else {
      setSelectedRows(row);
    }
  };

  return (
    <>
      <TextField
        placeholder={t("search")}
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: <Search className={classes.searchIcon} />,
        }}
        className={classes.searchBox}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onSearchChange(event.target.value)
        }
      />
      <Table
        {...getTableProps()}
        stickyHeader
        className={classes.selectionTable}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps()}
                  className={clsx(column.id === "number" && classes.smallCell, {
                    [classes.fullWidthCell]: isTakeBet,
                    [classes.largeCell]: column.id === "name",
                    [classes.warningCell]: column.id === "warning",
                  })}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                className={classes.row}
                onClick={() => handleRowClick(row.original)}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default SelectionTable;
