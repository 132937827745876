import React, { useState, useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Close, CheckCircleOutline, Print } from "@material-ui/icons";
import SelectionTable from "../../SelectionTable";
import { useEventWinnerModalStyles } from "./EventWinnerSelect.styles";
import { createBettingTicket } from "../../../actions/BettingEventTicketActions";
import Toast from "../../../../Shared/components/utils/Toast";
import { TStoreState } from "../../../../Shared/types/Stores";
import { handleError } from "../../../../Shared/utils/ErrorHandler";
import { IAppState } from "../../../../Shared/store/Store";
import { IBettingEvent, IParticipants } from "../../../types/BettingEvents";
import { Form } from "react-final-form";
import FormInput from "../../../../Shared/components/utils/FormInput";
import { isAfter } from "date-fns/esm";

interface IEventCreateModal {
  onClose: () => void;
  selectedEvent: IBettingEvent | undefined;
  openBetModal: () => void;
  closeBetModal: () => void;
  openedFromTable?: boolean;
}

const EventWinnerSelect: React.FC<IEventCreateModal> = ({
  onClose,
  selectedEvent,
  openBetModal,
  closeBetModal,
  openedFromTable,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useEventWinnerModalStyles();
  const [error, setError] = useState("");
  const [selectedRow, setSelectedRow] = useState<IParticipants>();
  const [participants, setParticipants] = useState<Array<IParticipants>>([]);
  const [sumInCash, setSumInCash] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );

  useEffect(() => {
    if (!participants.length) {
      if (selectedEvent?.participants) {
        setParticipants(selectedEvent?.participants);
      }
    }
  }, [openBetModal, participants, selectedEvent?.participants]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  const columns = useMemo(
    () => [
      {
        Header: `${t("numberShort")}.`,
        accessor: "number",
        Cell: (cell: any) => <>{cell.value}.</>,
      },
      {
        Header: t("participant"),
        accessor: "name",
      },
      {
        Header: "",
        id: "warning",
        Cell: (tableInfo: any) =>
          selectedRow === tableInfo.row.original && (
            <Grid container justify="flex-end">
              <CheckCircleOutline
                className={clsx(classes.checkedIcon, classes.green)}
              />
            </Grid>
          ),
      },
    ],
    [classes.checkedIcon, classes.green, selectedRow, t]
  );

  const handleClose = () => {
    setSelectedRow(undefined);
    setParticipants([]);
    setSumInCash(undefined);

    if (openedFromTable) {
      onClose();
      return;
    }
    openBetModal();
    onClose();
  };

  const createTicket = async () => {
    if (!sumInCash) return;

    try {
      if (selectedEvent && selectedRow) {
        if (isAfter(new Date(selectedEvent.bettingEndsOn), new Date())) {
          setLoading(true);
          await dispatch(
            createBettingTicket(
              selectedEvent?.id,
              selectedRow.id,
              sumInCash,
              storesState?.selectedStore?.id
            )
          );
          setLoading(false);
          handleClose?.();
          closeBetModal();
        } else {
          setError(t("bettingTimeEnds"));
        }
      }
    } catch (error: any) {
      setLoading(false);
      handleError(error?.status, setError);
    }
  };

  return (
    <>
      <Dialog open={!!selectedEvent} onClose={handleClose}>
        <Close onClick={handleClose} className={classes.dialogClose} />
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h4">{t("selectAWinner")}</Typography>
          <Typography variant="h5">{selectedEvent?.title}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.sum}>
            <Typography variant="body1">{t("fixedBidSum")}:</Typography>
            <Typography
              variant="body1"
              color="primary"
              className={classes.textSpace}
            >
              {`${selectedEvent?.ticketPrice}€`}
            </Typography>
          </div>
          <SelectionTable
            selectedRows={selectedRow}
            setSelectedRows={setSelectedRow}
            data={participants}
            columns={columns}
          />
          <Form
            onSubmit={createTicket}
            validate={(values: any) => {
              const errors: any = {};
              if (
                selectedEvent &&
                values.paidSumInCash < selectedEvent.ticketPrice
              ) {
                errors.paidSumInCash = t("sumCantBeLowerThanTicketPrice");
              }
              return errors;
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormInput
                  name="paidSumInCash"
                  id="paidSumInCash"
                  label={t("paidSumInCash")}
                  className={classes.sumInCash}
                  type="number"
                  value={sumInCash}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let input = parseInt(event.target.value);
                    setSumInCash(
                      Number.isNaN(input) ? undefined : Math.max(0, input)
                    );
                  }}
                />
              </form>
            )}
          />

          <Grid container justify="space-between" alignItems="flex-start">
            <Button
              variant="contained"
              className={classes.backButton}
              color="primary"
              disableElevation
              size="small"
              onClick={handleClose}
            >
              <Typography className={classes.paddingTop} variant="caption">
                {t("back")}
              </Typography>
            </Button>
            <Button
              variant="contained"
              className={classes.printButton}
              color="secondary"
              onClick={createTicket}
              disabled={
                !selectedRow ||
                !sumInCash ||
                (selectedEvent && sumInCash < selectedEvent.ticketPrice) ||
                loading
              }
              disableElevation
              size="small"
            >
              {!loading ? (
                <Print className={classes.printIcon} />
              ) : (
                <CircularProgress
                  style={{ width: 16, height: 16, marginRight: 10 }}
                />
              )}
              <Typography className={classes.paddingTop} variant="caption">
                {t("printTicket")}
              </Typography>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EventWinnerSelect;
