import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Paper,
  Button,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  IPlayersTableRow,
} from "../../../constants/Interfaces";
import { usePlayerPossibleFalseAlarmStyles } from "./PlayerPossibleFalseAlarmDialog.styles";
import { PlayerDataStatus } from "Shared/types/Players";
import Loading from "Shared/components/utils/Loading";

interface IPLayerPossibleFalseAlarmDialog {
    open: boolean;
    onClose: () => void;
    player: Partial<IPlayersTableRow>;
    onSubmit: () => void;
    isLoading: boolean;
}

const PlayerPossibleFalseAlarmDialog: React.FC<IPLayerPossibleFalseAlarmDialog> = ({
    open,
    onClose,
    player,
    onSubmit,
    isLoading
  }) => {
    const classes = usePlayerPossibleFalseAlarmStyles();
    const { t } = useTranslation();

    const getFalsePositiveMessage = (player: any) =>{
      switch (player.gamblingEligibility)
      {
        case PlayerDataStatus.ForbiddenOnDato:
          return t("possibleFalsePossitivePep");
        case PlayerDataStatus.ForbiddenEu:
          return t("possibleFalsePossitiveEn");
        default:
          return t("possibleFalsePossitiveUn");
      }
    }

    if (!player) return <></>;
  
    const handleClose = () => {  
      onClose?.();
    }

    return (
      <Dialog open={open} onClose={() => handleClose()}>
        <Close onClick={() => handleClose()} className={classes.dialogClose} />
        <DialogTitle disableTypography={true} className={classes.dialogTitle}>
          <Grid direction="row">
            <Typography variant="h4" className={classes.transactionTitle}>
              {getFalsePositiveMessage(player)}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
            {isLoading ? (
            <>
                <Typography variant="body2" align="center">
                {t("checking")}...
                </Typography>
                <Loading width={40} />
            </>
            ) : null}

          <Paper elevation={0} className={classes.transactionRows}>
            <Button onClick={() => handleClose()}>{t("yes")}</Button>
            <Button onClick={() => onSubmit()}>{t("no")}</Button>
          </Paper>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default PlayerPossibleFalseAlarmDialog;