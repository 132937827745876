import { IAdminDateFilter } from "Shared/constants/Table";

export const dateUnitLeadingZero = (unit: number) => {
  return (unit < 10 ? "0" : "") + unit;
};

export const getLtBirthYear = (string: string) => {
  // Get birth year from first 3 NIN numbers
  let decade: number = parseInt(string.slice(0, 1));
  let year: number = parseInt(string.slice(1, 3));

  if (decade % 2 !== 0) decade += 1;
  return (17 + decade / 2) * 100 + year;
};

export const getBirthDateFromNationalId = (
  nationalId: string | undefined
): Date | undefined => {
  const year = getLtBirthYear(nationalId?.slice(0, 3) || "");
  const month = Number(nationalId?.slice(3, 5));
  const day = Number(nationalId?.slice(5, 7));
  if (!Number.isNaN(year) && !Number.isNaN(month) && !Number.isNaN(day)) {
    return new Date(`${year}/${month}/${day}`);
  }
  return undefined;
};

export const validateAndReturnFilterDate = (
  name: string,
  value: Date,
  dateFilter?: Partial<IAdminDateFilter>,
  fullRange?: boolean
) => {
  // Handles date input. Sets from/to filters based on the new input
  const minDate = new Date(
    dateFilter?.from ? (dateFilter.from as Date) : new Date()
  );
  const maxDate = new Date(
    dateFilter?.to ? (dateFilter.to as Date) : new Date()
  );
  if (isNaN(value?.getFullYear())) return;

  if (
    (name === "to" &&
      value.getTime() >= minDate.getTime() &&
      value.getTime() <= Date.now()) ||
    (name === "from" &&
      value.getTime() <= maxDate.getTime() &&
      value.getTime() <= Date.now())
  ) {
    let date = ("0" + value.getDate()).slice(-2);
    // current month
    let month = ("0" + (value.getMonth() + 1)).slice(-2);
    // current year
    let year = value.getFullYear();
    const formatedDate = `${year}-${month}-${date} ${
      name === "to"
        ? !fullRange
          ? "09:59:59"
          : "23:59:59"
        : !fullRange
        ? "10:00:00"
        : "00:00:00"
    }`;
    return {
      ...dateFilter,
      [name]: formatedDate,
    };
  }
};
