import { makeStyles } from "@material-ui/core/styles";

export const usePlayerTableStyles = makeStyles((theme) => ({
  tablePaper: {
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    padding: theme.spacing(3),
  },
  table: {
    tableLayout: "fixed",
  },
  firstLevelLimit: {
    background: "rgba(255, 193, 7, 0.2)",
  },
  secondLevelLimit: {
    background: "rgba(235, 87, 87, 0.2)",
  },
  row: {
    "&:hover": {
      background: "rgba(0,0,0,.085)",
      cursor: "pointer",
    },
  },
  nameContainer: {
    display: "flex",
    justifyItems: "center",
  },
  blocked: {
    color: "#F44336",
  },
  unknown: {
    color: "#F57C00",
  },
  nameText: {
    paddingTop: 3,
    paddingLeft: theme.spacing(0.5),
  },
  green: {
    color: theme.palette.primary.main,
  },
}));
