import { makeStyles } from "@material-ui/core/styles";

export const useSearchFieldStyles = makeStyles((theme) => ({
  tableSearchSimple: {
    borderBottom: "1px solid #C4C4C4",
    marginBottom: theme.spacing(2),
    width: 250,
  },
  iconPrimary: {
    color: theme.palette.primary.main,
  },
}));
