import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AdminFilterStorage } from "../../../constants/Interfaces";
import { TAnalysisState } from "../../../../Shared/types/Analysis";
import { IAppState } from "../../../../Shared/store/Store";
import { getAnalysisStats } from "../../../actions/AnalysisActions";
import { handleError } from "../../../../Shared/utils/ErrorHandler";
import Toast from "../../../../Shared/components/utils/Toast";
import AdminHeader from "../../../../Shared/components/PageHeader";

const GreenText = withStyles({
  root: {
    color: "#0AAF60",
    fontSize: 16,
    "&:not(:first-child)": {
      marginLeft: 30,
    },
  },
})(Typography) as typeof Typography;

const BoldText = withStyles({
  root: {
    fontSize: 14,
    color: "#000",
    fontWeight: "bolder",
    marginLeft: 4,
  },
})(Typography) as typeof Typography;

const HeaderWrapper = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();
  // Redux states
  const analysisState: TAnalysisState = useSelector<IAppState, TAnalysisState>(
    (state: IAppState) => state.analysisState
  );
  // fetch stats
  useEffect(() => {
    (async () => {
      const storageDate = localStorage.getItem(AdminFilterStorage.Date);
      const dates = storageDate
        ? JSON.parse(storageDate)
        : {
            from:  new Date(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(10, 0, 0)
            ),
            to: "",
          };
      const storageMoney = localStorage.getItem(AdminFilterStorage.Money);
      const money = storageMoney
        ? JSON.parse(storageMoney)
        : { "10kSum": false, "15k": false };
      try {
        if (!analysisState.isLoading && analysisState.data) {
          await dispatch(
            getAnalysisStats(
              Number(localStorage.getItem(AdminFilterStorage.Site)) ||
                undefined,
              analysisState.searchQuery || undefined,
              dates.from as Date,
              dates.to as Date,
              money["1k"] ? 1000 : money["15k"] ? 15000 : undefined
            )
          );
        }
      } catch (err: any) {
        handleError(err?.status, setError);
      }
    })();
  }, [
    analysisState.data,
    analysisState.isLoading,
    analysisState.page,
    analysisState.searchQuery,
    dispatch,
  ]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  return (
    <>
      <AdminHeader
        isLoading={analysisState.isLoadingStats}
        leftSide={
          <Grid container alignItems="baseline">
            <GreenText display="inline">{t("uniqueVisitors")}: </GreenText>
            <BoldText display="inline">
              {analysisState.uniquePlayersCount}
            </BoldText>

            <GreenText display="inline">{t("newVisitors")}: </GreenText>
            <BoldText display="inline">
              {analysisState.newPlayersCount}
            </BoldText>
          </Grid>
        }
        rightSide={
          <Grid container alignItems="baseline">
            <GreenText display="inline">{t("totalBets")}: </GreenText>
            <BoldText display="inline">
              {analysisState.totalInAmount &&
                Math.round((analysisState.totalInAmount + Number.EPSILON) * 100) / 100}{" "}
                
              eur
            </BoldText>
            <GreenText display="inline">{t("totalPayouts")}: </GreenText>
            <BoldText display="inline">
              {analysisState.totalOutAmount &&
                Math.round((analysisState.totalOutAmount + Number.EPSILON) * 100) / 100}{" "}
              eur
            </BoldText>
          </Grid>
        }
      />
    </>
  );
};

export default HeaderWrapper;
