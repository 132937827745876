import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import CashierMain from "Gaming/containers/CashierMain";
import AdminMain from "Gaming/containers/admin/AdminMain";
import { ROUTES } from "Gaming/constants/Routes";
import { APPLICATION_NAMES } from "Shared/constants/Applications";
import PrivateRoute from "Shared/router/PrivateRoute";
import { RoleDescription } from "Shared/constants/Roles";

function MainRouter() {
  useEffect(() => {
    document.title = APPLICATION_NAMES.Perlas;
  });

  return (
    <Switch>
      <PrivateRoute
        path={[ROUTES.ADMIN.MAIN]}
        component={AdminMain}
        roles={[RoleDescription.Admin]}
      />
      <PrivateRoute
        path={[ROUTES.CASHIER_MAIN]}
        component={CashierMain}
        roles={[RoleDescription.StoreUser]}
      />
    </Switch>
  );
}

export default MainRouter;
