import { makeStyles } from "@material-ui/core/styles";

export const useCashierMenuStyle = makeStyles((theme) => ({
  menu: {
    width: "100%",
  },
  listItem: {
    textAlign: "center",
    height: 50,
    width: "100%",
    borderBottom: `1px solid #828282`,
  },
  listItemActive: {
    backgroundColor: '#FFF',
  },
  listItemText: {
    color: "#181818",
  },
  listItemTextActive: {
    color: '#0AAF60',
  },
  activeItemIcon: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    color: '#0AAF60',
  },
}));
