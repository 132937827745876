import { makeStyles } from "@material-ui/core/styles";

export const useLogReportsTableBlockStyles = makeStyles((theme) => ({
  small: {
    fontSize: 12,
  },
  paper: {
    marginTop: theme.spacing(1),
    width: "100%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(3),
    position: "relative",
  },
  pagination: {
    position: "absolute",
    top: "90%",
  },
  tableContainer: {
    maxHeight: "90%",
    overflowX: "auto",
  }
}));
