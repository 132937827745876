import React from "react";
import { Button, FormControl } from "@material-ui/core";
import { PersonAddOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as Scan } from "../../../Shared/assets/icons/scan.svg";
import { IApiPlayerEntity } from "../../../Shared/types/Players";
import { usePlayerFormStyles } from "./PlayerForm.styles";
import FormInput from "../../../Shared/components/utils/FormInput";

interface IPlayerForm {
  onSubmit?: (nationalIdentificationNumber: string | undefined) => void;
  onAddNew?: () => void;
}

const PlayerForm: React.FC<IPlayerForm> = ({ onSubmit, onAddNew }) => {
  const { t } = useTranslation();
  const classes = usePlayerFormStyles();

  const submit = async (values: Partial<IApiPlayerEntity>) => {
    // pass entered it to parent component
    onSubmit?.(values.nationalIdentificationNumber);
  };

  return (
    <Form
      onSubmit={submit}
      validate={(values: Partial<IApiPlayerEntity>) => {
        const errors = {
          nationalIdentificationNumber: "",
        };

        const integerRe = /^(\d+)$/;
        const identificationNumber = values.nationalIdentificationNumber;

        // if (
        //   identificationNumber &&
        //   !identificationNumber.toString().match(integerRe)
        // ) {
        //   errors.nationalIdentificationNumber = t("illegalFormat");
        // }

        return !errors.nationalIdentificationNumber ? {} : errors;
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <div className={classes.container}>
            <FormInput
              name="nationalIdentificationNumber"
              id="nationalIdentificationNumber"
              height="56px"
              placeholder={t("nationalIdentificationNumber")}
              className={classes.fullWidth}
            />
            <FormControl>
              <Button
                color="primary"
                type="submit"
                className={clsx(classes.button, classes.fullWidth)}
                size="large"
                variant="outlined"
                startIcon={<Scan />}
              >
                {t("check")}
              </Button>
            </FormControl>
            <Button
              color="primary"
              className={clsx(classes.button, classes.fullWidth)}
              size="large"
              variant="outlined"
              disableElevation
              startIcon={<PersonAddOutlined />}
              onClick={() => onAddNew?.()}
            >
              {t("newPlayer")}
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default PlayerForm;
