import React from "react";
import { useTranslation } from "react-i18next";
import { Close } from "@material-ui/icons";
import { format } from "date-fns";
import { ReportProblemOutlined } from "@material-ui/icons";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { IPlayersTableRow } from "../../../constants/Interfaces";
import { useTransactionWarningStyles } from "./PlayerTransactionWarning.styles";

interface IPlayerTransactionWarning {
  player: Partial<IPlayersTableRow>;
  open: boolean;
  handleSubmit: () => void;
}

const PlayerTransactionWarning: React.FC<IPlayerTransactionWarning> = ({
  open,
  handleSubmit,
  player,
}) => {
  const classes = useTransactionWarningStyles();
  const { t } = useTranslation();
  return (
    <Dialog open={open} classes={{ paper: classes.root }}>
      <Close onClick={() => handleSubmit()} className={classes.dialogClose} />
      <DialogTitle disableTypography={true}>
        <Typography align="center" className={classes.dialogTitle}>
          {t("timeToRescanId")}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ReportProblemOutlined className={classes.warningIcon} />
        <Typography variant="h6" align="center">
          {`${player.name} ${t("nationalIdentificationNumberAbbr")} ${
            player.nationalIdentificationNumber
          }`}
        </Typography>
        <Typography variant="body2" align="center">
          {`${t("birthDate")}: ${format(
            new Date(player.birthDate || ""),
            "yyyy-MM-dd"
          )} ${player.citizenship}`}
        </Typography>
        <Typography variant="caption" align="center">
          {t("warningReachedTransactionLimit")}
        </Typography>

        <Button
          name="check"
          color="primary"
          size="large"
          variant="contained"
          disableElevation
          onClick={() => handleSubmit()}
          className={classes.button}
        >
          {t("done")}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default PlayerTransactionWarning;
