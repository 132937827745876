import React from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";

import { Button, FormControl, Typography, Grid } from "@material-ui/core";
import { SaveOutlined, Error } from "@material-ui/icons";
import { usePlayerTransactionStyles } from "./PlayerTransactionForm.styles";

import FormInput from "../../../../Shared/components/utils/FormInput";
import Loading from "../../../../Shared/components/utils/Loading";
import { IPlayerTransaction } from "../../../constants/Interfaces";

interface ITransactionForm {
  submitting: boolean;
  onSubmit?: (values: IPlayerTransaction) => void;
  error: string | undefined;
  setError: (error: string | undefined) => void;
}

// Separate interface because we allow entering currency as string: '1 000,10'
interface ITransactionInputs {
  inAmount: string;
  outAmount: string;
}

const PlayerTransactionForm: React.FC<ITransactionForm> = ({
  submitting,
  onSubmit,
  error,
}) => {
  const { t } = useTranslation();
  const classes = usePlayerTransactionStyles();

  const trimSpaces = (input: string) => {
    return (input || "").split(" ").join("");
  };

  const numberRegexExpression = /[^\d\n,]/g;

  return (
    <Form
      onSubmit={(values: ITransactionInputs) => {
        const outAmount = Number(
          trimSpaces(values.outAmount).replace(",", ".")
        );
        const inAmount = Number(trimSpaces(values.inAmount).replace(",", "."));

        onSubmit?.({ outAmount, inAmount });
      }}
      validate={(values: ITransactionInputs) => {
        const fieldErrors: any = {};
        const re = /^(\d+)(,\d{1,2})?$/;
        //           (\d+)           -- only numbers
        //                (,\d{1,2}) -- ',' symbol and 1 or 2 numbers (optonal)
        if (!values.inAmount && !values.outAmount) {
          return;
        }
        const formatedBid = values.inAmount?.replace(",", ".");
        const formatedCashIn = values.outAmount?.replace(",", ".");

        if (values.inAmount && !trimSpaces(values.inAmount).match(re)) {
          fieldErrors.inAmount = t("illegalFormat");
        }
        if (
          formatedBid &&
          (parseFloat(formatedBid) > 99999.99 || parseFloat(formatedBid) < 0.01)
        ) {
          fieldErrors.inAmount = t("outOfBounds");
        }
        if (
          formatedCashIn &&
          (parseFloat(formatedCashIn) > 99999.99 ||
            parseFloat(formatedCashIn) < 0.01)
        ) {
          fieldErrors.outAmount = t("outOfBounds");
        }
        if (values.outAmount && !trimSpaces(values.outAmount).match(re)) {
          fieldErrors.outAmount = t("illegalFormat");
        }

        // setError(undefined);
        return fieldErrors;
      }}
      render={({ handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.formContainer}>
            <Grid container direction="row">
              <FormInput
                name="inAmount"
                id="inAmount"
                className={classes.input}
                height="56px"
                label={t("isBetting")}
                onBlur={() => {
                  const changeTo = values.inAmount
                    ? trimSpaces(values.inAmount)
                    : "";
                  form.change("inAmount", changeTo);
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const changeTo = event.target.value
                    ? event.target.value.replace(numberRegexExpression, "")
                    : "";
                  form.change("inAmount", changeTo);
                }}
              />
              <FormInput
                name="outAmount"
                id="outAmount"
                className={classes.input}
                height="56px"
                label={t("cashInSum")}
                onBlur={() => {
                  const changeTo = values.outAmount
                    ? trimSpaces(values.outAmount)
                    : "";
                  form.change("outAmount", changeTo);
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const changeTo = event.target.value
                    ? event.target.value.replace(numberRegexExpression, "")
                    : "";
                  form.change("outAmount", changeTo);
                }}
              />
            </Grid>

            {error && (
              <Grid container justify="center" className={classes.fullWidth}>
                <Typography variant="body2" className={classes.error}>
                  <Error className={classes.errorIcon} />
                  {error}
                </Typography>
              </Grid>
            )}

            <Grid container justify="center" className={classes.fullWidth}>
              <FormControl>
                <Button
                  name="check"
                  color="primary"
                  className={classes.button}
                  size="large"
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                  disableElevation
                >
                  {submitting ? (
                    <Loading width={20} circleColor="white" />
                  ) : (
                    <>
                      <SaveOutlined className={classes.saveIcon} />
                      {t("save")}
                    </>
                  )}
                </Button>
              </FormControl>
            </Grid>
          </div>
        </form>
      )}
    />
  );
};

export default PlayerTransactionForm;
