import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import localeEn from "../assets/locales/en.json";
import localeLt from "../assets/locales/lt.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: localeEn,
  },
  lt: {
    translation: localeLt,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "lt",
    initImmediate: false,
    debug: true,
    ns: ["translations"],
    saveMissing: true,
    updateMissing: true,

    react: {
      wait: true,
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
