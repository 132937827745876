import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { IWorkerData } from "../../../../Shared/types/Worker";
import { useWorkerFormStyles } from "./WorkerForm.styles";
import FormInput from "../../../../Shared/components/utils/FormInput";
import FormSelect from "../../../../Shared/components/utils/FormSelect";
import PasswordStrengthHelp from "./passwordHelper/PasswordStrengthHelp";
import { Roles } from "../../../../Shared/constants/Roles";
import { IApiStoreEntity, TStoreState } from "../../../../Shared/types/Stores";
import { IAppState } from "../../../../Shared/store/Store";
import Loading from "../../../../Shared/components/utils/Loading";
import { TUserState } from "../../../../Shared/types/Users";

interface IWorkerForm {
  initialData?: Partial<IWorkerData>;
  onSubmit?: (worker: IWorkerData, sites?: IApiStoreEntity[]) => void;
  setPasswordDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const WorkerForm: React.FC<IWorkerForm> = ({
  initialData,
  onSubmit,
  setPasswordDialogOpen,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordHelp, setShowPasswordHelp] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [selectedSites, setSelectedSites] = React.useState<IApiStoreEntity[]>(
    []
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedSites(event.target.value as IApiStoreEntity[]);
  };

  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );
  const usersState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.usersState
  );

  useEffect(() => {
    if (storesState.data && initialData?.userStoreIds) {
      // if editing find user stores by their ids and set those stores
      const filtered = storesState.data.filter(
        (store) =>
          initialData.userStoreIds &&
          initialData?.userStoreIds.includes(store.id)
      );
      setSelectedSites(filtered);
    }
  }, [initialData?.userStoreIds, storesState.data]);

  const submit = async (newValues: IWorkerData) => {
    onSubmit?.(newValues, selectedSites);
  };
  const classes = useWorkerFormStyles();

  const availableRoles = [
    {
      value: Roles.Admin,
      text: t(`roleTypes.${Roles.Admin}`),
    },
    {
      value: Roles.StoreUser,
      text: t(`roleTypes.${Roles.StoreUser}`),
    },
  ];

  return (
    <Form
      initialValues={
        initialData && {
          ...initialData,
        }
      }
      onSubmit={submit}
      validate={(values: Partial<IWorkerData>) => {
        const errors: Record<any, string> = {};

        if (!initialData && !values.password) {
          errors.password = t("required");
        } else if (!initialData && !isPasswordValid) {
          errors.password = t("passwordComplexity");
        }

        if (!values.firstName?.trim()) {
          errors.firstName = t("required");
        }

        if (!values.lastName?.trim()) {
          errors.lastName = t("required");
        }

        if (!values.role || values.role <= 0) {
          errors.role = t("required");
        }

        if (!values.username) {
          errors.username = t("required");
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.container}>
            <FormInput
              name="firstName"
              id="firstName"
              height="56px"
              label={t("firstName")}
            />
            <FormInput
              name="lastName"
              id="lastName"
              height="56px"
              label={t("lastName")}
              className={classes.input}
            />
            <FormInput
              name="username"
              id="username"
              className={classes.input}
              height="56px"
              label={t("username")}
            />
            {!initialData && (
              <div className={classes.passwordStrengthContainer}>
                <FormInput
                  name="password"
                  id="password"
                  className={classes.input}
                  onFocus={() => setShowPasswordHelp(true)}
                  onBlur={() => setShowPasswordHelp(false)}
                  height={"56px"}
                  label={t("password")}
                  showPassword={() => setShowPassword(!showPassword)}
                  secondaryIcon={
                    showPassword ? (
                      <VisibilityOff className={classes.inputIconClickable} />
                    ) : (
                      <Visibility className={classes.inputIconClickable} />
                    )
                  }
                  autocomplete="password"
                  placeholder={t("password")}
                  type={showPassword ? "text" : "password"}
                />
                {showPasswordHelp && (
                  <PasswordStrengthHelp
                    password={values.password}
                    setIsPasswordValid={(isValid: boolean) =>
                      setIsPasswordValid(isValid)
                    }
                  />
                )}
              </div>
            )}
            <FormSelect
              id="role"
              className={classes.input}
              name="role"
              height="56px"
              variant="outlined"
              label={t("role")}
              options={availableRoles}
              optionValue="value"
              optionDisplay="text"
            />
            {values?.role && Number(values.role) === Roles.StoreUser && (
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.input}
              >
                <InputLabel htmlFor="site-input">
                  {t("gamblingSite")}
                </InputLabel>
                <Select
                  label={t("gamblingSite")}
                  style={{ height: "56px" }}
                  multiple
                  value={selectedSites}
                  onChange={handleChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  inputProps={{
                    classes: {
                      icon: classes.arrowIcon,
                    },
                    name: "site-inp1ut",
                    id: "stie-input",
                  }}
                  renderValue={(selected: any) => (
                    <div className={classes.chips}>
                      {(selected as IApiStoreEntity[]).map((value) => (
                        <Chip
                          key={value.id}
                          label={value.name}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                >
                  {storesState.data &&
                    storesState.data.map((site: any) => (
                      <MenuItem key={site.id} value={site}>
                        {site.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

            <div
              className={classes.buttonContainer}
              style={{
                justifyContent: initialData ? "space-between" : "center",
              }}
            >
              {initialData && (
                <FormControl>
                  <Button
                    disabled={submitting}
                    className={classes.button}
                    size="large"
                    variant="outlined"
                    disableElevation
                    onClick={() => setPasswordDialogOpen(true)}
                  >
                    {t("changePassword")}
                  </Button>
                </FormControl>
              )}
              <FormControl>
                <Button
                  color="primary"
                  type="submit"
                  disabled={submitting}
                  className={classes.button}
                  size="large"
                  variant="contained"
                  disableElevation
                >
                  {submitting || usersState.isLoadingNew ? (
                    <Loading width={20} circleColor="white" />
                  ) : (
                    <>{t("save")}</>
                  )}
                </Button>
              </FormControl>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default WorkerForm;
