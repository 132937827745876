import React from "react";
import { Switch } from "react-router-dom";
import AdminWorkers from "../containers/admin/AdminWorkers";
import AdminSinglePlayer from "../containers/admin/AdminSinglePlayer";
import AdminPlayers from "../containers/admin/AdminPlayers";
import { ROUTES } from "../constants/Routes";
import PrivateRoute from "../../Shared/router/PrivateRoute";
import { RoleDescription } from "../../Shared/constants/Roles";

const AdminRouter = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={[ROUTES.ADMIN.WORKERS, '/']}
        component={AdminWorkers}
        roles={[RoleDescription.Admin]}
      />
      <PrivateRoute
        exact
        path={[ROUTES.ADMIN.PLAYER]}
        component={AdminSinglePlayer}
        roles={[RoleDescription.Admin]}
      />
      <PrivateRoute
        exact
        path={[ROUTES.ADMIN.PLAYERS, ROUTES.ADMIN.MAIN]}
        component={AdminPlayers}
        roles={[RoleDescription.Admin]}
      />
    </Switch>
  );
};

export default AdminRouter;
