import { Reducer } from "redux";
import {
  initialAnalysisState,
  TAnalysisActions,
  TAnalysisState,
  GetAnalysisPlayers,
  GetAnalysisStats,
} from "../types/Analysis";

export const analysisReducer: Reducer<TAnalysisState, TAnalysisActions> = (
  state = initialAnalysisState,
  action
) => {
  switch (action.type) {
    case GetAnalysisPlayers.REQUEST: {
      return {
        ...state,
        isLoading: true,
        page: action.page,
        searchQuery: action.searchQuery,
      };
    }
    case GetAnalysisStats.REQUEST: {
      return {
        ...state,
        isLoadingStats: true,
      }
    }
    case GetAnalysisStats.FAILED: {
      return {
        ...state,
        isLoadingStats: false,
      }
    }
    case GetAnalysisPlayers.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GetAnalysisStats.SUCCESS: {
      return {
        ...state,
        isLoadingStats: false,
        uniquePlayersCount: action.payload.data.uniquePlayersCount,
        newPlayersCount: action.payload.data.newPlayersCount,
        totalInAmount: action.payload.data.totalInAmount,
        totalOutAmount: action.payload.data.totalOutAmount,
      }
    }
    case GetAnalysisPlayers.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        totalPages: action.payload.data.totalPages,
        totalPlayerCount: action.payload.data.totalCount,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
