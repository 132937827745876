import { AxiosError, AxiosResponse } from "axios";

export enum PlayerDataStatus {
  Allowed,
  Forbidden,
  Inconclusive,
  ForbiddenEu,
  ForbiddenUn,
  ForbiddenOnDato,
}

export interface IApiPlayerEntity {
  firstName: string | null;
  lastName: string | null;
  playerName?: string;
  nationalIdentificationNumber: string;
  birthDate: string;
  citizenship: string | null;
  restrictedFromGambling: boolean;
  gamblingEligibility: PlayerDataStatus;
  id?: number;
  playerId?: number;
  latestTransactionInStore?: Date;
  forced: boolean;
  shift?: string;
  alteredManually: boolean;
  note?: string;
}

export interface IApiPlayerIdentification {
  playerId: number;
}

export interface TPlayerState {
  readonly data?: Array<IApiPlayerEntity>;
  readonly selectedPlayer?: IApiPlayerEntity;
  readonly latestFoundPlayer?: IApiPlayerEntity;
  readonly isLoading?: boolean;
  readonly isLoadingPlayerFind?: boolean;
}

export const initialPlayersState: TPlayerState = {
  data: undefined,
  selectedPlayer: undefined,
  latestFoundPlayer: undefined,
  isLoading: false,
  isLoadingPlayerFind: false,
};

export enum PlayerFind {
  REQUEST = "PLAYERS_FIND_REQUEST",
  SUCCESS = "PLAYERS_FIND_SUCCESS",
  FAILED = "PLAYERS_FIND_FAILED",
}

export enum PlayersGet {
  REQUEST = "PLAYERS_GET_REQUEST",
  SUCCESS = "PLAYERS_GET_SUCCESS",
  FAILED = "PLAYERS_GET_FAILED",
}

export enum PlayerGet {
  REQUEST = "PLAYER_GET_REQUEST",
  SUCCESS = "PLAYER_GET_SUCCESS",
  FAILED = "PLAYER_GET_FAILED",
}

export enum PlayerPost {
  REQUEST = "PLAYER_POST_REQUEST",
  SUCCESS = "PLAYER_POST_SUCCESS",
  FAILED = "PLAYER_POST_FAILED",
}

export enum PlayerPut {
  SUCCESS = "PLAYER_PUT_SUCCESS",
  REQUEST = "PLAYER_PUT_REQUEST",
  FAILED = "PLAYER_PUT_FAILED",
}

export enum ChangePlayer {
  CHANGE = "CHANGE_PLAYER",
}

export type TPlayerFindAction =
  | {
      type: PlayerFind.REQUEST;
    }
  | {
      type: PlayerFind.SUCCESS;
      payload: AxiosResponse<IApiPlayerEntity>;
    }
  | {
      type: PlayerFind.FAILED;
      payload: AxiosError;
    };

export type TChangePlayer = {
  type: ChangePlayer.CHANGE;
  payload: IApiPlayerEntity;
};

export type TPlayerPutActions =
  | {
      type: PlayerPut.REQUEST;
    }
  | {
      type: PlayerPut.SUCCESS;
      payload: AxiosResponse<IApiPlayerEntity>;
    }
  | {
      type: PlayerPut.FAILED;
      payload: AxiosError;
    };

export type TPlayerPostActions =
  | {
      type: PlayerPost.REQUEST;
    }
  | {
      type: PlayerPost.SUCCESS;
      payload: AxiosResponse<IApiPlayerEntity>;
    }
  | {
      type: PlayerPost.FAILED;
      payload: AxiosError;
    };

export type TPlayersGetActions =
  | {
      type: PlayersGet.REQUEST;
    }
  | {
      type: PlayersGet.SUCCESS;
      payload: AxiosResponse<Array<IApiPlayerEntity>>;
    }
  | {
      type: PlayersGet.FAILED;
      payload: AxiosError;
    };

export type TPlayerGetActions =
  | {
      type: PlayerGet.REQUEST;
    }
  | {
      type: PlayerGet.SUCCESS;
      payload: AxiosResponse<IApiPlayerEntity>;
    }
  | {
      type: PlayerGet.FAILED;
      payload: AxiosError;
    };

export type TPlayerActions =
  | TPlayersGetActions
  | TPlayerGetActions
  | TPlayerPostActions
  | TPlayerPutActions
  | TChangePlayer
  | TPlayerFindAction;
