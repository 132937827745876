import { makeStyles } from "@material-ui/core/styles";

export const useCallBackComponentStyles = makeStyles((theme) => ({
  text: {
    paddingBottom: 0,
    marginTop: theme.spacing(3),
  },
  main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: '100%',
  },
}));
