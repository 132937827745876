import React from "react";
import { useCashierMainStyles } from "./CashierMain.styles";
import UserTab from "../../../Shared/components/UserTab";
import CashierPanel from "../../components/cashier/CashierPanel";
import CashierRouter from "../../router/CashierRouter";

const CashierMain: React.FC = () => {
  const classes = useCashierMainStyles();

  return (
    <div className={classes.root}>
      <div className={classes.leftSideContainer}>
        <UserTab />
        <CashierPanel />
      </div>
      <div className={classes.rightSideContainer}>
        <CashierRouter />
      </div>
    </div>
  );
};

export default CashierMain;
