import { makeStyles } from "@material-ui/core/styles";

export const useHeaderInputs = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  smallButton: {
    fontSize: 11,
    marginLeft: theme.spacing(1),
    display: 'block'
  },
  buttonLoader: {
    fontSize: 11,
    marginLeft: theme.spacing(1),
    display: 'flex',
    paddingLeft: 40,
  },
  smallInput: {
    width: 200,
    "& .MuiOutlinedInput-root": {
      fontSize: 12,
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 8,
      paddingBottom: 6,
    },
  },
  inputPlaceholder: {
    "&::placeholder": {
      opacity: 1,
    },
  },
  buttonIconPlaceHolder: {
    width: 20,
  },
  buttonIcon: {
    width: '20px !important',
    height: '20px !important',
    color: theme.palette.secondary.main,
  },
  mtCustom: {
    [theme.breakpoints.down(1470)]: {
      marginTop: theme.spacing(1),
    },
  }
}));
