import {
  initialUserState,
  TUserActions,
  TUserState,
  UsersGet,
  UsersPost,
  UsersPut,
  UserChangePassword,
} from "../types/Users";
import { Reducer } from "redux";

export const usersReducer: Reducer<TUserState, TUserActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case UsersGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    }
    case UsersPost.SUCCESS: {
      return {
        ...state,
        data: state.data ? [action.payload.data, ...state?.data] : [action.payload.data],
        isLoadingNew: false,
      };
    }
    case UserChangePassword.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case UsersPut.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: state.data
        ? state.data.map(rec =>
            rec.id === action.payload.data.id ? action.payload.data : rec
          )
        : [],
      };
    }
    case UsersPost.FAILED: {
      return {
        ...state,
        isLoadingNew: false,
      }
    }
    case UserChangePassword.FAILED:
    case UsersPut.FAILED:
    case UsersGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case UsersPost.REQUEST: {
      return {
        ...state,
        isLoadingNew: true,
      }
    }
    case UserChangePassword.REQUEST:
    case UsersPut.REQUEST:
    case UsersGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default: {
      return state;
    }
  }
};
