import React from "react";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { Block } from "@material-ui/icons";
import { Typography, Button, Box } from "@material-ui/core";
import { useTransactionEditDialogStyles } from "./TransactionEditDialog.styles";
import { IApiTransactionCancellationDetails } from "../../../../Shared/types/Transactions";

interface ITransactionCancelDialogContent {
  transaction: any;
  onClose: () => void;
  onDelete: () => void;
  cancellationDetails?: IApiTransactionCancellationDetails;
  loading?: boolean;
}

const TransactionCancelDialogContent: React.FC<ITransactionCancelDialogContent> = ({
  transaction,
  onClose,
  onDelete,
  cancellationDetails,
  loading,
}) => {
  const classes = useTransactionEditDialogStyles();
  const { t } = useTranslation();
  if (!transaction) return null;

  if (transaction?.isCancelled) {
    return (
      <>
        <Block className={classes.cancelledBigIcon} />
        <Typography align="center">
          {t("transactionCancelledBy")}
          <b>{` ${cancellationDetails?.userName}`}</b>
          <br />
          <b>{`${format(
            new Date(`${cancellationDetails?.cancelledOn}Z` || ""),
            "yyyy-MM-dd HH:mm:ss"
          )}`}</b>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          className={classes.button}
        >
          {t("close")}
        </Button>
      </>
    );
  }

  return (
    <>
      <Typography>{t("cancelTransaction")}</Typography>
      <Box display="flex" gridGap={8} mt={2}>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={onDelete}
          className={classes.yesButton}
        >
          {t("yes")}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClose}
          className={classes.noButton}
        >
          {t("no")}
        </Button>
      </Box>
    </>
  );
};

export default TransactionCancelDialogContent;
