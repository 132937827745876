import { CurrentApp } from "../../Shared/types/App";
import { APPLICATIONS } from "../constants/Applications";
import { APPLICATION_ITEM } from "../constants/Storage";

export const setCurrentApp = (app: APPLICATIONS) => {
  localStorage.setItem(APPLICATION_ITEM, app);

  return {
    type: CurrentApp.SET,
    payload: app,
  };
};
