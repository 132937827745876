import React, { useState } from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { IApiStoreEntity } from "../../../../Shared/types/Stores";
import { IWorkerData } from "../../../../Shared/types/Worker";
import { useWorkerEditDialogStyles } from "./WorkerEditDialog.styles";
import WorkerEditPasswordDialog from "./WorkerEditPasswordDialog";
import WorkerForm from "./WorkerForm";

interface IDialog {
  open: boolean;
  onClose?: () => void;
  onSubmit?: (values: IWorkerData, sites?: IApiStoreEntity[]) => void;
  initialData?: Partial<IWorkerData>;
}

const WorkerEditDialog: React.FC<IDialog> = ({
  open,
  onClose,
  onSubmit,
  initialData,
}) => {
  const classes = useWorkerEditDialogStyles();
  const { t } = useTranslation();
  const [passwordDialogOpen, setPasswordDialogOpen] = useState<boolean>(false);

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <Close onClick={() => handleClose()} className={classes.dialogClose} />
      <DialogTitle disableTypography={true}>
        <Typography align="center" variant="h4">
          {initialData ? t("editWorker") : t("newWorker")}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <WorkerForm
          initialData={initialData}
          onSubmit={(values: IWorkerData, selectedSites?: IApiStoreEntity[]) =>
            onSubmit?.(values, selectedSites)
          }
          setPasswordDialogOpen={setPasswordDialogOpen}
        />
      </DialogContent>
      <WorkerEditPasswordDialog
        open={passwordDialogOpen}
        userId={initialData?.id}
        onClose={() => setPasswordDialogOpen(false)}
      />
    </Dialog>
  );
};

export default WorkerEditDialog;
