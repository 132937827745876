import React from "react";
import {} from "@material-ui/icons";
import { useSkeletonFieldStyles } from "./SkeletonField.styles";

interface ISkeletonText {
  styles?: React.CSSProperties;
  className?: string;
}

const SkeletonField: React.FC<ISkeletonText> = ({ styles, className }) => {
  const classes = useSkeletonFieldStyles();

  return <div style={styles} className={`${classes.root} ${className}`}></div>;
};

export default SkeletonField;
