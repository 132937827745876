import { makeStyles } from "@material-ui/core/styles";

export const useAdminPlayersStyles = makeStyles((theme) => ({
  root: {},
  tablePaper: {
    width: "100%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(1),
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    padding: theme.spacing(3),
  },
  toolText: {
    display: "flex",
    alignItems: "center",
  },
  green: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  moreLink: {
    paddingRight: theme.spacing(1),
    "&:hover": {
      cursor: "pointer",
    },
  },
  small: {
    fontSize: 12,
  },
  big: {
    fontSize: 14,
  },
  editPlaceHolder: {
    width: 24,
  }
}));
