import { makeStyles } from "@material-ui/core/styles";

export const useAdminEventsStyles = makeStyles((theme) => ({
  green: {
    color: "#0AAF60",
  },
  red: {
    color: "#ff0000",
  },
  small: {
    fontSize: 12,
  },
  big: {
    fontSize: 14,
  },

  paper: {
    marginTop: theme.spacing(1),
    width: "100%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(3),
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end !important",
  },
  leftGrid: {
    justifyContent: 'space-between',
    marginRight: theme.spacing(2),
  },
}));
