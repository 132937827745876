import { makeStyles } from "@material-ui/core/styles";

const WarningIconSize = 120;

export const useTransactionWarningStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 550,
  },
  dialogTitle: {
    fontSize: 32,
  },
  dialogContent: {
    margin: "0 auto",
    maxWidth: "85%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 0,
  },
  warningIcon: {
    color: "#FFC107",
    height: WarningIconSize,
    width: WarningIconSize,
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&.MuiButton-containedPrimary ": {
      color: "white",
    },
  },
  dialogClose: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: "#CECECE",
    fontSize: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
