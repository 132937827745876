import { makeStyles } from "@material-ui/core/styles";

export const usePlayerInfoStyles = makeStyles((theme) => ({
  name: {
    gridColumn: "auto / span 2",
    height: "30px",
    width: "100%",
    marginBottom: theme.spacing(3),
  },
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  playerFields: {
    display: "grid",
    gridRowGap: theme.spacing(1),
    gridColumnGap: theme.spacing(0.5),
    gridTemplateColumns: "1fr 1fr",
    width: "250px",
    marginBottom: theme.spacing(4),
  },
  blocked: {
    color: "#F44336",
  },
  unknown: {
    color: "#F57C00",
  },
}));
