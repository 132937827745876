import { AxiosResponse } from "axios";
import {
  IApiPlayerEntity,
  TPlayerActions,
  PlayersGet,
  PlayerGet,
  PlayerPost,
  PlayerFind,
  ChangePlayer,
  PlayerPut,
  IApiPlayerIdentification
} from "../../Shared/types/Players";
import { TDispatch } from "../../Shared/types/Thunk";
import { apiCall, apiClient } from "../../Shared/actions/BaseAction";
import { API_END_POINTS } from "../constants/Api";
import { format } from "date-fns";

export const getPlayersList = () => {
  return apiCall<TPlayerActions, null, Array<IApiPlayerEntity>>(
    PlayersGet,
    "GET",
    API_END_POINTS.PLAYERS,
    true
  );
};

export const findPlayer = (
  storeId: number,
  nationalIdentificationNumber: string | undefined,
  callback: (value: IApiPlayerEntity) => void,
  firstName?: string | null,
  lastName?: string | null,
  birthDate?: string
) => {
  return async (
    dispatch: TDispatch<PlayerFind>
  ): Promise<AxiosResponse<IApiPlayerEntity>> => {
    dispatch({
      type: PlayerFind.REQUEST,
    });

    let birthYear = birthDate
      ? format(new Date(birthDate), "yyyy-MM-dd")
      : undefined;

    try {
      const response = await apiClient.request({
        method: "GET",
        url: API_END_POINTS.PLAYER_FIND(storeId),
        params: {
          nationalIdentificationNumber,
          firstName,
          lastName,
          birthDate: birthYear,
        },
      });
      dispatch({
        type: PlayerFind.SUCCESS,
        payload: response,
      });
      callback(response.data);
      return response.data;
    } catch (error: any) {
      dispatch({
        type: PlayerFind.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getStorePlayer = (id: number) => {
  return apiCall<TPlayerActions, null, IApiPlayerEntity>(
    PlayerGet,
    "GET",
    API_END_POINTS.PLAYER(id),
    true
  );
};

export const postPlayer = (storeId: number, data: IApiPlayerEntity) => {
  return apiCall<TPlayerActions, IApiPlayerEntity, IApiPlayerEntity>(
    PlayerPost,
    "POST",
    API_END_POINTS.PLAYERS_STORE(storeId),
    true,
    data,
    {}
  );
};

export const postPlayerFalseAlarm = (storeId: number, data: IApiPlayerIdentification) => {
  return apiCall<TPlayerActions, IApiPlayerIdentification, IApiPlayerEntity>(
    PlayerPost,
    "POST",
    API_END_POINTS.PLAYER_FALSE_ALARM(storeId),
    true,
    data,
    {}
  );
};

export const editPlayer = (playerId: number, player: IApiPlayerEntity) => {
  return apiCall<TPlayerActions, IApiPlayerEntity, IApiPlayerEntity>(
    PlayerPut,
    "PUT",
    API_END_POINTS.PLAYER_EDIT(playerId),
    true,
    player,
    {}
  );
};

export const changeCurrentPlayer = (player: IApiPlayerEntity) => {
  return {
    type: ChangePlayer.CHANGE,
    payload: player,
  };
};
