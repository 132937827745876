import React, {useEffect} from "react";
import { Typography } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { useToastStyles } from "./Toast.styles";

interface IToast {
  text: string;
  isError?: boolean;
  cleanup: () => void;
}

const Toast: React.FC<IToast> = ({ text, isError, cleanup }) => {
  const classes = useToastStyles();
  useEffect(() => {
    return () => cleanup?.()
  }, [cleanup]);
  
  return (
    <div className={classes.text}>
      {isError && (
        <span>
          <ErrorOutline />
        </span>
      )}
      <Typography variant="subtitle1" className={classes.textMargin}>
        {text}
      </Typography>
    </div>
  );
};

export default Toast;