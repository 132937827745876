import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { ROUTES } from "ToTo/constants/Routes";
import AdminMain from "ToTo/containers/admin/AdminMain";
import CashierMain from "ToTo/containers/cashier/CashierMain";
import PrivateRoute from "Shared/router/PrivateRoute";
import { RoleDescription } from "Shared/constants/Roles";
import { APPLICATION_NAMES } from "Shared/constants/Applications";

function MainRouter() {
  useEffect(() => {
    document.title = APPLICATION_NAMES.ToTo;
  });

  return (
    <Switch>
      <PrivateRoute
        path={[ROUTES.ADMIN.MAIN]}
        component={AdminMain}
        roles={[RoleDescription.Admin]}
      />
      <PrivateRoute
        path={ROUTES.CASHIER.MAIN}
        component={CashierMain}
        roles={[RoleDescription.StoreUser]}
      />
    </Switch>
  );
}

export default MainRouter;
