import { makeStyles } from "@material-ui/core/styles";

export const useScanConfirmationStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 430,
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dialogClose: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: "#CECECE",
    fontSize: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  green: {
    color: theme.palette.primary.main,
  },
  button: {
    minHeight: 48,
    minWidth: 200,
  },
}));
