import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import { Close, CheckCircleOutline, Save } from "@material-ui/icons";
import SelectionTable from "../../components/SelectionTable";
import { useCashierTakeBetModalStyles } from "./CashierTakeBetModal.style";
import clsx from "clsx";
import { CashierEventsTableKeys } from "../../constants/Interfaces";
import { IBettingEvent } from "../../types/BettingEvents";

interface IEventCreateModal {
  open: boolean;
  onClose: () => void;
  setSelectedEvent: (event: IBettingEvent) => void;
  events: Array<IBettingEvent>;
}

const CashierTakeBetModal: React.FC<IEventCreateModal> = ({
  open,
  onClose,
  setSelectedEvent,
  events,
}) => {
  const { t } = useTranslation();
  const classes = useCashierTakeBetModalStyles();
  const [selectedRow, setSelectedRow] = useState<IBettingEvent>();

  const columns = useMemo(
    () => [
      {
        Header: t("event"),
        accessor: CashierEventsTableKeys.title,
        id: CashierEventsTableKeys.title,
      },
      {
        Header: "",
        id: "warning",
        Cell: (tableInfo: any) =>
          selectedRow?.id === tableInfo.row.original.id && (
            <Grid container justify="flex-end">
              <CheckCircleOutline
                className={clsx(classes.checkedIcon, classes.green)}
              />
            </Grid>
          ),
      },
    ],
    [classes.checkedIcon, classes.green, selectedRow?.id, t]
  );

  const handleClick = () => {
    if (selectedRow) {
      setSelectedEvent(selectedRow);
      onClose();
    } else {
      return;
    }
  };

  const handleClose = () => {
    setSelectedRow(undefined);
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Close onClick={handleClose} className={classes.dialogClose} />
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h4">{t("acceptBet")}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <SelectionTable
            selectedRows={selectedRow}
            setSelectedRows={setSelectedRow}
            data={events}
            isTakeBet
            columns={columns}
          />
          <Grid container justify="center">
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleClick}
              color="secondary"
              type="submit"
              disabled={!selectedRow}
              disableElevation
              size="small"
            >
              <Save className={classes.saveIcon} />
              <Typography style={{ paddingTop: 5 }} variant="caption">
                {t("save")}
              </Typography>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CashierTakeBetModal;
