import { makeStyles } from "@material-ui/core/styles";

export const usePlayerTableStyles = makeStyles((theme) => ({
  tablePaper: {
    width: "100%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(1),
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    padding: theme.spacing(3),
  },
  table: {
    marginTop: theme.spacing(1),
    tableLayout: "auto",
  },
  tableSearchSimple: {
    borderBottom: "1px solid #C4C4C4",
    marginBottom: theme.spacing(2),
    width: 250,
  },

  firstLevelLimit: {
    backgroundColor: "#FF9C07",
    color: "#fff",
    borderRadius: 9,
    padding: 3,
  },
  firstLevelBackground: {
    backgroundColor: "rgba(255, 193, 7, 0.2)",
  },
  secondLevelLimit: {
    backgroundColor: "#F44336",
    color: "#fff",
    borderRadius: 9,
    padding: 3,
  },
  secondLevelBackground: {
    backgroundColor: "rgba(235, 87, 87, 0.2)",
  },

  nameContainer: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
  },
  blocked: {
    color: "#F44336",
  },
  unknown: {
    color: "#F57C00",
  },
  nameText: {
    paddingTop: 3,
    paddingLeft: theme.spacing(0.5),
  },
  pencilIcon: {
    color: "rgba(0,0,0,.18)",
    fontSize: 20,
    paddingLeft: theme.spacing(0.5),
  },
  loadMore: {
    marginTop: theme.spacing(2),
    fontSize: 11,
  },
}));
