import { APPLICATIONS } from "../constants/Applications";

export interface TAppState {
  readonly loading?: boolean;
  readonly loaded?: boolean;
  readonly error?: boolean | [];
  readonly activeApp?: APPLICATIONS;
}

export enum CurrentApp {
  SET = "SET_CURRENT_APP",
}

export type TCurrentAppActions = {
  type: CurrentApp.SET;
  payload: string;
};

export type TAppActions = TCurrentAppActions;
