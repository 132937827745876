import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import {
  Close,
  Print,
  Block,
  QueryBuilder,
  CheckCircleOutline,
  CancelOutlined,
} from "@material-ui/icons";
import { useTicketCheckDialog } from "./TicketCheckDialog.styles";
import Toast from "../../../../Shared/components/utils/Toast";
import { IAppState } from "../../../../Shared/store/Store";
import {
  BettingEventTicketStatus,
  TBettingEventTicketState,
} from "../../../types/BettingEventTickets";
import { ticketStatusChange } from "../../../actions/BettingEventTicketActions";
import { handleError } from "../../../../Shared/utils/ErrorHandler";
import TicketCheckButton from "./TicketCheckButton";
import { TStoreState } from "Shared/types/Stores";
import { sendMessage, getSocket } from "Shared/utils/WebSockets";

interface ITicketCheckDialog {
  handleClose: () => void;
  open: boolean;
}

interface DialogComp {
  title: string;
  icon: JSX.Element;
  bidSum?: number;
  button: JSX.Element;
}

const TicketCheckDialog: React.FC<ITicketCheckDialog> = ({
  handleClose,
  open,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useTicketCheckDialog();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [ticketExpired, setTicketExpired] = useState(false);
  const [dialogItems, setDialogItems] = useState<DialogComp>();
  const [loading, setLoading] = useState<boolean>(false);

  const bettingEventTicketState: TBettingEventTicketState = useSelector<
    IAppState,
    TBettingEventTicketState
  >((state: IAppState) => state.bettingEventTicketState);

  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );

  const socket = getSocket();

  socket.onmessage = (message: { data: string }) => {
    if (message) {
      if (message.data?.indexOf("CashRemaining") !== -1) {
        const cashInStore = message.data.toLowerCase().split("remaining")[1];
        if (
          bettingEventTicketState?.data?.status ===
          BettingEventTicketStatus.Winning
        ) {
          if (
            (bettingEventTicketState?.data?.ticketWonAmount || 0) >
            Number(cashInStore)
          ) {
            setDisableButton(true);
            setError(t("noCash"));
          }
        } else if (
          bettingEventTicketState?.data?.status ===
          BettingEventTicketStatus.Cancelled
        ) {
          if (
            bettingEventTicketState?.data?.ticketPrice > Number(cashInStore)
          ) {
            setDisableButton(true);
            setError(t("noCash"));
          }
        }
      } else {
        setDisableButton(true);
        setError(t("noCash"));
      }
    } else {
      setDisableButton(true);
      setError(t("noCash"));
    }
    setLoading(false);
    return true;
  };

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  const determineAndPrintTicket = useCallback(
    async (ticketStatus: number) => {
      try {
        if (
          bettingEventTicketState.data?.ticketNumber &&
          storesState?.selectedStore?.id
        ) {
          setSubmitting(true);
          await dispatch(
            ticketStatusChange(
              storesState?.selectedStore?.id,
              bettingEventTicketState.data?.ticketNumber,
              ticketStatus
            )
          );
          setSubmitting(false);
          handleClose();
        }
      } catch (err: any) {
        handleError(err?.status, setError);
        setSubmitting(false);
      }
    },
    [bettingEventTicketState.data, handleClose, dispatch, storesState?.selectedStore?.id]
  );

  const getDialog = useCallback(
    (ticketStatus: number) => {
      console.log(disableButton, error , 'TESTAS DISABLE')
      switch (ticketStatus) {
        case BettingEventTicketStatus.Cancelled: {
          setDialogItems({
            title: t("ticketStatus.ticketCancelled"),
            icon: (
              <Block className={clsx(classes.icon, classes.iconCanceled)} />
            ),
            bidSum: bettingEventTicketState.data
              ? bettingEventTicketState.data.ticketPrice
              : undefined,
            button: (
              <TicketCheckButton
                disabled={disableButton}
                expired={ticketExpired}
                buttonClass={clsx(classes.button, classes.buttonCanceled)}
                icon={<Print className={classes.printIcon} />}
                loading={submitting}
                text={t("returnTicket")}
                handleClick={() =>
                  determineAndPrintTicket(BettingEventTicketStatus.Cancelled)
                }
              />
            ),
          });
          break;
        }

        case BettingEventTicketStatus.Active: {
          setDialogItems({
            title: t("ticketStatus.ticketActive"),
            icon: (
              <QueryBuilder
                className={clsx(classes.icon, classes.iconActive)}
              />
            ),
            button: (
              <TicketCheckButton
                buttonClass={clsx(classes.button, classes.buttonActive)}
                handleClick={handleClose}
                text={t("close")}
              />
            ),
          });
          break;
        }
        case BettingEventTicketStatus.PaidOut: {
          setDialogItems({
            title: t("ticketStatus.ticketPayedOut"),
            icon: (
              <img
                src="/paidOut.svg"
                alt="refund"
                className={classes.svgPaidOut}
              />
            ),
            button: (
              <TicketCheckButton
                buttonClass={clsx(classes.button, classes.buttonPaidOut)}
                handleClick={handleClose}
                text={t("close")}
              />
            ),
          });
          break;
        }

        case BettingEventTicketStatus.Refunded: {
          setDialogItems({
            title: t("ticketStatus.ticketRefunded"),
            icon: (
              <img src="/refund.svg" alt="refund" className={classes.svg} />
            ),
            button: (
              <TicketCheckButton
                buttonClass={clsx(classes.button, classes.buttonPaidOut)}
                handleClick={handleClose}
                text={t("close")}
              />
            ),
          });
          break;
        }

        case BettingEventTicketStatus.Losing: {
          setDialogItems({
            title: t("ticketStatus.ticketLost"),
            icon: (
              <CancelOutlined
                className={clsx(classes.icon, classes.iconLosing)}
              />
            ),
            button: (
              <TicketCheckButton
                buttonClass={clsx(classes.button, classes.buttonLosing)}
                icon={<Print className={classes.printIcon} />}
                handleClick={() =>
                  determineAndPrintTicket(BettingEventTicketStatus.Losing)
                }
                loading={submitting}
                text={t("printTicket")}
              />
            ),
          });
          break;
        }

        case BettingEventTicketStatus.Winning: {
          setDialogItems({
            title: t("ticketStatus.ticketWon"),
            bidSum: bettingEventTicketState.data
              ? bettingEventTicketState.data.ticketWonAmount
              : undefined,
            icon: (
              <CheckCircleOutline
                className={clsx(classes.icon, classes.iconWinning)}
              />
            ),
            button: (
              <TicketCheckButton
                buttonClass={clsx(classes.button, classes.buttonWinning)}
                icon={<Print className={classes.printIcon} />}
                loading={submitting}
                text={t("payOut")}
                disabled={disableButton}
                expired={ticketExpired}
                handleClick={() =>
                  determineAndPrintTicket(BettingEventTicketStatus.Winning)
                }
              />
            ),
          });
          break;
        }
      }
    },
    [t, disableButton, bettingEventTicketState.data, ticketExpired, submitting, determineAndPrintTicket, handleClose]
  );

  useEffect(() => {
    (async () => {
      if (bettingEventTicketState.data?.status) {
        if (bettingEventTicketState.data.ticketExpired) {
          setTicketExpired(true);
        }
        if (
          bettingEventTicketState.data.status ===
            BettingEventTicketStatus.Cancelled ||
          bettingEventTicketState.data.status ===
            BettingEventTicketStatus.Winning
        ) {
          try {
            setLoading(true);
            const data = `{"MessageType": "PrinterMessage","Data": "${'{\\"printType\\":\\"CheckCash\\"}'}\"}`;
            if (!disableButton) {
              sendMessage(data).then((response: any) => {
                console.log(response);
              })
            } else {
              setLoading(false);
            }
          } catch (error: any) {
            // throw error;
            console.log(error);
          }
        }
        getDialog(bettingEventTicketState.data.status);
      }
    })();
  }, [disableButton]);

  return (
    <>
      <Dialog open={open}>
        {bettingEventTicketState.isLoading || loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <>
            <Close onClick={handleClose} className={classes.dialogClose} />
            <DialogTitle disableTypography className={classes.dialogTitle}>
              <Typography variant="h4">{dialogItems?.title}</Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {dialogItems?.icon}

              {dialogItems?.bidSum && (
                <Typography variant="h6" className={classes.bold}>
                  {t("returnSum")}
                  {bettingEventTicketState.data && (
                    <span
                      className={
                        bettingEventTicketState.data.status === 6
                          ? classes.sumWin
                          : classes.sumCancel
                      }
                    >
                      {`${dialogItems?.bidSum}€`}
                    </span>
                  )}
                </Typography>
              )}
              {dialogItems?.button}
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};

export default TicketCheckDialog;
