import { makeStyles } from "@material-ui/core/styles";

export const useAdminLogReportsStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    width: "100%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(3),
  },
}));
