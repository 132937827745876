import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import { addMinutes, isAfter, isBefore, isValid } from "date-fns";
import clsx from "clsx";
import {
  FormControl,
  Button,
  Card,
  TextField,
  Grid,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { CalendarTodayOutlined, Save, Clear } from "@material-ui/icons";
import FormDateWithHours from "../../../Shared/components/utils/FormDateWithHours";
import FormInput from "../../../Shared/components/utils/FormInput";
import { useNewEventFormStyles } from "./NewEventForm.styles";
import Loading from "../../../Shared/components/utils/Loading";
import { INewBettingEvent } from "../../types/BettingEvents";
import { IEventParticipant } from "../../constants/Interfaces";

interface IParticipantInput {
  index: number;
  name?: string;
  placeholder?: string;
  handleParticipants?: (input: string) => void;
  onRemove?: () => void;
  setNewParticipant?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

interface IEventCreateForm {
  onSubmit?: (values: INewBettingEvent) => void;
  onClose: () => void;
}

const ParticipantInput: React.FC<IParticipantInput> = ({
  index,
  name,
  placeholder,
  handleParticipants,
  onRemove,
  setNewParticipant,
  onFocus,
  onBlur,
}) => {
  const classes = useNewEventFormStyles();
  const [inputName, setInputName] = useState(name);

  const onNewParticipant = (name: string) => {
    handleParticipants?.(name);
    setNewParticipant?.("");
    setInputName("");
  };

  return (
    <Grid container alignItems="center" className={classes.participant}>
      <TextField
        value={inputName}
        style={{ width: "100%" }}
        onChange={(e: React.ChangeEvent<{ value: string }>) => {
          setNewParticipant?.(e.target.value);
          setInputName(e.target.value);
        }}
        onKeyPress={(e) => {
          e.key.toLowerCase() === "enter" && e.preventDefault();
        }}
        InputProps={{
          startAdornment: (
            <Typography style={{ marginRight: 8 }}>{index + 1}.</Typography>
          ),
          endAdornment: name && (
            <Clear className={classes.removeIcon} onClick={onRemove} />
          ),
        }}
        onFocus={() => onFocus?.()}
        onBlur={() => onBlur?.()}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key.toLowerCase() !== "enter") return;
          const target = event.target as HTMLInputElement;
          if (!target.value) return;
          onNewParticipant(target.value);
        }}
        placeholder={placeholder}
      />
    </Grid>
  );
};

const EventCreateForm: React.FC<IEventCreateForm> = ({ onSubmit, onClose }) => {
  const classes = useNewEventFormStyles();
  const { t } = useTranslation();
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [participants, setParticipants] = useState<IEventParticipant[]>([]);
  const [newParticipant, setNewParticipant] = useState("");
  const [scrollToBottom, setScrollToBottom] = useState<boolean>(false);
  const [enterButtonTooltip, setEnterButtonTooltip] = useState<boolean>(false);
  const participantsBottom = useRef<HTMLDivElement | null>(null);

  const onNewParticipant = (name: string) => {
    if (!name.length) return;

    setParticipants([
      ...participants,
      {
        name,
        number: participants.length + 1,
      },
    ]);

    setScrollToBottom(true);
    setEnterButtonTooltip(false);
  };

  useEffect(() => {
    if (!scrollToBottom) return;

    participantsBottom.current?.scrollIntoView({ behavior: "smooth" });
    setScrollToBottom(false);
  }, [scrollToBottom]);

  const submitForm = async (values: INewBettingEvent) => {
    if (!participants.length) {
      setError(t("enterAtLeast1Participant"));
      return;
    }
    setSubmitting(true);
    let sendParticipants: IEventParticipant[] = [...participants];
    if (newParticipant) {
      const newParticipantObject = {
        name: newParticipant,
        number: participants.length + 1,
      };
      sendParticipants = [...participants, newParticipantObject];
    }
    await onSubmit?.({
      ...values,
      participants: sendParticipants,
    });
    setSubmitting(false);
    onClose();
    return;
  };

  const trimSpaces = (input: string | number) => {
    return (input || "").toString().split(" ").join("");
  };

  return (
    <Form
      onSubmit={submitForm}
      validate={(values: INewBettingEvent) => {
        const errors: any = {};
        const integerRe = /^(\d+)$/; // positive integers only
        const currency = /^(\d+)(,\d{1,2})?$/; // values like 1,000

        if (!values.title) {
          errors.title = t("required");
        }
        if (!values.startsOn) {
          errors.startsOn = t("required");
        } else if (!isValid(new Date(values.startsOn))) {
          errors.startsOn = t("dateInvalid");
        } else if (isBefore(new Date(values.startsOn), new Date())) {
          errors.startsOn = t("eventCantStartInPast");
        }

        if (!values.bettingEndsOn) {
          errors.bettingEndsOn = t("required");
        } else if (!isValid(new Date(values.bettingEndsOn))) {
          errors.bettingEndsOn = t("dateInvalid");
        } else if (
          isAfter(new Date(values.bettingEndsOn), new Date(values.startsOn))
        ) {
          errors.bettingEndsOn = t("bettingDataHigher");
        } else if (
          isBefore(new Date(values.bettingEndsOn), new Date().getTime())
        ) {
          errors.bettingEndsOn = t("bettingEndsInPast");
        }

        if (!values.prizePoolPercentage) {
          errors.prizePoolPercentage = t("required");
        } else if (!values.prizePoolPercentage.toString().match(integerRe)) {
          errors.prizePoolPercentage = t("illegalFormat");
        } else if (
          values.prizePoolPercentage < 50 ||
          values.prizePoolPercentage > 100
        ) {
          errors.prizePoolPercentage = t("mustBeBetween50And100");
        }

        if (!values.ticketPrice) {
          errors.ticketPrice = t("required");
        } else if (!trimSpaces(values.ticketPrice).match(currency)) {
          errors.ticketPrice = t("illegalFormat");
        }

        if (!values.guaranteedMinimumPrizePool) {
          errors.guaranteedMinimumPrizePool = t("required");
        } else if (
          !trimSpaces(values.guaranteedMinimumPrizePool).match(currency)
        ) {
          errors.guaranteedMinimumPrizePool = t("illegalFormat");
        }
        return errors;
      }}
      mutators={{
        // expect (field, value) args from the mutator
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({
        handleSubmit,
        values,
        form: {
          mutators: { setValue },
        },
      }) => (
        <form onSubmit={handleSubmit}>
          {error && <div className={classes.error}>{error}</div>}
          <div className={classes.formContainer}>
            <FormInput
              name="title"
              id="title"
              className={clsx(classes.input, classes.fullWidth)}
              height="56px"
              label={t("title")}
            />
            <FormDateWithHours
              name="startsOn"
              id="startsOn"
              className={classes.input}
              onChange={(event) => {
                if (event) {
                  setValue("bettingEndsOn", addMinutes(new Date(event), -10));
                }
              }}
              height="56px"
              format="yyyy-MM-dd HH:mm:ss"
              label={t("eventDateAndTime")}
              icon={<CalendarTodayOutlined className={classes.calendarIcon} />}
              variant="dialog"
              inputVariant="outlined"
              DialogProps={{ className: classes.dateSelector }}
              minDate={new Date()}
            />
            <FormDateWithHours
              name="bettingEndsOn"
              id="bettingEndsOn"
              className={classes.input}
              height="56px"
              format="yyyy-MM-dd HH:mm:ss"
              label={t("betEnd")}
              icon={<CalendarTodayOutlined className={classes.calendarIcon} />}
              variant="dialog"
              inputVariant="outlined"
              DialogProps={{ className: classes.dateSelector }}
              maxDate={values.startsOn ? new Date(values.startsOn) : undefined}
              minDate={new Date()}
            />

            <FormInput
              name="prizePoolPercentage"
              id="prizePoolPercentage"
              className={classes.input}
              height="56px"
              label={t("winningFundPercentage")}
              placeholder={t("min50%")}
              InputLabelProps={{
                shrink: true,
              }}
              secondaryIcon={
                <Typography className={classes.green}>%</Typography>
              }
            />
            <FormInput
              name="ticketPrice"
              id="ticketPrice"
              className={classes.input}
              height="56px"
              label={t("fixedBidSum")}
            />
            <FormInput
              name="guaranteedMinimumPrizePool"
              id="guaranteedMinimumPrizePool"
              className={clsx(classes.input, classes.fullWidth)}
              height="56px"
              label={t("minWinningFund")}
              placeholder={t("minWinningFundFrom0")}
              secondaryIcon={
                <Typography className={classes.green}>€</Typography>
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Card
              elevation={0}
              className={clsx(classes.participantsCard, classes.fullWidth)}
            >
              {participants.map((p, idx) => (
                <ParticipantInput
                  index={idx}
                  key={idx}
                  name={p.name}
                  handleParticipants={(value: string) => {
                    setParticipants([
                      ...participants.slice(0, idx),
                      { name: value, number: participants.length + 1 },
                      ...participants.slice(idx + 1),
                    ]);
                  }}
                  onRemove={() => {
                    let newList = participants;
                    newList.splice(idx, 1);
                    setParticipants([...newList]);
                  }}
                />
              ))}
              <ParticipantInput
                index={participants.length}
                placeholder={t("participantName")}
                handleParticipants={onNewParticipant}
                setNewParticipant={setNewParticipant}
                onBlur={() => {
                  setEnterButtonTooltip(false);
                }}
                onFocus={() => {
                  if (participants.length === 0) {
                    setEnterButtonTooltip(true);
                  }
                }}
              />
              {/* This is used to make scroll work */}
              <Tooltip
                disableHoverListener
                placement="bottom"
                open={enterButtonTooltip}
                arrow
                title={`${t("pressEnterToAddNewParticipant")}`}
              >
                <div className={classes.newParticipantTooltip} />
              </Tooltip>
              <div ref={participantsBottom} />
            </Card>

            <FormControl className={classes.fullWidth}>
              <Button
                name="create"
                color="secondary"
                className={classes.button}
                size="large"
                variant="contained"
                type="submit"
                disableElevation
                disabled={submitting}
                startIcon={<Save />}
                onClick={() => handleSubmit}
              >
                {submitting ? (
                  <Loading width={20} circleColor="white" />
                ) : (
                  <Typography
                    variant="caption"
                    style={{
                      paddingTop: 5,
                    }}
                  >
                    {t("save")}
                  </Typography>
                )}
              </Button>
            </FormControl>
          </div>
        </form>
      )}
    />
  );
};
export default EventCreateForm;
