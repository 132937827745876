import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CallbackComponent } from "redux-oidc";
import userManager from "../helpers/userManagement";
import Loading from "../../Shared/components/utils/Loading";
import { useCallBackComponentStyles } from "./CallBackComponent.styles";
import { Typography } from "@material-ui/core";
import { APPLICATION_ITEM } from "../../Shared/constants/Storage";
import { ROUTES } from "../constants/Routes";

const CallbackPage: React.FC = () => {
  const history = useHistory();
  const classes = useCallBackComponentStyles();
  const { t } = useTranslation();

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={(user) => {
        const lastRoute = localStorage.getItem("lastRoute") || "/";
        const app = localStorage.getItem(APPLICATION_ITEM)
        if (app) {
          if (lastRoute) {
            history.push(lastRoute)
          } else {
            history.push(user.profile.role === "Admin" ? '/admin' : '/');
          }
          return;
        }
        history.push(ROUTES.APP_SELECT);
      }}
      errorCallback={(error) => {
        console.error(error);
      }}
    >
      <div className={classes.main}>
        <Loading width={60} />
        <Typography variant="h2" align="center" className={classes.text}>
          {t("redirecting")}
        </Typography>
      </div>
    </CallbackComponent>
  );
};

export default connect()(CallbackPage);
