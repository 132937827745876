import {
  IApiStoreEntity,
  TStoresActions,
  EditCurrentStore,
  StoresGet,
  AvailableStoresGet,
  StoreGetPlayers,
  IApiStorePlayersEntity,
  UpdateStorePlayerTransaction,
  AddPlayerToStore,
} from "../../Shared/types/Stores";
import { apiCall } from "../../Shared/actions/BaseAction";
import { API_END_POINTS } from "../constants/Api";
import { STORE_ITEM } from "../../Shared/constants/Storage";
import { PAGE_LIMIT } from "../../Shared/constants/Table";
import { IApiTransactionEntity } from "../../Shared/types/Transactions";

export const getStoresList = () => {
  return apiCall<TStoresActions, null, Array<IApiStoreEntity>>(
    StoresGet,
    "GET",
    API_END_POINTS.STORES,
    true
  );
};

export const getAvailableStores = () => {
  return apiCall<TStoresActions, null, Array<IApiStoreEntity>>(
    AvailableStoresGet,
    "GET",
    API_END_POINTS.AVAILABLE_STORES,
    true
  );
};

export const getStorePlayersList = (
  storeId: number,
  page: number,
  searchQuery?: string,
  minDate?: string,
  maxDate?: string,
  currentShiftOnly?: boolean
) => {
  const limit = PAGE_LIMIT;
  return apiCall<TStoresActions, null, Array<IApiStorePlayersEntity>>(
    StoreGetPlayers,
    "GET",
    API_END_POINTS.STORE_PLAYERS,
    true,
    null,
    {},
    {
      Limit: limit,
      Term: searchQuery,
      MinDate: minDate,
      MaxDate: maxDate,
      PageNumber: page,
      CurrentShiftOnly: currentShiftOnly,
      StoreId: storeId,
    }
  );
};

export const editCurrentStore = (store: IApiStoreEntity) => {
  localStorage.setItem(STORE_ITEM, JSON.stringify(store));

  return {
    type: EditCurrentStore.EDIT,
    payload: store,
  };
};

export const addPlayerToStore = (player: Partial<IApiStorePlayersEntity>) => {
  return {
    type: AddPlayerToStore.ADD_PLAYER,
    payload: player,
  };
};

export const updateStorePlayersTransaction = (
  transaction: IApiTransactionEntity
) => {
  return {
    type: UpdateStorePlayerTransaction.UPDATE_TRANSACTION,
    payload: transaction,
  };
};
