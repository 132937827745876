export enum AdminEventsTableKeys {
  title = "title",
  status = "status",
  startsOn = "startsOn",
  bettingEndsOn = "bettingEndsOn",
  soldTicketAmount = "soldTicketAmount",
  totalBetsAmount = "totalBetsAmount",
  totalPaidOutAmount = "totalPaidOutAmount",
  totalWinningsAmount = "totalWinningsAmount",
  winningTicketAmount = "winningTicketAmount",
  cancelledTicketAmount = "cancelledTicketAmount",
  eventEndedOn = "eventEndedOn",
}

export enum AdminTicketActionsTableKeys {
  UserName = "userName",
  ActionType = "actionType",
  BettingEventName = "bettingEventName",
  ActionDate = "actionDate",
  BettingEventTicketId = "bettingEventTicketId"
}

export enum CashierEventsTableKeys {
  title = "title",
  status = "status",
  startsOn = "startsOn",
  bettingEndsOn = "bettingEndsOn",
  ticketPrice = "ticketPrice",
}

export enum AdminEventStatus {
  Active = 1,
  Pending = 2,
  Ended = 3,
  Cancelled = 4,
  Closed = 5,
}

export interface IEventParticipant {
  number: number;
  name: string;
}
