import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  withStyles,
  CheckboxProps,
  Checkbox,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { ImportExport, CalendarTodayOutlined } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { IAppState } from "../../../../../Shared/store/Store";
import { TStoreState } from "../../../../../Shared/types/Stores";
import { useAdminTableFiltersStyles } from "./AdminTableFilters.styles";
import Toast from "../../../../../Shared/components/utils/Toast";
import {
  AdminFilterStorage,
  IAdminMoneyFilter,
} from "../../../../constants/Interfaces";
import { IAdminDateFilter } from "Shared/constants/Table";
import { ROUTES } from "../../../../constants/Routes";
import {
  exportAllTransactions,
  exportPlayerTransactions,
} from "../../../../actions/TransactionsActions";
import { downloadFileFromBlob } from "../../../../../Shared/helpers/Utils";
import { format } from "date-fns";
import SearchField from "../../../../../Shared/components/utils/SearchField";
import { validateAndReturnFilterDate } from "Shared/helpers/Date";

const GreenCheckbox = withStyles({
  root: {
    color: "#0AAF60",
    "&$checked": {
      color: "#0AAF60",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const StyledMenuItem = withStyles({
  root: {
    fontSize: 12,
  },
})(MenuItem);

interface IAdminTableFilters {
  storeFilter: number;
  setStoreFilter: (store: number) => void;
  dateFilter?: Partial<IAdminDateFilter>;
  setDateFilter: (value: Partial<IAdminDateFilter>) => void;
  moneyFilter: IAdminMoneyFilter;
  setMoneyFilter: (money: IAdminMoneyFilter) => void;
  searchQuery?: string;
  setSearchQuery?: (query: string) => void;
}

const AdminTableFilters: React.FC<IAdminTableFilters> = ({
  storeFilter,
  dateFilter,
  moneyFilter,
  setStoreFilter,
  setDateFilter,
  setMoneyFilter,
  searchQuery,
  setSearchQuery,
}) => {
  const { t } = useTranslation();
  const classes = useAdminTableFiltersStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();
  const [searchInput, setSearchInput] = useState<string>(searchQuery || "");
  const player: any = useRouteMatch(ROUTES.ADMIN.PLAYER);

  // Redux
  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((input: string) => setSearchQuery?.(input), 300),
    []
  );

  const handleChange = async (e: React.ChangeEvent<{ value: string }>) => {
    setSearchInput(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleSiteSelect = async (e: React.ChangeEvent<{ value: unknown }>) => {
    const site = e.target.value as number;
    setStoreFilter(site);
    localStorage.setItem(AdminFilterStorage.Site, site.toString());
  };

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  const exportCSV = async () => {
    // Exports current table to CSV file

    let fileResponse: any;

    if (player?.params) {
      // Viewing single player
      const { id } = player?.params;
      if (!id) return;

      fileResponse = await dispatch(exportPlayerTransactions(id));
    } else {
      // Viewing all players
      fileResponse = await dispatch(
        exportAllTransactions({
          ...((moneyFilter["15k"] || moneyFilter["1k"]) && {
            MinSumPerShift: moneyFilter["1k"] ? 1000 : 15000,
          }),
          ...(storeFilter && { StoreId: storeFilter }),
          MinDate: dateFilter?.from
            ? format(new Date(dateFilter.from), "yyyy-MM-dd'T'HH:mm:ss")
            : undefined,
          MaxDate: dateFilter?.to
            ? format(new Date(dateFilter.to), "yyyy-MM-dd'T'HH:mm:ss")
            : undefined,
        })
      );
    }

    if (fileResponse?.data) {
      const filename =
        fileResponse.config?.headers?.["Content-Disposition"].split(
          "attachment;filename="
        )[1] || `${format(new Date(), "yyyy-MM-dd-HH_mm_ss")}.csv`;

      downloadFileFromBlob("text/csv", fileResponse.data, filename);
    }
  };

  const clearFilters = async () => {
    // Clears all inputs and resets filters;
    const today = new Date();
    setMoneyFilter({ "1k": false, "15k": false });
    setStoreFilter(0);
    setDateFilter({
      from: new Date(
        new Date(today.getFullYear(), today.getMonth(), 1).setHours(10, 0, 0)
      ),
      to: "",
    });
    setSearchQuery?.("");

    localStorage.removeItem(AdminFilterStorage.Money);
    localStorage.removeItem(AdminFilterStorage.Date);
    localStorage.removeItem(AdminFilterStorage.Site);
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Handling bidSum money checkboxes.
    // Calculates filter value.
    setMoneyFilter({
      ...moneyFilter,
      [event.target.name]: event.target.checked,
    });
    localStorage.setItem(
      AdminFilterStorage.Money,
      JSON.stringify({
        ...moneyFilter,
        [event.target.name]: event.target.checked,
      })
    );
  };

  const handleDateInputChange = async (name: string, value: Date) => {
    // Handles date input. Sets from/to filters based on the new input

    const date = validateAndReturnFilterDate(name, value, dateFilter);
    if (value === null) {
      localStorage.setItem(
        AdminFilterStorage.Date,
        JSON.stringify({ ...dateFilter, [name]: null })
      );
    }
    if (date) {
        setDateFilter(date);
        localStorage.setItem(AdminFilterStorage.Date, JSON.stringify(date));
    } 

  };

  const handleInputClear = () => {
    setSearchInput("");
    setSearchQuery?.("");
  };

  return (
    <>
      <Grid alignItems="center" container>
        <FormControl
          variant="outlined"
          size="small"
          className={classes.selectionForm}
        >
          <InputLabel className={classes.selectionLabel}>
            {t("gamblingSite")}...
          </InputLabel>
          <Select
            id="site"
            label={t("gamblingSite")}
            value={storeFilter}
            onChange={handleSiteSelect}
            inputProps={{
              classes: {
                icon: classes.arrowIcon,
              },
            }}
            MenuProps={{
              className: classes.selection,
            }}
          >
            <StyledMenuItem value="">-</StyledMenuItem>
            {storesState.data?.map(
              (store) =>
                store.name && (
                  <StyledMenuItem value={store.id} key={store.id}>
                    {store.name}
                  </StyledMenuItem>
                )
            )}
          </Select>
        </FormControl>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            placeholder="yyyy-mm-dd"
            helperText=""
            inputVariant="outlined"
            InputProps={{
              className: classes.dateBox,
            }}
            size="small"
            keyboardIcon={
              <CalendarTodayOutlined className={classes.iconPrimary} />
            }
            value={dateFilter?.from ? dateFilter.from : null}
            onChange={(date: any) => handleDateInputChange("from", date)}
            maxDate={dateFilter?.to ? dateFilter.to : new Date()}
          />
        </MuiPickersUtilsProvider>
        <Typography style={{ alignSelf: "center" }} variant="body2">
          iki
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            placeholder="yyyy-mm-dd"
            helperText=""
            inputVariant="outlined"
            InputProps={{
              className: classes.dateBox,
            }}
            size="small"
            keyboardIcon={
              <CalendarTodayOutlined className={classes.iconPrimary} />
            }
            value={dateFilter?.to ? dateFilter.to : null}
            onChange={(date: any) => handleDateInputChange("to", date)}
            minDate={dateFilter?.from ? dateFilter.from : null}
            maxDate={new Date().setDate(new Date().getDate() + 1)}
          />
        </MuiPickersUtilsProvider>

        <Button
          color="primary"
          className={classes.clearFilters}
          size="small"
          variant="outlined"
          onClick={clearFilters}
          disableElevation
        >
          {t("clearFilters")}
        </Button>

        <Button
          color="primary"
          className={classes.exportCSV}
          size="large"
          variant="outlined"
          onClick={exportCSV}
          disableElevation
          startIcon={<ImportExport />}
        >
          {t("export")}
        </Button>
      </Grid>
      <Grid container>
        {!player && (
          <SearchField
            handleChange={handleChange}
            handleClear={handleInputClear}
            value={searchInput}
            inputClass={classes.tableSearchSimple}
          />
        )}

        <FormControlLabel
          control={
            <GreenCheckbox
              checked={moneyFilter["1k"]}
              onChange={handleCheckBoxChange}
              name="1k"
            />
          }
          classes={{ label: classes.checkboxLabel }}
          label={t("daySumAbove1000")}
        />
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={moneyFilter["15k"]}
              onChange={handleCheckBoxChange}
              name="15k"
            />
          }
          classes={{ label: classes.checkboxLabel }}
          label={t("daySumAbove15000")}
        />
      </Grid>
    </>
  );
};
export default AdminTableFilters;
