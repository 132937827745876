import { ThemeOptions } from "@material-ui/core/styles";

export const gamingTheme: ThemeOptions = {
  palette: {
    primary: {
      main: "#0AAF60",
    },
    secondary: {
      main: "#000",
    },
    error: {
      main: "#FF4D4D",
    }
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: "5px 0",
        "&.MuiTableCell-body": {
          color: "black",
        },
      },
    },
    MuiTableHead: {
      root: {
        "& .MuiTableCell-head": {
          padding: 0,
          backgroundColor: "white",
          fontWeight: "bold",
          borderBottom: "2px solid black",
          color: "black",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 5,
        color: "rgba(0, 0, 0, 0.8)",
        "&:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0.34)",
        },
        "&:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0.34)",
        },
        "&:not(.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#0AAF60 !important",
        },
      },
    },
    MuiDialog: {
      root: {
        "& .MuiPaper-rounded": {
          borderRadius: 10,
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "white",
        color: "black",
      },
    },
    MuiInputLabel: {
      root: {
        color: "rgba(0, 0, 0, 0.8)",
      },
    },
    MuiTablePagination: {
      root: {
        color: '#0C0C0C !important'
      }
    }
  },
};
