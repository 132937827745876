import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Box,
} from "@material-ui/core";
import { ExitToApp, Autorenew } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useCashierTabStyles } from "./UserTab.styles";
import { ReactComponent as ProfilePicture } from "../assets/icons/profile_picture.svg";
import { ROUTES } from "../constants/Routes";
import { IAppState } from "../store/Store";
import { TStoreState } from "../types/Stores";
import {
  getAvailableStores,
  editCurrentStore,
} from "../../Gaming/actions/StoresActions";
import { setCurrentApp } from "../../Shared/actions/AppActions";
import Loading from "./utils/Loading";
import { IApiStoreEntity } from "../types/Stores";
import { APPLICATIONS } from "../constants/Applications";
import { removeStorageItems, APPLICATION_ITEM } from "../constants/Storage";
import Toast from "../components/utils/Toast";
import userManager from "../helpers/userManagement";
import { handleError } from "../utils/ErrorHandler";
import { RoleDescription } from "../constants/Roles";

interface IUserTab {
  hideSites?: boolean;
}

const UserTab: React.FC<IUserTab> = ({ hideSites }) => {
  const classes = useCashierTabStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [activeApp, setActiveApp] = useState(
    localStorage.getItem(APPLICATION_ITEM)
  );

  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );
  const oidc: any = useSelector<IAppState, any>(
    (state: IAppState) => state.oidc
  );

  useEffect(() => {
    (async () => {
      if (!storesState.data) {
        try {
          await dispatch(getAvailableStores());
        } catch (err: any) {
          handleError(err?.status, setError);
        }
      }
    })();
  }, [dispatch, storesState.data]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  const switchApp = async () => {
    if (activeApp) {
      const app =
        activeApp === APPLICATIONS.ToTo
          ? APPLICATIONS.PerlasGaming
          : APPLICATIONS.ToTo;
      dispatch(setCurrentApp(app));
      localStorage.setItem(APPLICATION_ITEM, app);
      setActiveApp(app);
      history.push(
        oidc.user.profile.role === RoleDescription.Admin
          ? ROUTES.ADMIN.MAIN
          : ROUTES.MAIN_PAGE
      );
    } else {
      history.push(ROUTES.APP_SELECT);
    }
  };

  const handleLogout = () => {
    removeStorageItems();
    userManager.signoutRedirect();
  };

  return (
    <Paper elevation={3} className={classes.container}>
      <Box className={classes.changeApp}>
        <Typography variant="body2">
          {activeApp === APPLICATIONS.ToTo ? "ToTo" : "ID Check"}
        </Typography>
        <Typography
          variant="caption"
          onClick={switchApp}
          className={classes.changeSite}
        >
          <Autorenew className={classes.changeSiteIcon} />
          {t("change")}
        </Typography>
      </Box>

      <RouterLink
        className={classes.exitButton}
        to={ROUTES.APP_SELECT}
        onClick={() => handleLogout()}
      >
        <ExitToApp />
      </RouterLink>

      <ProfilePicture className={classes.profileName} />
      <Typography variant="h6" align="center" className={classes.name}>{`${
        oidc.user.profile?.firstname || ""
      } ${oidc.user.profile?.lastname || ""}`}</Typography>
      {storesState.isLoading ? (
        <Grid
          container
          alignContent="center"
          justify="center"
          style={{ height: "30px" }}
        >
          <Loading width={15} circleColor="#FFFFFF" />
        </Grid>
      ) : (
        <>
          {!hideSites && (
            <div className={classes.flex}>
              <Typography variant="body2">{`${t(
                "gamblingSite"
              )}: `}</Typography>
              <FormControl
                variant="outlined"
                size="small"
                className={classes.selectionForm}
              >
                <Select
                  id="site"
                  value={storesState.selectedStore?.name}
                  className={classes.select}
                  MenuProps={{
                    className: classes.selection,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const site = event.target.value as string;
                    let currentStore;
                    if (site) {
                      currentStore = storesState.data?.filter(
                        (store) => store.name === site
                      );
                    }

                    if (currentStore || !site) {
                      let currentStoreObject: IApiStoreEntity;
                      if (currentStore) {
                        currentStoreObject = {
                          address: currentStore?.[0]?.address,
                          id: currentStore[0].id,
                          name: currentStore[0].name,
                        };
                      } else {
                        currentStoreObject = {
                          address: "",
                          id: undefined,
                          name: "",
                        };
                      }
                      dispatch(editCurrentStore(currentStoreObject));
                    }
                  }}
                >
                  <MenuItem value="">-</MenuItem>
                  {storesState.data?.map((store) => (
                    <MenuItem value={store.name || ""} key={store.id}>
                      {store.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </>
      )}
    </Paper>
  );
};

export default UserTab;
