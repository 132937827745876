import React from 'react';
import { Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import usePasswordStrengthHelpStyle from './PasswordStrengthHelp.styles';

const PasswordRequirements = ({ requirement, text }) => {
  const classes = usePasswordStrengthHelpStyle();
  return (
    <div className={classes.requirementItem}>
      <Check
        className={requirement ? classes.greenCheckBox : classes.checkBox}
      />
      <Typography
        variant="body2"
        className={requirement ? classes.greenRequirement : classes.requirement}
      >
        {text}
      </Typography>
    </div>
  );
};

export default PasswordRequirements;
