import { makeStyles } from "@material-ui/core/styles";

export const usePlayerTabStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
    backgroundColor: "white",
    position: "relative",
    color: theme.palette.secondary.main,
    borderRadius: 10,
    flex: 1,
  },

  inputs: {
    marginTop: theme.spacing(4),
    width: "100%",
  },
}));
