import React, {
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import { debounce } from "lodash";
import { Grid, Button, Typography } from "@material-ui/core";
import { CalendarTodayOutlined } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useAdminTableFiltersStyles } from "./AdminTableFilters.styles";
import { IAdminDateFilter } from "Shared/constants/Table";
import SearchField from "../../../../../Shared/components/utils/SearchField";
import { validateAndReturnFilterDate } from "Shared/helpers/Date";

interface IAdminTableFilterSimple {
  dateFilter?: Partial<IAdminDateFilter>;
  setDateFilter: Dispatch<
    SetStateAction<Partial<IAdminDateFilter> | undefined>
  >;
  setSearchTerm: Dispatch<SetStateAction<string | undefined>>;
  fullRange?: boolean;
}

const AdminTableFilterSimple = ({
  dateFilter,
  setDateFilter,
  setSearchTerm,
  fullRange,
}: IAdminTableFilterSimple) => {
  const classes = useAdminTableFiltersStyles();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>();

  const handleDateInputChange = async (name: string, value: Date) => {
    const date = validateAndReturnFilterDate(name, value, dateFilter, fullRange);
    console.log(date);
    if (date) {
        setDateFilter(date);
    } 
  };

  const clearFilters = async () => {
    // Clears all inputs and resets filters;
    setDateFilter(undefined);
    setSearchValue(undefined);
    setSearchTerm(undefined);
  };

  const handleChange = async (e: React.ChangeEvent<{ value: string }>) => {
    setSearchValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const debouncedSearch = useCallback(
    debounce((input: string) => setSearchTerm?.(input), 300),
    []
  );

  return (
    <>
      <Grid alignItems="center" container>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            placeholder="yyyy-mm-dd"
            helperText=""
            inputVariant="outlined"
            InputProps={{
              className: classes.dateBox,
            }}
            size="small"
            keyboardIcon={
              <CalendarTodayOutlined className={classes.iconPrimary} />
            }
            value={dateFilter?.from ? dateFilter.from : null}
            onChange={(date: any) => handleDateInputChange("from", date)}
            maxDate={dateFilter?.to ? dateFilter.to : new Date()}
          />
        </MuiPickersUtilsProvider>
        <Typography style={{ alignSelf: "center" }} variant="body2">
          {t('till')}
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            placeholder="yyyy-mm-dd"
            helperText=""
            inputVariant="outlined"
            InputProps={{
              className: classes.dateBox,
            }}
            size="small"
            keyboardIcon={
              <CalendarTodayOutlined className={classes.iconPrimary} />
            }
            value={dateFilter?.to ? dateFilter.to : null}
            onChange={(date: any) => handleDateInputChange("to", date)}
            minDate={dateFilter?.from ? dateFilter.from : null}
            maxDate={new Date().setDate(new Date().getDate() + 1)}
          />
        </MuiPickersUtilsProvider>

        <Button
          color="primary"
          className={classes.clearFilters}
          size="small"
          variant="outlined"
          onClick={clearFilters}
          disableElevation
        >
          {t("clearFilters")}
        </Button>
      </Grid>
      <Grid container>
        <SearchField
          handleChange={handleChange}
          handleClear={() => {
            setSearchTerm(undefined);
            setSearchValue(undefined);
          }}
          value={searchValue ?? ""}
          inputClass={classes.tableSearchSimple}
        />
      </Grid>
    </>
  );
};

export default AdminTableFilterSimple;
