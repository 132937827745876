import React from "react";
import { Switch } from "react-router-dom";
import CashierEvents from "../containers/cashier/CashierEvents";
import CashierReports from "../containers/cashier/CashierReports";
import { ROUTES } from "../constants/Routes";
import PrivateRoute from "../../Shared/router/PrivateRoute";
import { RoleDescription } from "../../Shared/constants/Roles";

const CashierRouter: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={[ROUTES.CASHIER.EVENTS, ROUTES.CASHIER.MAIN]}
        component={CashierEvents}
        roles={[RoleDescription.StoreUser]}
      />
      <PrivateRoute
        exact
        path={ROUTES.CASHIER.REPORTS}
        component={CashierReports}
        roles={[RoleDescription.StoreUser]}
      />
    </Switch>
  );
};

export default CashierRouter;
