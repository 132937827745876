import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import { Close, ReportProblemOutlined } from "@material-ui/icons";

import { usePlayerCommentModalStyles } from "./PlayerCommentModal.styles";

interface IPlayerCommentModal {
  commentAboutPlayer?: string;
  commentDialog: boolean;
  handleClose: () => void;
  handleOnExited: () => void;
}

const PlayerCommentModal: React.FC<IPlayerCommentModal> = ({
  commentDialog,
  commentAboutPlayer,
  handleClose,
  handleOnExited,
}) => {
  const classes = usePlayerCommentModalStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={commentDialog} onClose={handleClose} onExited={handleOnExited}>
      <Close className={classes.dialogClose} onClick={handleClose} />
      <DialogTitle disableTypography>
        <Typography align="center" variant="h4">
          {t("commentAboutPlayer")}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ReportProblemOutlined className={classes.warningIcon} />
        <Typography align="center">{commentAboutPlayer}</Typography>
        <Box
          display="flex"
          alignItems="center"
          gridGap={8}
          justifyContent="space-between"
          mt={2}
        >
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            style={{ color: "#fff" }}
            onClick={handleClose}
          >
            {t("good")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PlayerCommentModal;
