import "date-fns";
import React, { ReactElement } from "react";
import { Field } from "react-final-form";
import format from "date-fns/format";
import ltLocale from "date-fns/locale/lt";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { FormControl, FormHelperText } from "@material-ui/core";
import { useFormInputStyles } from "./FormInput.styles";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface IFormDate {
  name: string;
  id: string;
  icon?: ReactElement;
  secondaryIcon?: ReactElement;
  placeholder?: string;
  format?: string;
  variant?: "inline" | "static" | "dialog" | undefined;
  height?: string;
  className?: string;
  label?: string;
  inputVariant?: "outlined" | "filled" | "standard";
  setError?: (value: boolean) => void;
  InputProps?: object;
  minDate?: Date;
  maxDate?: Date;
  initialValue?: any;
  onChange?: (event: MaterialUiPickersDate) => void;
  DialogProps?: any;
}

class LtLocalizedUtil extends DateFnsUtils {
  getCalendarHeaderText(date: any) {
    return format(date, "yyyy-MM-dd", { locale: ltLocale });
  }

  getDatePickerHeaderText(date: any) {
    return format(date, "yyyy-MM-dd", { locale: ltLocale });
  }
}

export const FormDate: React.FC<IFormDate> = ({
  name,
  id,
  icon,
  placeholder,
  format,
  variant,
  height,
  className,
  label,
  setError,
  inputVariant,
  InputProps,
  maxDate,
  minDate,
  initialValue,
  DialogProps,
  onChange,
}) => {
  const classes = useFormInputStyles();
  return (
    <Field name={name} initialValue={initialValue}>
      {({ input, meta }) => (
        <FormControl
          className={className}
          fullWidth
          variant="outlined"
          onBlur={() => {
            if (meta.error && setError) {
              setError(true);
            }
          }}
        >
          <MuiPickersUtilsProvider utils={LtLocalizedUtil} locale={ltLocale}>
            <KeyboardDatePicker
              id={id}
              variant={variant}
              format={format}
              error={
                ((meta.submitError && !meta.dirtySinceLastSubmit) ||
                  meta.error) &&
                meta.touched &&
                !meta.active
              }
              placeholder={placeholder}
              autoComplete="off"
              label={label}
              style={{ height }}
              helperText=""
              inputVariant={inputVariant}
              InputProps={{ ...InputProps }}
              {...input}
              onChange={(event) => {
                input.onChange(event);
                onChange?.(event);
              }}
              value={input.value ? input.value : null}
              keyboardIcon={icon}
              minDate={minDate}
              maxDate={maxDate}
              DialogProps={{ ...DialogProps, className: classes.datePicker }}
            />
          </MuiPickersUtilsProvider>
          {(meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
            meta.touched && (
              <FormHelperText className={classes.error}>
                {meta.error || meta.submitError}
              </FormHelperText>
            )}
        </FormControl>
      )}
    </Field>
  );
};

export default FormDate;
