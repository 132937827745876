import { makeStyles } from "@material-ui/core/styles";

export const useConfirmIdDialogStyles = makeStyles((theme) => ({
  dialogTitle: {
    marginTop: theme.spacing(1.5),
  },
  dialogContent: {
    minWidth: 430,
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  dialogClose: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: "#CECECE",
    fontSize: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  button: {
    marginTop: theme.spacing(1.5),
    minHeight: 50,
    "&.MuiButton-containedPrimary ": {
      color: "white",
    },
    "&.MuiButton-label": {
      display: "flex",
      alignItems: "end",
      fontSize: 14,
    },
  },
  buttonWarning: {
    marginTop: theme.spacing(1.5),
    backgroundColor: theme.palette.error.main,
    minHeight: 50,
    color: "white",
    "&.MuiButton-label": {
      display: "flex",
      alignItems: "end",
      fontSize: 14,
    },
  },
}));
