import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useTable,
  Column,
  TableInstance,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  TableState,
} from "react-table";
import clsx from "clsx";
import {
  TableContainer as MaterialTableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  Input,
  InputAdornment,
  TablePagination,
} from "@material-ui/core";
import {
  Search,
  HourglassEmpty,
  TrendingUp,
  CheckCircleOutline,
  Block,
  AssignmentTurnedIn,
} from "@material-ui/icons";
import {
  AdminEventStatus,
  AdminEventsTableKeys,
} from "../constants/Interfaces";
import { useEventsTableStyles } from "./EventsTable.styles";
import { IBettingEvent } from "../types/BettingEvents";
import { default as CustomTablePagination } from "Shared/components/TablePagination";

type Data = object;

interface IEventsTable {
  columns: Column<Data>[];
  events: Array<IBettingEvent>;
  initialTableState?: Partial<TableState<object>>;
  onSearchTermUpdate?: (value: string) => void;
  useCustomSearch?: boolean;
  useCustomPagination?: boolean;
  // only if useCustomPagination is true
  changePage?: (event: React.ChangeEvent<unknown>, newPage: number) => void;
  activePage?: number;
  pages?: number[];
}

const EventsTable: React.FC<IEventsTable> = ({
  columns,
  events: data,
  initialTableState,
  onSearchTermUpdate,
  changePage,
  activePage,
  pages,
  useCustomPagination,
  useCustomSearch,
}) => {
  const classes = useEventsTableStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    gotoPage,
    setPageSize,
    rows,
    state: { pageIndex, pageSize },
  } = useTable<Data>(
    {
      columns,
      data,
      initialState: {
        ...(!useCustomPagination && { pageIndex: 0, pageSize: 15 }),
        ...initialTableState,
      },
    },
    useGlobalFilter,
    usePagination
  ) as TableInstance<object>;

  const onChange = useAsyncDebounce((inputValue) => {
    if (useCustomSearch) {
      onSearchTermUpdate?.(inputValue);
    } else {
      setGlobalFilter(inputValue);
    }
  }, 200);

  const getEventStatusIcon = (row: any) => {
    switch (row.status) {
      case AdminEventStatus.Pending:
        return (
          <HourglassEmpty className={clsx(classes.icon, classes.iconWaiting)} />
        );
      case AdminEventStatus.Active:
        return (
          <TrendingUp className={clsx(classes.icon, classes.iconActive)} />
        );
      case AdminEventStatus.Ended:
        return (
          <CheckCircleOutline
            className={clsx(classes.icon, classes.iconFinished)}
          />
        );
      case AdminEventStatus.Cancelled:
        return <Block className={clsx(classes.icon, classes.iconCanceled)} />;
      case AdminEventStatus.Closed:
        return (
          <AssignmentTurnedIn
            className={clsx(classes.icon, classes.iconFinished)}
          />
        );
    }
  };

  return (
    <>
      <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        className={classes.tableSearchSimple}
        placeholder={t("search")}
        startAdornment={
          <InputAdornment position="start">
            <Search className={classes.green} />
          </InputAdornment>
        }
      />
      <MaterialTableContainer>
        <Table {...getTableProps()} stickyHeader className={classes.table}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    className={clsx(
                      column.id === AdminEventsTableKeys.bettingEndsOn &&
                        classes.tableCellHeaderLarge
                    )}
                  >
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} className={classes.row}>
                  {row.cells.map((cell: any) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        <div
                          className={clsx(
                            cell.column.id === "warning"
                              ? classes.nameContainerAligmentEnd
                              : classes.nameContainer
                          )}
                        >
                          {cell.column.id === AdminEventsTableKeys.status &&
                            getEventStatusIcon(row.original)}
                          <span className={classes.nameText}>
                            {cell.render("Cell")}
                          </span>
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </MaterialTableContainer>

      {useCustomPagination ? (
        <CustomTablePagination
          activePage={activePage || 0}
          pages={pages}
          changePage={(e, p) => changePage?.(e, p)}
        />
      ) : (
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          count={data.length}
          page={pageIndex}
          onPageChange={(
            _event: React.MouseEvent<HTMLButtonElement> | null,
            newPage: number
          ) => {
            gotoPage(newPage);
          }}
          rowsPerPage={pageSize}
          onChangeRowsPerPage={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => setPageSize(Number(event.target.value))}
        />
      )}
    </>
  );
};

export default EventsTable;
