import { makeStyles } from "@material-ui/core/styles";

export const useEventCreateModalStyles = makeStyles((theme) => ({
  dialogTitle: {
    paddingTop: 0,
    textAlign: "center",
  },
  dialogContent: {
    minWidth: 430,
    paddingBottom: theme.spacing(2),
    borderRadius: 10,
  },
  dialogClose: {
    marginRight: theme.spacing(2),
    marginLeft: "auto",
    marginTop: theme.spacing(2),
    color: "#0C0C0C",
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
