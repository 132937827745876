import React from "react";
import { useTranslation } from "react-i18next";
import { Input, InputAdornment, IconButton } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { useSearchFieldStyles } from "./SearchField.styles";

interface ISearchField {
  handleChange: (e: React.ChangeEvent<{ value: string }>) => void;
  handleClear: () => void;
  value: string;
  inputClass?: string;
}

const SearchField: React.FC<ISearchField> = ({ handleChange, handleClear, value, inputClass }) => {
  const classes = useSearchFieldStyles();
  const { t } = useTranslation();

  return (
    <Input
      onChange={handleChange}
      value={value}
      className={inputClass ? inputClass : classes.tableSearchSimple}
      placeholder={t("search")}
      startAdornment={
        <InputAdornment position="start">
          <Search className={classes.iconPrimary} />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="clear search"
            onClick={handleClear}
            color="primary"
            size="small"
          >
            <Close />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default SearchField;
