import React from "react";
import {
  useTable,
  Column,
  TableInstance,
  useFilters,
} from "react-table";
import clsx from "clsx";
import {
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  Box,
} from "@material-ui/core";
import { Block, EditOutlined, HelpOutline } from "@material-ui/icons";
import {
  PlayersTableKeys,
  IPlayerTransactionsTableRow,
} from "../../../constants/Interfaces";
import { PlayerDataStatus } from "../../../../Shared/types/Players";
import { usePlayerTableStyles } from "./PlayersTable.styles";
import TablePagination from "../../../../Shared/components/TablePagination";
import Loading from "../../../../Shared/components/utils/Loading";
import { checkIfPlayerIsBlocked, checkIfPlayerIsInconclusive } from "Shared/helpers/Players";

type Data = object;
interface IPlayerTable {
  columns: Column<Data>[];
  data: Partial<IPlayerTransactionsTableRow>[] | undefined;
  activePage: number;
  changePage: (event: React.ChangeEvent<unknown>, newPage: number) => void;
  pages?: number[];
  isLoading?: boolean;
}

const PlayerTable: React.FC<IPlayerTable> = ({
  columns,
  data,
  activePage,
  pages,
  changePage,
  isLoading,
}) => {
  const classes = usePlayerTableStyles();
  // Table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<Data>(
    {
      columns,
      data: data ? data : [],
    },
    useFilters,
  ) as TableInstance<object>;

  const getRowClassName = (row: any) => {
    if (
      (row.original[PlayersTableKeys.totalShiftInAmountSum] >= 1000 &&
        row.original[PlayersTableKeys.totalShiftInAmountSum] < 15000) ||
      (row.original[PlayersTableKeys.totalShiftOutAmountSum] >= 1000 &&
        row.original[PlayersTableKeys.totalShiftOutAmountSum] < 15000)
    )
      return classes.firstLevelBackground;
    if (
      row.original[PlayersTableKeys.totalShiftInAmountSum] >= 15000 ||
      row.original[PlayersTableKeys.totalShiftOutAmountSum] >= 15000
    ) {
      return classes.secondLevelBackground;
    }
    return "";
  };

  return (
    <>
      <Table {...getTableProps()} stickyHeader className={classes.table}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {!isLoading && (
          <TableBody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  className={getRowClassName(row)}
                >
                  {row.cells.map((cell: any) => {

                    const playerBlocked = checkIfPlayerIsBlocked(row);

                    const playerUnknown = checkIfPlayerIsInconclusive(row);

                    return (
                      <TableCell {...cell.getCellProps()}>
                        <div
                          className={clsx(
                            classes.nameContainer,
                            playerBlocked && classes.blocked,
                            playerUnknown && classes.unknown,
                            cell?.style
                          )}
                        >
                          {cell.column.id === PlayersTableKeys.name && (
                            <>
                              {playerBlocked && <Block />}
                              {playerUnknown && <HelpOutline />}
                            </>
                          )}
                          <div className={classes.nameText}>
                            {cell.render("Cell")}
                          </div>

                          {cell.column.id === PlayersTableKeys.name &&
                            row.original.wasEdited && (
                              <EditOutlined className={classes.pencilIcon} />
                            )}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ height: "70%", width: "100%" }}
        >
          <Loading width={100} />
        </Box>
      )}
      <TablePagination
        activePage={activePage}
        pages={pages}
        changePage={changePage}
      />
    </>
  );
};

export default PlayerTable;
