import { makeStyles } from "@material-ui/core/styles";

export const usePlayerTransactionStyles = makeStyles((theme) => ({
  formContainer: {
    minWidth: 340,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  fullWidth: {
    gridColumn: "auto / span 2",
  },
  error: {
    color: "red",
    alignItems: "center",
    display: "flex",
  },
  button: {
    minHeight: 50,
    minWidth: 340,
    marginTop: theme.spacing(1),
    color: "#fff",
    fontSize: 14,
  },
  saveIcon: {
    color: "#fff",
    marginRight: theme.spacing(1),
  },
  inputsContainer: {
    display: "grid",
    gridColumnGap: theme.spacing(0.5),
  },
  input: {
    width: 200,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "&:first-child": {
      marginRight: theme.spacing(0.5),
    },
    "&:last-child": {
      marginLeft: theme.spacing(0.5),
    },
  },
  errorIcon: {
    marginRight: theme.spacing(0.5),
  },
}));
