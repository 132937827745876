import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
    client_id: 'perlas-gaming-web-client',
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    response_type: 'code',
    scope: "openid profile api offline_access",
    authority: process.env.REACT_APP_AUTHORITY_URL,
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
  };
  

const userManager = createUserManager(userManagerConfig);

export default userManager;