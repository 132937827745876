import { makeStyles } from "@material-ui/core/styles";

export const useEditPlayerDialogStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: "center",
    fontSize: "32px",
  },
  dialogContent: {
    minWidth: 430,
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dialogClose: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: "#CECECE",
    fontSize: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  error: {
    color: "red",
    marginBottom: theme.spacing(1),
  },
  notFound: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  errorIcon: {
    paddingRight: theme.spacing(0.5),
  },
}));
