import { PlayerDataStatus } from "../../Shared/types/Players";
import { IApiStoreEntity } from "../../Shared/types/Stores";

export interface IPlayersTableRow {
  playerId: number;
  name: string;
  id: number;
  totalShiftInAmountSum: number | undefined;
  totalShiftOutAmountSum: number | undefined;
  latestTransactionInStore: Date | string | null;
  birthDate: string | undefined;
  gamblingEligibility?: PlayerDataStatus;
  citizenship: string | null | undefined;
  nationalIdentificationNumber: string | null | undefined;
}

export interface IPlayersAnalysisTableRow extends IPlayersTableRow {
  shift: string | undefined | null;
  latestTransactionStoreId: number | undefined;
  latestTransactionStoreName: string | undefined | null;
  firstName: string | null;
  lastName: string | null;
}

export interface IPlayerTransactionsTableRow extends IPlayersAnalysisTableRow {
  id: number;
  employeeName?: string;
  wasEdited: boolean;
  createdOn?: Date;
  updatedOn?: Date;
  outAmount?: number;
  inAmount?: number;
}

export enum PlayersTableKeys {
  playerId = "playerId",
  firstName = "firstName",
  lastName = "lastName",
  playerName = "playerName",
  name = "name",
  inAmount = "inAmount",
  outAmount = "outAmount",
  totalShiftInAmountSum = "totalShiftInAmountSum",
  storeShiftInAmountSum = "storeShiftInAmountSum",
  storeShiftOutAmountSum = "storeShiftOutAmountSum",
  totalShiftOutAmountSum = "totalShiftOutAmountSum",
  latestTransactionInStore = "latestTransactionInStore",
  birthDate = "birthDate",
  gamblingEligibility = "gamblingEligibility",
  gamblingCheckResult = "gamblingCheckResult",
  citizenship = "citizenship",
  nationalIdentificationNumber = "nationalIdentificationNumber",
  shift = "shift",
  latestTransactionStoreId = "latestTransactionStoreId",
  latestTransactionStoreName = "latestTransactionStoreName",
  employeeName = "employeeName",
  storeName = "storeName",
}

export enum AddPlayerKeys {
  createPlayer = "create",
  checkPlayer = "check",
  editPlayer = "edit",
}

export enum AdminFilterStorage {
  Money = "AdminFilter_Money",
  Date = "AdminFilter_Date",
  Site = "AdminFilter_Site",
}

export interface IAdminMoneyFilter {
  "1k": boolean;
  "15k": boolean;
}

export interface IPlayerTransaction {
  inAmount: number;
  outAmount: number;
  playerName?: string;
}

export interface IWorkerRow {
  id: number;
  username?: string;
  firstName?: string;
  lastName?: string;
  stores: IApiStoreEntity[];
  role: number[];
  createdOn: string;
  updatedOn?: string;
}

export interface ITransactionPlayerOption {
  playerName: string;
  nationalIdentificationNumber?: string;
  birthDate?: string;
  playerId?: number;
}

export interface IPlayerTableWrapperFilter {
  minDate?: Date | number | string;
  maxDate?: Date | number | string;
  minSumPerShift?: number;
  pageNumber?: number;
  storeId?: number;
}
