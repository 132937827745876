import { makeStyles } from "@material-ui/core/styles";

export const useCashierTabStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "170px",
    position: "relative",
    color: "white",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.spacing(2)
  },
  exitButton: {
    color: "white",
    position: "absolute",
    top: "15px",
    right: "13px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  gamblingSite: {
    fontWeight: 500,
  },
  profileName: {
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(5)
  },
  changeSite: {
    paddingLeft: theme.spacing(1),
    color: "white",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  changeSiteIcon: {
    fontSize: "inherit", // inherits from above
  },
  selectionForm: {
    width: 140,
    marginLeft: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      fontSize: 12,
    },
  },
  name: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  select: {
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.125)",
    },
  },
  selection: {
    fontSize: 12,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  changeApp: {
    position: "absolute",
    top: "15px",
    left: "13px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));
