import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import UserTab from "../../Shared/components/UserTab";
import PlayerTab from "../components/cashier/PlayerTab";
import PlayersTable from "../components/cashier/PlayersTable";
import AddPlayerTransaction from "../components/cashier/players/AddPlayerTransaction";
import PlayerTransactionWarning from "../components/cashier/players/PlayerTransactionWarning";
import { IApiPlayerEntity, PlayerDataStatus, IApiPlayerIdentification, TPlayerState } from "../../Shared/types/Players";
import { changeCurrentPlayer, postPlayerFalseAlarm } from "../actions/PlayersActions";
import { IPlayersTableRow, PlayersTableKeys } from "../constants/Interfaces";
import { useCashierMainStyles } from "./CashierMain.styles";
import Toast from "../../Shared/components/utils/Toast";
import PlayerPossibleFalseAlarmDialog from "Gaming/components/cashier/players/PlayerPossibleFalseAlarmDialog";
import { IAppState } from "Shared/store/Store";
import { TStoreState } from "Shared/types/Stores";
import { getStorePlayersList } from "Gaming/actions/StoresActions";
import { isPlayerForbiddenInEuOrOnDatoOrUn } from "Shared/helpers/Players";
import PlayerCommentModal from "Gaming/components/cashier/PlayerCommentModal";

const CashierMain: React.FC = () => {
  const classes = useCashierMainStyles();
  const { t } = useTranslation();
  const [transactionPlayer, setTransactionPlayer] = useState<
    Partial<IPlayersTableRow>
  >();
  const [transactionWindow, setTransactionWindow] = useState(false);
  const [transactionWarning, setTransactionWarning] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [commentDialog, setCommentDialog] = useState(false);
  const [commentAboutPlayer, setCommentAboutPlayer] = useState<string>();
  
  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  const columns = [
    {
      Header: `${t("name")}`,
      accessor: PlayersTableKeys.playerName,
      Cell: (cell: any) => (
        <Typography className={classes.big}>{cell.value}</Typography>
      ),
    },
    {
      Header: `${t("bidSum")}`,
      accessor: PlayersTableKeys.storeShiftInAmountSum,
      Cell: (cell: any) => (
        <Typography className={classes.small}>{cell.value}</Typography>
      ),
    },
    {
      Header: `${t("cashInSum")}`,
      accessor: PlayersTableKeys.storeShiftOutAmountSum,
      Cell: (cell: any) => (
        <Typography className={classes.small}>{parseFloat(cell.value).toFixed(2)}</Typography>
      ),
    },
    {
      Header: `${t("time")}`,
      accessor: PlayersTableKeys.latestTransactionInStore,
      Cell: (cell: any) => {
        const date = cell.value.endsWith("Z")
          ? new Date(cell.value.slice(0, -1))
          : new Date(cell.value);
        return (
          <Typography className={classes.small}>
            {format(date || null, "yyyy-MM-dd HH:mm:ss")}
          </Typography>
        );
      },
    },
    {
      Header: "",
      id: "warning",
      Cell: (tableInfo: any) => (
        <>
          <Tooltip
            title={`${t("info")}`}
            placement="bottom"
            onClick={() => handlePlayerInfoCheck(tableInfo.row.original)}
          >
            <Info className={classes.green} />
          </Tooltip>
        </>
      ),
    },
  ];

  const handlePlayerSelect = useCallback(
    (player: Partial<IPlayersTableRow> | undefined) => {
      if (player?.gamblingEligibility === PlayerDataStatus.Forbidden) {
        return;
      }

      setTransactionPlayer(player);
      setTransactionWindow(true);
    },
    []
  );

  const handlePlayerFalseAlarm = async (playerId : number) =>{
    const payload: IApiPlayerIdentification ={
      playerId: playerId
    };

    setIsLoading(true);
    
    const result: any = await dispatch(
      postPlayerFalseAlarm(storesState?.selectedStore?.id!, payload)
    );

    setIsLoading(false);    

    dispatch(getStorePlayersList(storesState?.selectedStore?.id!,1));

    handlePlayerInfoCheck(result.data as IApiPlayerEntity);

    if (result.data.gamblingEligibility !== PlayerDataStatus.Allowed &&
      result.data.gamblingEligibility !== PlayerDataStatus.Forbidden) {
        //update gambling eligibility, for text to be updated
        transactionPlayer!.gamblingEligibility = result.data.gamblingEligibility;
        return;
    }

    setTransactionWindow(false);
  };

  const handlePlayerInfoCheck = (player: IApiPlayerEntity | undefined) => {
    if (player) {
      dispatch(changeCurrentPlayer(player));
    }
  };

  const handleTransactionSubmit = (
    inAmount: number,
    outAmount: number,
    warning?: boolean
  ) => {
    setTransactionWindow(false);

    if (warning) {
      setTransactionWarning(true);
    } else {
      setTransactionPlayer(undefined);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.leftSideContainer}>
        <UserTab />
        <PlayerTab onPlayerSelect={handlePlayerSelect} />
      </div>
      <div className={classes.rightSideContainer}>
        <PlayersTable columns={columns} onPlayerSelect={handlePlayerSelect} />
      </div>

      {(transactionPlayer && 
        isPlayerForbiddenInEuOrOnDatoOrUn(transactionPlayer?.gamblingEligibility)) ?
          <PlayerPossibleFalseAlarmDialog
          onClose={() => setTransactionWindow(false)}
          open={transactionWindow}
          player={transactionPlayer}
          onSubmit={() => handlePlayerFalseAlarm(transactionPlayer.id!)}
          isLoading={isLoading}></PlayerPossibleFalseAlarmDialog> 
          : null}

      {(transactionPlayer && 
        !isPlayerForbiddenInEuOrOnDatoOrUn(transactionPlayer?.gamblingEligibility)) ?
        <>
          <AddPlayerTransaction
          open={transactionWindow}
          onClose={() => setTransactionWindow(false)}
          onSubmit={handleTransactionSubmit}
          player={transactionPlayer}/> 
          
          <PlayerTransactionWarning
            open={transactionWarning}
            player={transactionPlayer}
            handleSubmit={() => {
              setTransactionWarning(false);
            }}/>

          {commentAboutPlayer && 
           transactionPlayer.gamblingEligibility === PlayerDataStatus.Allowed ? 
            <PlayerCommentModal
              commentDialog={commentDialog}
              commentAboutPlayer={commentAboutPlayer}
              handleClose={() => {
                setCommentDialog(false);
              }}
              handleOnExited={() => {
                setCommentAboutPlayer(undefined);
              }}/> : null}
        </>        
        : null}
    </div>
  );
};

export default CashierMain;
