import { makeStyles } from "@material-ui/core/styles";

export const useCashierMainStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    minHeight: "100%",
    gridTemplateColumns: "350px 1fr",
    gridColumnGap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  leftSideContainer: {
    display: "flex",
    flexDirection: "column",
  },
  rightSideContainer: {
    height: "100%",
  },
  green: {
    color: theme.palette.primary.main,
  },
  small: {
    fontSize: 12,
  },
  big: {
    fontSize: 14,
  },
}));
