import React from "react";
import { Switch } from "react-router-dom";
import AdminEvents from "../containers/admin/AdminEvents";
import AdminReports from "../containers/admin/AdminReports";
import { ROUTES } from "../constants/Routes";
import { RoleDescription } from "../../Shared/constants/Roles";
import PrivateRoute from "../../Shared/router/PrivateRoute";
import AdminLogReports from "ToTo/containers/admin/AdminLogReports";

const AdminRouter: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={[ROUTES.ADMIN.MAIN, ROUTES.ADMIN.EVENTS, '/']}
        component={AdminEvents}
        roles={[RoleDescription.Admin]}
      />
      <PrivateRoute
        exact
        path={ROUTES.ADMIN.REPORTS}
        component={AdminReports}
        roles={[RoleDescription.Admin]}
      />
            <PrivateRoute
        exact
        path={ROUTES.ADMIN.LOG_REPORTS}
        component={AdminLogReports}
        roles={[RoleDescription.Admin]}
      />
    </Switch>
  );
};

export default AdminRouter;
