import React, { ReactElement } from "react";
import { Field } from "react-final-form";
import {
  FormControl,
  TextField,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import { useFormInputStyles } from "./FormInput.styles";

interface IFormInput {
  name: string;
  id: string;
  inputClass?: string;
  icon?: ReactElement;
  secondaryIcon?: ReactElement;
  autocomplete?: string;
  placeholder?: string;
  type?: string;
  height?: string;
  className?: string;
  label?: string;
  showPassword?: () => void;
  setError?: (value: boolean) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  InputProps?: object;
  InputLabelProps?: object;
  value?: string | number | null;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;
}

export const FormInput: React.FC<IFormInput> = ({
  name,
  id,
  inputClass,
  icon,
  secondaryIcon,
  autocomplete,
  placeholder,
  type,
  height,
  className,
  onFocus,
  onBlur,
  onChange,
  label,
  showPassword,
  setError,
  InputProps,
  InputLabelProps,
  value,
  disabled,
  multiline,
  rows,
  rowsMax,
}) => {
  const classes = useFormInputStyles();
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <FormControl
          className={className}
          fullWidth
          variant="outlined"
          onBlur={() => {
            if (meta.error && setError) {
              setError(true);
            }
          }}
        >
          <TextField
            id={id}
            label={label}
            style={{ height }}
            multiline={multiline}
            rows={rows}
            rowsMax={rowsMax}
            error={
              ((meta.submitError && !meta.dirtySinceLastSubmit) ||
                meta.error) &&
              meta.touched &&
              !meta.active
            }
            autoComplete={autocomplete}
            placeholder={placeholder}
            className={inputClass}
            variant="outlined"
            type={type}
            {...input}
            onBlur={(e: any) => {
              input.onBlur(e);
              onBlur?.(e);
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              input.onChange(e);
              onChange?.(e);
            }}
            onFocus={onFocus}
            InputProps={{
              ...InputProps,
              startAdornment: icon && (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
              endAdornment: secondaryIcon && (
                <InputAdornment
                  position="end"
                  className={classes.endIcon}
                  onClick={() => {
                    if (showPassword) showPassword();
                  }}
                >
                  {secondaryIcon}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              ...InputLabelProps,
            }}
            disabled={disabled}
          />
          {(meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
            meta.touched && (
              <FormHelperText className={classes.error}>
                {meta.error || meta.submitError}
              </FormHelperText>
            )}
        </FormControl>
      )}
    </Field>
  );
};

export default FormInput;
