import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Box } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { useTicketDeleteStyles } from "./TicketDeleteModal.styles";
import ConfirmModal from "../../../Shared/components/ConfirmModal";

interface ITicketDeleteModal {
  open: boolean;
  ticketId?: string;
  onConfirm: () => void;
  onClose: () => void;
  cancelEvent?: number;
}
const TicketDeleteModal: React.FC<ITicketDeleteModal> = ({
  open,
  ticketId,
  onConfirm,
  onClose,
  cancelEvent,
}) => {
  const { t } = useTranslation();
  const classes = useTicketDeleteStyles();

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <ConfirmModal
      open={open}
      title={
        ticketId ? (
          <Typography variant="h5">{t("confirmTicketCancel")}</Typography>
        ) : cancelEvent ? (
          <Typography variant="h5">{t("cancelEvent")}</Typography>
        ) : (
          <span></span>
        )
      }
      onConfirm={handleConfirm}
      onClose={handleClose}
      hideButtons={!ticketId && !cancelEvent}
    >
      <Grid container justify="center" alignItems="flex-end">
        {ticketId ? (
          <Typography className={classes.green}>{ticketId}</Typography>
        ) : cancelEvent ? (
          <Typography className={classes.green}>{cancelEvent}</Typography>
        ) : (
          <Box p={4} alignItems="center" display="flex" m={0} pt={0}>
            <ErrorOutline className={classes.red} style={{ marginRight: 4 }} />
            <Typography className={classes.red}>
              {t("pleaseEnterTicketId")}
            </Typography>
          </Box>
        )}
      </Grid>
    </ConfirmModal>
  );
};

export default TicketDeleteModal;
