import { AxiosError, AxiosResponse } from "axios";

export interface IApiUserEntity {
  id: number;
  username?: string;
  firstName?: string;
  lastName?: string;
  userStoreIds?: number[];
  role: number[];
  createdOn: string;
  updatedOn?: string;
}

export interface TUserState {
  readonly data?: Array<IApiUserEntity>;
  readonly isLoading?: boolean;
  readonly isLoadingNew?: boolean;
}

export const initialUserState: TUserState = {
  data: undefined,
  isLoading: false,
  isLoadingNew: false,
};

export interface ILoginData {
  username: string;
  password: string;
}

export enum UserChangePassword {
  REQUEST = "USER_CHANGE_PASSWORD_REQUEST",
  SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS",
  FAILED = "USER_CHANGE_PASSWORD_FAILED",
}

export enum UsersGet {
  REQUEST = "USER_GET_ALL_REQUEST",
  SUCCESS = "USER_GET_ALL_SUCCESS",
  FAILED = "USER_GET_ALL_FAILED",
}

export enum UsersPost {
  REQUEST = "USER_POST_REQUEST",
  SUCCESS = "USER_POST_SUCCESS",
  FAILED = "USER_POST_FAILED",
}

export enum UsersPut {
  REQUEST = "USER_PUT_REQUEST",
  SUCCESS = "USER_PUT_SUCCESS",
  FAILED = "USER_PUT_FAILED",
}

export type TUserChangePasswordAction =
  | {
      type: UserChangePassword.REQUEST;
    }
  | {
      type: UserChangePassword.SUCCESS;
      payload: null;
    }
  | {
      type: UserChangePassword.FAILED;
      payload: AxiosError;
    };

export type TUsersPutAction =
  | {
      type: UsersPut.REQUEST;
    }
  | {
      type: UsersPut.SUCCESS;
      payload: AxiosResponse<IApiUserEntity>;
    }
  | {
      type: UsersPut.FAILED;
      payload: AxiosError;
    };

export type TUsersPostAction =
  | {
      type: UsersPost.REQUEST;
    }
  | {
      type: UsersPost.SUCCESS;
      payload: AxiosResponse<IApiUserEntity>;
    }
  | {
      type: UsersPost.FAILED;
      payload: AxiosError;
    };

export type TUsersGetActions =
  | {
      type: UsersGet.REQUEST;
    }
  | {
      type: UsersGet.SUCCESS;
      payload: AxiosResponse<Array<IApiUserEntity>>;
    }
  | {
      type: UsersGet.FAILED;
      payload: AxiosError;
    };

export type TUserActions =
  | TUsersGetActions
  | TUsersPostAction
  | TUsersPutAction
  | TUserChangePasswordAction;
