import { makeStyles } from "@material-ui/core/styles";

export const useAdminPanelStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    position: "relative",
    color: theme.palette.secondary.main,
    borderRadius: 10,
    flex: 1,
  },

  player: {
    minHeight: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  menu: {
    width: "100%",
  },
  subMenu: {
    position: "fixed",
    transform: `translateX(${400 + theme.spacing(2)}px) translateY(-80px)`,
    width: 200,
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "#fff",
  },
  listItem: {
    textAlign: "center",
    height: 50,
    width: "100%",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  listItemActive: {
    backgroundColor: theme.palette.primary.main,
  },
  listItemText: {
    color: theme.palette.primary.main,
  },
  noBottomBorder: {
    borderBottom: "none",
  },
}));
