import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Cell } from "react-table";
import { format, isToday } from "date-fns";
import { toast } from "react-toastify";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "Shared/store/Store";
import { IAdminDateFilter } from "Shared/constants/Table";
import { AdminTicketActionsTableKeys } from "ToTo/constants/Interfaces";
import { getBettingEventTicketActions } from "ToTo/actions/BettingEventTicketActionActions";
import { handleError } from "Shared/utils/ErrorHandler";
import Toast from "Shared/components/utils/Toast";
import TablePagination from "Shared/components/TablePagination";

import { getPageCount } from "Shared/helpers/Utils";
import AdminTableFilterSimple from "Gaming/components/admin/players/tableHelpers/AdminTableFilterSimple";
import { useLogReportsTableBlockStyles } from "./LogReportsTableBlock.styles";
import {
  TBettingEventTicketActionsState,
  IBettingEventTicketActionModal,
  TicketActionType,
} from "ToTo/types/BettingEventTicketActions";
import TableSimple from "Shared/components/TableSimple";

const LogReportsTableBlock = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useLogReportsTableBlockStyles();
  const [error, setError] = useState<string>();
  const [totalPages, setTotalPages] = useState<Array<number>>();
  // filter state
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dateFilter, setDateFilter] = useState<Partial<IAdminDateFilter>>();

  const [ticketActions, setTicketActions] = useState<
    IBettingEventTicketActionModal[]
  >([]);

  //redux state
  const ticketActionsState: TBettingEventTicketActionsState = useSelector<
    IAppState,
    TBettingEventTicketActionsState
  >((state: IAppState) => state.bettingEventsTicketActionsState);

  // get data for table on filter change
  useEffect(() => {
    (async () => {
      try {
        await dispatch(
          getBettingEventTicketActions({
            filterString: searchTerm,
            minDate: dateFilter?.from,
            maxDate: dateFilter?.to,
            pageNumber: 1,
          })
        );
      } catch (err: any) {
        handleError(err?.status, setError);
      }
    })();
  }, [dateFilter, searchTerm, dispatch]);


  useEffect(() => {
    if (!ticketActionsState.data) return;
    // set data for table
    setTicketActions(ticketActionsState.data);
    // set total pages
    if (ticketActionsState.totalPages != null) {
      getPageCount(ticketActionsState.totalPages, setTotalPages);
    }
  }, [ticketActionsState.data, ticketActionsState.totalPages]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  const columns = useMemo(
    () => [
      {
        Header: `${t("fullName")}`,
        id: AdminTicketActionsTableKeys.UserName,
        accessor: AdminTicketActionsTableKeys.UserName,
      },
      {
        Header: t("action"),
        accessor: AdminTicketActionsTableKeys.ActionType,
        Cell: (cell: Cell) => {
          return (
            <Typography className={classes.small}>
              {t(`ticketActionStatus.${TicketActionType[cell.value]}`)}
            </Typography>
          );
        },
      },
      {
        Header: t("eventName"),
        accessor: AdminTicketActionsTableKeys.BettingEventName,
        Cell: (cell: Cell) => (
          <Typography className={classes.small}>{cell.value}</Typography>
        ),
      },
      {
        Header: t("time"),
        accessor: AdminTicketActionsTableKeys.ActionDate,
        Cell: (cell: Cell) => {
          const date = cell.value.endsWith("Z")
            ? new Date(cell.value.slice(0, -1))
            : new Date(cell.value);
          return (
            <Typography className={classes.small}>
              {isToday(date)
                ? format(date, "HH:mm:ss")
                : format(date, "yyyy-MM-dd HH:mm:ss")}
            </Typography>
          );
        },
      },
      {
        Header: t("ticketId"),
        accessor: AdminTicketActionsTableKeys.BettingEventTicketId,
        Cell: (cell: Cell) => (
          <Typography className={classes.small}>{cell.value}</Typography>
        ),
      },
    ],
    [t, classes.small]
  );

  const changePage = async (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
    try {
      await dispatch(
        getBettingEventTicketActions({
          filterString: searchTerm,
          minDate: dateFilter?.from,
          maxDate: dateFilter?.to,
          pageNumber: newPage,
        })
      );
    } catch (err: any) {
      handleError(err?.status, setError);
    }
  };

  return (
    <>
      <AdminTableFilterSimple
        setSearchTerm={setSearchTerm}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        fullRange
      />
      <div className={classes.tableContainer}>
      <TableSimple
        data={ticketActions}
        columns={columns}
        isLoading={ticketActionsState.isLoading}
      />
      </div>
      <TablePagination
        activePage={currentPage}
        containerStyle={classes.pagination}
        pages={totalPages}
        changePage={changePage}
      />
    </>
  );
};

export default LogReportsTableBlock;
