import React, { useState } from "react";
import { Button, FormControl } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useWorkerFormStyles } from "./WorkerForm.styles";
import FormInput from "../../../../Shared/components/utils/FormInput";
import PasswordStrengthHelp from "./passwordHelper/PasswordStrengthHelp";

interface IEditPasswordForm {
  onSubmit: (password: string) => void;
}

interface IFormValues {
  password: string;
  passwordRepeat: string;
}

const EditPasswordForm: React.FC<IEditPasswordForm> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showSecondaryPassword, setShowSecondaryPassword] = useState<boolean>(
    false
  );
  const [showPasswordHelp, setShowPasswordHelp] = useState<boolean>(false);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const submit = async (values: IFormValues) => {
    onSubmit(values.password);
  };

  const classes = useWorkerFormStyles();

  return (
    <Form
      onSubmit={submit}
      validate={(values: IFormValues) => {
        const errors: Record<any, string> = {};
        if (!values.passwordRepeat) {
          errors.passwordRepeat = t("required");
        }
        if (!values.password) {
          errors.password = t("required");
        } else if (!isPasswordValid) {
          errors.password = t("passwordComplexity");
        } else if (values.password !== values.passwordRepeat) {
          errors.passwordRepeat = t("passwordsNeedToMatch");
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.container}>
            <div className={classes.passwordStrengthContainer}>
              <FormInput
                name="password"
                id="password"
                className={classes.input}
                onFocus={() => setShowPasswordHelp(true)}
                onBlur={() => setShowPasswordHelp(false)}
                height={"56px"}
                label={t("password")}
                showPassword={() => setShowPassword(!showPassword)}
                secondaryIcon={
                  showPassword ? (
                    <VisibilityOff className={classes.inputIconClickable} />
                  ) : (
                    <Visibility className={classes.inputIconClickable} />
                  )
                }
                autocomplete="password"
                placeholder={t("password")}
                type={showPassword ? "text" : "password"}
              />
              {showPasswordHelp && (
                <PasswordStrengthHelp
                  password={values.password}
                  setIsPasswordValid={(isValid: boolean) =>
                    setIsPasswordValid(isValid)
                  }
                />
              )}
            </div>
            <FormInput
              name="passwordRepeat"
              id="passwordRepeat"
              className={classes.input}
              height={"56px"}
              label={t("passwordRepeat")}
              showPassword={() =>
                setShowSecondaryPassword(!showSecondaryPassword)
              }
              secondaryIcon={
                showSecondaryPassword ? (
                  <VisibilityOff className={classes.inputIconClickable} />
                ) : (
                  <Visibility className={classes.inputIconClickable} />
                )
              }
              autocomplete="password"
              placeholder={t("passwordRepeat")}
              type={showSecondaryPassword ? "text" : "password"}
            />
            <FormControl>
              <Button
                color="primary"
                type="submit"
                disabled={submitting}
                className={classes.button}
                size="large"
                variant="contained"
                disableElevation
              >
                {t("save")}
              </Button>
            </FormControl>
          </div>
        </form>
      )}
    />
  );
};

export default EditPasswordForm;
