import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useEventCreateModalStyles } from "./EventCreateModal.styles";
import NewEventForm from "./NewEventForm";
import { postBettingEvent } from "../../actions/BettingEventsActions";
import Toast from "../../../Shared/components/utils/Toast";
import { handleError } from "../../../Shared/utils/ErrorHandler";
import {
  INewBettingEvent,
  INewBettingEventFormated,
} from "../../types/BettingEvents";
import { format } from "date-fns";

interface IEventCreateModal {
  open: boolean;
  onClose: () => void;
}

const EventCreateModal: React.FC<IEventCreateModal> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const classes = useEventCreateModalStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const handleSubmit = async (values: INewBettingEvent) => {
    setError("");
    //TODO: get userId from userState
    const dataToSubmit: INewBettingEventFormated = {
      title: values.title,
      userId: 1,
      startsOn: format(new Date(values.startsOn), "yyyy-MM-dd'T'HH:mm:ssX"),
      bettingEndsOn: format(
        new Date(values.bettingEndsOn),
        "yyyy-MM-dd'T'HH:mm:ssX"
      ),
      prizePoolPercentage: values.prizePoolPercentage,
      ticketPrice: values.ticketPrice,
      guaranteedMinimumPrizePool: values.guaranteedMinimumPrizePool,
      participants: values.participants,
    };
    try {
      await dispatch(postBettingEvent(dataToSubmit));
      toast.success(<Toast text={t("eventCreated")} cleanup={() => {}} />);
    } catch (err: any) {
      handleError(err?.status, setError);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  return (
    <Dialog open={open}>
      <Close onClick={() => onClose()} className={classes.dialogClose} />
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h4">{t("newEvent")}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <NewEventForm onSubmit={handleSubmit} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default EventCreateModal;
