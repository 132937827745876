import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isValid, isAfter, differenceInYears, format } from "date-fns";
import { Form, Field } from "react-final-form";
import { Button, FormControl, TextField } from "@material-ui/core";
import { PersonAdd, CalendarTodayOutlined } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IApiPlayerEntity } from "../../../../Shared/types/Players";
import FormInput from "../../../../Shared/components/utils/FormInput";
import FormDate from "../../../../Shared/components/utils/FormDate";
import Loading from "../../../../Shared/components/utils/Loading";
import {
  AddPlayerKeys,
  IPlayersAnalysisTableRow,
} from "../../../constants/Interfaces";
import { checkLtIdentification } from "../../../../Shared/helpers/Players";
import { useAddPlayerFormStyles } from "./AddPlayerForm.styles";
import ConfirmIdDialog from "./ConfirmIdDialog";
import { MINIMUM_NEW_PLAYER_AGE } from "../../../constants/main.constants";
import { getBirthDateFromNationalId } from "../../../../Shared/helpers/Date";
import availableCountriesLt from "../../../constants/CountryListLT.json";

const NATIONAL_ID_MAX_FIRST_DIGIT = 7;
const NATIONAL_ID_LENGTH = 11;
const LITHUANIAN_CITIZENSHIP = "Lietuva";

interface IPlayerForm {
  player?:
    | Partial<IApiPlayerEntity>
    | undefined
    | null
    | IPlayersAnalysisTableRow;
  onCreateNew?: (player: IApiPlayerEntity) => void;
  onCheck?: (player: IApiPlayerEntity) => void;
  onEdit?: (player: IApiPlayerEntity) => void;
  canCreateNew?: boolean | undefined;
  submitting: boolean;
  isEditing?: boolean;
}

const checkIdentification = (id: string, citizenship: string | null) => {
  if (citizenship?.toLowerCase().includes("liet")) {
    if (
      parseInt(id.toString()[0], 10) <= NATIONAL_ID_MAX_FIRST_DIGIT &&
      id.toString().length === NATIONAL_ID_LENGTH
    ) {
      return checkLtIdentification(id);
    } else {
      return false;
    }
  }
  return true;
};

const AddPlayerForm: React.FC<IPlayerForm> = ({
  player,
  onCreateNew,
  onCheck,
  onEdit,
  canCreateNew,
  submitting,
  isEditing,
}) => {
  const { t } = useTranslation();
  const classes = useAddPlayerFormStyles();
  const [
    incorrectIdentification,
    setIncorrectIdentification,
  ] = useState<string>();
  const [openIdDialog, setOpenIdDialog] = useState<boolean>(false);
  const [error, setError] = useState("");

  let submitAction:
    | AddPlayerKeys.checkPlayer
    | AddPlayerKeys.createPlayer
    | AddPlayerKeys.editPlayer = AddPlayerKeys.checkPlayer;

  const handleCheck = async (values: IApiPlayerEntity) => {
    await onCheck?.(values);
  };

  const closeIdDialog = () => {
    setOpenIdDialog(false);
    setIncorrectIdentification(undefined);
  };

  const handleCreate = async (values: IApiPlayerEntity) => {
    await onCreateNew?.(values);
  };

  const validateCheckPlayerInputs = async (values: IApiPlayerEntity) => {
    // Player check form, user must input: name,surname and birthdate OR national code
    const errors: any = {};
    if (
      !values.firstName &&
      !values.lastName &&
      !values.birthDate &&
      !values.nationalIdentificationNumber
    ) {
      errors.firstName = t("required");
      errors.lastName = t("required");
      errors.birthDate = t("required");
      errors.nationalIdentificationNumber = t("required");
    } else if (
      (values.firstName || values.lastName || values.birthDate) &&
      !values.nationalIdentificationNumber
    ) {
      if (!values.firstName?.trim().length) {
        errors.firstName = t("required");
      }
      if (!values.lastName?.trim().length) {
        errors.lastName = t("required");
      }
      if (!values.birthDate) {
        errors.birthDate = t("required");
      }
    }

    if (Object.keys(errors).length) {
      return errors;
    }

    await handleCheck(values as IApiPlayerEntity);
  };

  const CreateAndEditValidation = (values: IApiPlayerEntity) => {
    // Must input everything.
    const errors: any = {};
    if (!values.firstName?.trim().length) {
      errors.firstName = t("required");
    }

    if (!values.lastName?.trim().length) {
      errors.lastName = t("required");
    }

    if (!values.birthDate) {
      errors.birthDate = t("required");
    } else if (!isValid(new Date(values.birthDate))) {
      errors.birthDate = t("dateInvalid");
    } else if (isAfter(new Date(values.birthDate), new Date())) {
      errors.birthDate = t("dateInvalid");
    } else if (
      differenceInYears(new Date(), new Date(values.birthDate)) <
      MINIMUM_NEW_PLAYER_AGE
    ) {
      errors.birthDate = t("ageCensus");
    }

    if (!values.nationalIdentificationNumber) {
      errors.nationalIdentificationNumber = t("required");
    }

    if (!values.citizenship) {
      errors.citizenship = t("required");
    }

    if (Object.keys(errors).length) {
      return errors;
    }
  };

  const handleCreatePlayer = async (values: IApiPlayerEntity) => {
    // New player form validation. Must input everything.
    const isValidationErrors = CreateAndEditValidation(values);
    if (isValidationErrors) return isValidationErrors;

    const isCorrect = checkIdentification(
      values.nationalIdentificationNumber,
      values.citizenship
    );
    if (isCorrect) {
      setError("");
      await handleCreate(values as IApiPlayerEntity);
    } else {
      if (!incorrectIdentification) {
        setIncorrectIdentification(
          values.nationalIdentificationNumber.toString()
        );
        setOpenIdDialog(true);
        return;
      }
    }
  };

  return (
    <Form
      initialValues={{
        ...player,
        citizenship: player?.citizenship || LITHUANIAN_CITIZENSHIP,
      }}
      onSubmit={async (values: IApiPlayerEntity) => {
        if (submitAction === AddPlayerKeys.checkPlayer) {
          return validateCheckPlayerInputs(values);
        }
        if (submitAction === AddPlayerKeys.createPlayer) {
          return handleCreatePlayer(values);
        }
        if (submitAction === AddPlayerKeys.editPlayer) {
          const isValidationErrors = CreateAndEditValidation(values);
          if (isValidationErrors) return isValidationErrors;
          return onEdit?.(values);
        }
      }}
      validate={(values: IApiPlayerEntity) => {
        const errors: any = {};
        if (values.birthDate) {
          if (!isValid(new Date(values.birthDate))) {
            errors.birthDate = t("dateInvalid");
          } else if (isAfter(new Date(values.birthDate), new Date())) {
            errors.birthDate = t("dateInvalid");
          } else if (
            differenceInYears(new Date(), new Date(values.birthDate)) <
            MINIMUM_NEW_PLAYER_AGE
          ) {
            errors.birthDate = t("ageCensus");
          }
        }
        const integerRe = /^(\d+)$/;
        const { nationalIdentificationNumber } = values;

        // if (
        //   nationalIdentificationNumber &&
        //   !nationalIdentificationNumber.toString().match(integerRe)
        // ) {
        //   errors.nationalIdentificationNumber = t("mustBeNumeric");
        // }

        return errors;
      }}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit}>
          <ConfirmIdDialog
            open={openIdDialog}
            handleClose={closeIdDialog}
            submitting={submitting}
            handlePlayerCreate={() =>
              onCreateNew?.({ ...values, forced: true })
            }
          />
          {error && <div className={classes.error}>{error}</div>}
          <div className={classes.formContainer}>
            <FormInput
              name="firstName"
              id="firstName"
              className={classes.input}
              height="56px"
              label={t("firstName")}
            />
            <FormInput
              name="lastName"
              id="lastName"
              className={classes.input}
              height="56px"
              label={t("lastName")}
            />
            <FormDate
              name="birthDate"
              id="birthDate"
              className={classes.input}
              height="56px"
              format="yyyy-MM-dd"
              label={`${t("birthDate")} (YYYY-MM-DD)`}
              icon={<CalendarTodayOutlined className={classes.calendarIcon} />}
              variant="dialog"
              inputVariant="outlined"
              maxDate={new Date()}
            />
            <Field name="citizenship">
              {({ input, meta }) => (
                <>
                  <Autocomplete
                    id="citizenship"
                    noOptionsText={
                      values?.citizenship && values?.citizenship.length > 2
                        ? t("noOptions")
                        : t("3orMoreSymbols")
                    }
                    value={input?.value}
                    onChange={(
                      _event: React.ChangeEvent<{}>,
                      value: any | null
                    ) => {
                      form.change("citizenship", value);
                    }}
                    className={classes.input}
                    options={
                      values?.citizenship && values?.citizenship.length > 2
                        ? Object.values(availableCountriesLt) || []
                        : []
                    }
                    filterOptions={(options) =>
                      options.filter((o) =>
                        o.toLowerCase().includes(input.value.toLowerCase())
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...input}
                        {...params}
                        label={t("nationality")}
                        type="text"
                        variant="outlined"
                        error={
                          ((meta.submitError && !meta.dirtySinceLastSubmit) ||
                            meta.error) &&
                          meta.touched &&
                          !meta.active
                        }
                        helperText={
                          meta.touched &&
                          !meta.active &&
                          (meta.error
                            ? meta.error
                            : meta.submitError && !meta.dirtySinceLastSubmit
                            ? meta.submitError
                            : "")
                        }
                      />
                    )}
                  />
                </>
              )}
            </Field>
            <FormInput
              name="nationalIdentificationNumber"
              id="nationalIdentificationNumber"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const input = event.target.value;
                if (!input) return;

                if (checkLtIdentification(input)) {
                  const birthdate = getBirthDateFromNationalId(input);
                  if (birthdate) {
                    form.change("birthDate", format(birthdate, "yyyy-MM-dd"));
                  }
                }
              }}
              className={classes.input}
              height="56px"
              label={t("nationalIdentificationNumber")}
            />

            {isEditing && (
              <FormInput
                name="note"
                id="note"
                className={classes.input}
                multiline
                rows={4}
                rowsMax={4}
                label={t("comments")}
              />
            )}

            {canCreateNew && (
              <FormControl>
                <Button
                  name="create"
                  color="primary"
                  className={classes.button}
                  size="large"
                  variant="outlined"
                  disableElevation
                  disabled={submitting}
                  startIcon={<PersonAdd />}
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    submitAction = AddPlayerKeys.createPlayer;
                    handleSubmit({ ...event });
                  }}
                >
                  {submitting ? (
                    <Loading width={20} circleColor="white" />
                  ) : (
                    t("createPlayer")
                  )}
                </Button>
              </FormControl>
            )}
            <FormControl>
              <Button
                name="check"
                color="primary"
                className={classes.button}
                size="large"
                variant="contained"
                disabled={submitting}
                disableElevation
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  submitAction = isEditing
                    ? AddPlayerKeys.editPlayer
                    : AddPlayerKeys.checkPlayer;
                  handleSubmit({ ...event });
                }}
              >
                {submitting ? (
                  <Loading width={20} circleColor="white" />
                ) : isEditing ? (
                  t("editPlayer")
                ) : (
                  t("check")
                )}
              </Button>
            </FormControl>
          </div>
        </form>
      )}
    />
  );
};

export default AddPlayerForm;
