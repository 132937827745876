import { makeStyles } from "@material-ui/core/styles";

export const useEventWinnerModalStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: "center",
    paddingTop: 0,
  },
  dialogContent: {
    minWidth: 440,
    paddingBottom: theme.spacing(2),
    borderRadius: 10,
  },
  dialogClose: {
    marginRight: theme.spacing(2),
    marginLeft: "auto",
    marginTop: theme.spacing(2),
    color: "#0C0C0C",
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchBox: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  button: {
    fontSize: 12,
    height: 40,
    width: "45%",
  },
  searchIcon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  green: {
    color: '#0AAF60',
  },
  checkedIcon: {
    fontSize: 14,
  },
  paddingTop: {
    paddingTop: theme.spacing(0.6),
  },
  table: {
    marginBottom: theme.spacing(3),
    tableLayout: "fixed",
    height: 250,
    position: "relative",
    overflowY: "auto",
    display: "block",
    "& .MuiTableCell-root": {
      paddingLeft: 5,
      paddingRight: 5,
    },
    "& .MuiTableCell-body": {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  row: {
    "&:hover": {
      background: "rgba(255,255,255,.085)",
      cursor: "pointer",
    },
  },
  smallCell: {
    width: "7% !important",
  },
  printIcon: {
    paddingRight: theme.spacing(1),
    width: 28,
    height: 28,
  },
  throphyIcon: {
    color: "#0AAF60",
    fontSize: 27,
    paddingBottom: 5,
  },
  sum: {
      display: 'flex',
      marginBottom: theme.spacing(2),
  },
  backButton: {
    width: '35%',
    height: 42,
    backgroundColor: theme.palette.error.main,
  },
  printButton: {
    width: '55%',
    height: 42,
    color: '#FFF'
  },
  textSpace: {
    paddingLeft: theme.spacing(0.5),
  },
  sumInCash: {
    marginBottom: theme.spacing(3)
  }
}));
