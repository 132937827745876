import { makeStyles } from "@material-ui/core/styles";

export const useWorkerEditPasswordDialogStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: "center",
    fontSize: "32px",
  },
  dialogContent: {
    minWidth: 450,
    minHeight: 250,
    paddingBottom: theme.spacing(2),
    borderRadius: 10,
  },
  dialogClose: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: "#CECECE",
    fontSize: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
