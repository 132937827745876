import { apiCall } from "../../Shared/actions/BaseAction";
import {
  TBettingEventTicketActionsState,
  IBettingEventTicketActionData,
  BettingEventTicketActionsGet,
} from "../types/BettingEventTicketActions";
import { API_END_POINTS } from "../constants/Api";
import { PAGE_LIMIT } from "../../Shared/constants/Table";

//TODO chagne type
export const getBettingEventTicketActions = (params: any) => {
  return apiCall<
    TBettingEventTicketActionsState,
    null,
    IBettingEventTicketActionData
  >(
    BettingEventTicketActionsGet,
    "GET",
    API_END_POINTS.BETTING_EVENT_TICKET_ACTIONS,
    true,
    null,
    {},
    { ...params, limit: PAGE_LIMIT }
  );
};
