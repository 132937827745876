import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import WorkersTable from "../../components/admin/workers/WorkersTable";
import WorkerEditDialog from "../../components/admin/workers/WorkerEditDialog";
import { IWorkerData } from "../../../Shared/types/Worker";
import { useAdminWorkersStyles } from "./AdminWorkers.styles";
import { IApiUserEntity, TUserState } from "../../../Shared/types/Users";
import { IAppState } from "../../../Shared/store/Store";
import { handleError } from "../../../Shared/utils/ErrorHandler";
import { editUser, getUsersList, postUser } from "../../actions/UsersActions";
import Toast from "../../../Shared/components/utils/Toast";
import { toast } from "react-toastify";
import { IApiStoreEntity, TStoreState } from "../../../Shared/types/Stores";
import { IWorkerRow } from "../../constants/Interfaces";
import { API_ERRORS } from "../../constants/Api";

const formateTableData = (
  users: IApiUserEntity[],
  stores: IApiStoreEntity[]
) => {
  const tableData: IWorkerRow[] = [];
  for (let i = 0; i < users.length; i++) {
    const user = users[i];
    if (user.userStoreIds?.length) {
      const storeData: IApiStoreEntity[] = [];
      for (let j = 0; j < user.userStoreIds.length; j++) {
        const foundStore = stores.find(
          (store) => user.userStoreIds && store.id === user?.userStoreIds[j]
        );
        if (foundStore) storeData.push(foundStore);
      }
      tableData.push({ ...user, stores: storeData || [] });
    } else {
      tableData.push({ ...user, stores: [] });
    }
  }
  return tableData;
};

const AdminWorkers: React.FC = () => {
  const classes = useAdminWorkersStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [tableData, setTableData] = useState<IWorkerRow[]>([]);
  const [selectedWorker, setSelectedWorker] = useState<IWorkerData>();

  const usersState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.usersState
  );
  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );

  useEffect(() => {
    (async () => {
      if (!usersState.data) {
        try {
          await dispatch(getUsersList());
        } catch (err: any) {
          handleError(err?.status, setError);
        }
      }
    })();
  }, [dispatch, usersState.data]);

  useEffect(() => {
    (async () => {
      if (!storesState.data) {
        try {
          await dispatch(getUsersList());
        } catch (err: any) {
          handleError(err?.status, setError);
        }
      }
    })();
  }, [dispatch, storesState.data]);

  useEffect(() => {
    if (usersState.data && storesState.data) {
      const data = formateTableData(usersState.data, storesState.data);
      setTableData(data);
    }
  }, [storesState.data, usersState.data]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
      setError(undefined);
    }
  }, [error]);

  const columns = [
    {
      Header: `${t("name")}`,
      accessor: "firstName",
      id: "name",
      Cell: (cell: any) => (
        <span>{`${cell.value} ${cell.row.original.lastName}`}</span>
      ),
    },
    {
      Header: `${t("role")}`,
      accessor: "role",
      id: "role",
      disableSortBy: true,
      Cell: (cell: any) => <span>{t(`roleTypes.${cell.value}`)}</span>,
    },
    {
      Header: `${t("site")}`,
      accessor: "stores",
      id: "store",
      disableSortBy: true,
      Cell: (cell: any) => (
        <div className={classes.flexCenter}>
          <span className={classes.overflowText}>
            {cell.value?.map((site: IApiStoreEntity, index: any) => (
              <React.Fragment key={site.id}>
                {`${site.name}${index !== cell.value.length - 1 ? ", " : ""}`}
              </React.Fragment>
            ))}
          </span>
        </div>
      ),
    },
    {
      Header: "",
      id: "tools",
      disableSortBy: true,
      Cell: (tableInfo: any) => (
        <Button
          onClick={() => {
            const row = tableInfo.row.original as IWorkerData;
            handleDialogOpen(row);
          }}
        >
          <Tooltip title={`${t("edit")}`}>
            <Edit className={classes.green} />
          </Tooltip>
        </Button>
      ),
    },
  ];

  const handleDialogOpen = (worker?: IWorkerData) => {
    setSelectedWorker(worker);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedWorker(undefined);
    setDialogOpen(false);
  };

  const handleDialogSubmit = async (
    values: IWorkerData,
    sites?: IApiStoreEntity[]
  ) => {
    let user = { ...values };
    user.firstName = user.firstName.trim();
    user.lastName = user.lastName.trim();
    user.username = user.username.trim();

    if (sites) {
      //we only need site ids for submitting
      const siteIds = sites?.map((site) => site.id);
      user.userStoreIds = siteIds;
    }
    try {
      if (!selectedWorker) {
        await dispatch(postUser(user));
        toast.success(<Toast text={t("userCreated")} cleanup={() => {}} />);
      } else {
        await dispatch(editUser(user, selectedWorker.id));
        toast.success(<Toast text={t("userEdited")} cleanup={() => {}} />);
      }
      handleDialogClose();
    } catch (err: any) {
      if (
        err?.request?.status === 400 &&
        err?.data.title === API_ERRORS.USERNAME_EXISTS
      ) {
        setError(t("usernameExists"));
        return;
      }
      handleError(err?.status, setError);
    }
  };

  return (
    <>
      <WorkersTable
        columns={columns}
        data={tableData}
        openDialog={handleDialogOpen}
        isLoading={usersState.isLoading}
      />
      <WorkerEditDialog
        open={dialogOpen}
        onClose={() => handleDialogClose()}
        onSubmit={(values: IWorkerData, sites?: IApiStoreEntity[]) =>
          handleDialogSubmit(values, sites)
        }
        initialData={selectedWorker}
      />
    </>
  );
};

export default AdminWorkers;
