import React from "react";
import clsx from "clsx";
import Pagination from "@material-ui/lab/Pagination";
import { useTablePaginationStyles } from "./TablePagination.styles";

interface ITablePagination {
  activePage: number;
  pages?: Array<number>;
  changePage: (event: React.ChangeEvent<unknown>, number: number) => void;
  containerStyle?: string;
}

const TablePagination: React.FC<ITablePagination> = ({
  activePage,
  pages,
  changePage,
  containerStyle,
}) => {
  const classes = useTablePaginationStyles();
  return (
    <div
      className={clsx(
        classes.paginationContainer,
        containerStyle && containerStyle
      )}
    >
      {pages && (
        <Pagination
          count={typeof pages === "object" ? pages.length : pages}
          variant="outlined"
          shape="rounded"
          onChange={changePage}
          page={activePage}
        />
      )}
    </div>
  );
};

export default TablePagination;
