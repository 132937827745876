import React, { useState, useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Button,
  Box,
} from "@material-ui/core";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import {
  Close,
  CheckCircleOutline,
  HighlightOff,
  Save,
} from "@material-ui/icons";
import SelectionTable from "ToTo/components/SelectionTable";
import { IBettingEvent, IParticipants } from "ToTo/types/BettingEvents";
import Toast from "Shared/components/utils/Toast";
import EventWinnerConfirm from "./EventWinnerConfirm";
import { useEventWinnerModalStyles } from "./EventWinnerSelect.styles";
import { postBettingEventResults } from "ToTo/actions/BettingEventsActions";
import ConfirmNobodyWons from "./ConfirmNobodyWons";

interface IEventCreateModal {
  open: boolean;
  onClose: () => void;
  event: IBettingEvent | undefined;
}

const EventWinnerSelect: React.FC<IEventCreateModal> = ({
  open,
  onClose,
  event,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const classes = useEventWinnerModalStyles();
  const [selectedWinner, setSelectedWinner] = useState<IParticipants>();
  const [nonParticipants, setNonParticipants] = useState<IParticipants[]>([]);
  const [participants, setParticipants] = useState<Array<IParticipants>>([]);
  const [confirmWindow, setConfirmWindow] = useState(false);
  const [confirmNobodyWonsOpen, setConfirmNobodyWonsOpen] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  useEffect(() => {
    if (open && !participants.length) {
      if (event?.participants) {
        setParticipants(event?.participants);
      }
    }
  }, [event?.participants, open, participants]);

  const columns = useMemo(
    () => [
      {
        Header: `${t("numberShort")}.`,
        accessor: "number",
        Cell: (cell: any) => <>{cell.value}.</>,
      },
      {
        Header: t("participant"),
        accessor: "name",
      },
      {
        Header: "",
        id: "warning",
        Cell: (tableInfo: any) =>
          selectedWinner === tableInfo.row.original && (
            <Grid container justify="flex-end">
              <CheckCircleOutline
                className={clsx(classes.checkedIcon, classes.green)}
              />
            </Grid>
          ),
      },
    ],
    [classes.checkedIcon, classes.green, selectedWinner, t]
  );

  const columnsNonParticipant = useMemo(
    () => [
      {
        Header: `${t("numberShort")}.`,
        accessor: "number",
        Cell: (cell: any) => <>{cell.value}.</>,
      },
      {
        Header: t("participant"),
        accessor: "name",
      },
      {
        Header: "",
        id: "warning",
        Cell: (tableInfo: any) =>
          nonParticipants.some(
            (rec) => rec.id === tableInfo.row.original.id
          ) ? (
            <Grid container justify="flex-end">
              <HighlightOff
                className={clsx(classes.checkedIcon, classes.red)}
              />
            </Grid>
          ) : null,
      },
    ],
    [classes.checkedIcon, classes.green, nonParticipants, t]
  );

  const onSave = () => {
    // Called when winner is selected
    if (!selectedWinner) return;
    setConfirmWindow(true);
  };

  const onNobodyWon = () => {
    setSelectedWinner(undefined);
    setNonParticipants(participants);

    (async () => {
      await handleConfirm();
    })();
  };

  const handleClose = () => {
    setSelectedWinner(undefined);
    setConfirmWindow(false);
    setParticipants([]);
    onClose();
  };

  const handleConfirm = async () => {
    // Called when winner is confirmed
    if (event?.id) {
      await dispatch(
        postBettingEventResults(
          event.id,
          selectedWinner?.id,
          nonParticipants?.map((rec) => rec.id) || []
        )
      );
    }
    setError("");
    handleClose();
  };

  useEffect(() => {
    if (nonParticipants.some((rec) => rec.id === selectedWinner?.id)) {
      setSelectedWinner(undefined);
    }
  }, [nonParticipants]);

  useEffect(() => {
    if (nonParticipants.some((rec) => rec.id === selectedWinner?.id)) {
      setNonParticipants(
        nonParticipants.filter((x) => x.id !== selectedWinner?.id)
      );
    }
  }, [selectedWinner]);

  return (
    <>
      {confirmWindow ? (
        <EventWinnerConfirm
          open={confirmWindow}
          winner={selectedWinner}
          nonParticipants={nonParticipants}
          onConfirm={handleConfirm}
          onClose={handleClose}
        />
      ) : (
        <Dialog open={open} onClose={handleClose}>
          <Close onClick={handleClose} className={classes.dialogClose} />
          <DialogContent className={classes.dialogContent}>
            {/* Winners */}
            <Box position="relative" mt={-1.5} mb={2}>
              <Typography align="center" variant="h5">
                {t("selectAWinner")}
              </Typography>
            </Box>
            {participants && (
              <SelectionTable
                selectedRows={selectedWinner}
                setSelectedRows={setSelectedWinner}
                data={participants}
                columns={columns}
              />
            )}
            {/* Losers */}
            <Typography align="center" variant="h5">
              {t("selectNonParticipants")}
            </Typography>
            {participants && (
              <SelectionTable
                selectedRows={nonParticipants}
                setSelectedRows={setNonParticipants}
                data={participants}
                columns={columnsNonParticipant}
                enableMultiselection
              />
            )}

            {/* Buttons */}
            <Box
              display="flex"
              flexDirection="column"
              gridGap={16}
              alignItems="center"
              mt={1}
            >
              <Button
                variant="outlined"
                className={clsx(classes.button, classes.redButton)}
                onClick={() => setConfirmNobodyWonsOpen(true)}
                color="primary"
                type="submit"
                disableElevation
                size="small"
              >
                <Typography variant="caption">{t("nobodyWon")}</Typography>
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                onClick={onSave}
                color="secondary"
                type="submit"
                disabled={!selectedWinner}
                disableElevation
                size="small"
              >
                <Save className={classes.saveIcon} />
                <Typography variant="caption">{t("save")}</Typography>
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {confirmNobodyWonsOpen && 
        <ConfirmNobodyWons 
          onNobodyWon={onNobodyWon}
          setConfirmNobodyWonsOpen={setConfirmNobodyWonsOpen}
        />
      }
    </>
  );
};

export default EventWinnerSelect;
