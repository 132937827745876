import React from "react";
import { Button, Typography, Box } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { CalendarTodayOutlined, ImportExport } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useAdminReportFilterStyles } from "./AdminReportsFilter.styles";
import { IAdminDateFilter } from "Shared/constants/Table";
import { exportBettingEvents } from "ToTo/actions/BettingEventsReportActions";
import { downloadFileFromBlob } from "Shared/helpers/Utils";
import { validateAndReturnFilterDate } from "Shared/helpers/Date";

interface IAdminReportsFilter {
  dateFilter?: Partial<IAdminDateFilter>;
  setDateFilter: (value: Partial<IAdminDateFilter>) => void;
  searchTerm?: string;
}

const AdminReportsFilter = ({
  dateFilter,
  setDateFilter,
  searchTerm,
}: IAdminReportsFilter) => {
  const classes = useAdminReportFilterStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDateInputChange = async (
    name: string,
    value: Date | undefined
  ) => {
    if (!value) return;
    const date = validateAndReturnFilterDate(name, value, dateFilter, true);
    if (date) {
      setDateFilter(date);
    }
  };

  const clearFilters = async () => {
    // Clears all inputs and resets filters;
    setDateFilter({ from: undefined, to: undefined });
  };

  const exportCSV = async () => {
    const response: any = await dispatch(
      exportBettingEvents({
        MinDate: dateFilter?.from
          ? format(new Date(dateFilter.from), "yyyy-MM-dd'T'HH:mm:ss")
          : undefined,
        MaxDate: dateFilter?.to
          ? format(new Date(dateFilter.to), "yyyy-MM-dd'T'HH:mm:ss")
          : undefined,
        Term: searchTerm,
      })
    );
    const filename =
      response.config?.headers?.["Content-Disposition"].split(
        "attachment;filename="
      )[1] || `${format(new Date(), "yyyy-MM-dd-HH_mm_ss")}.csv`;
    downloadFileFromBlob("text/csv", response.data, filename);
  };

  return (
    <Box display="flex" justifyContent="space-between" mb={1}>
      <Box display="flex" alignItems="center" gridGap={8}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            placeholder="yyyy-mm-dd"
            helperText=""
            inputVariant="outlined"
            InputProps={{
              className: classes.dateBox,
            }}
            size="small"
            keyboardIcon={
              <CalendarTodayOutlined className={classes.iconPrimary} />
            }
            value={dateFilter?.from || null}
            onChange={(date) =>
              handleDateInputChange("from", date || undefined)
            }
            maxDate={dateFilter?.to || new Date()}
          />
        </MuiPickersUtilsProvider>
        <Typography style={{ alignSelf: "center" }} variant="body2">
          {t("till")}
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            placeholder="yyyy-mm-dd"
            helperText=""
            inputVariant="outlined"
            InputProps={{
              className: classes.dateBox,
            }}
            size="small"
            keyboardIcon={
              <CalendarTodayOutlined className={classes.iconPrimary} />
            }
            value={dateFilter?.to || null}
            onChange={(date) => handleDateInputChange("to", date || undefined)}
            maxDate={new Date()}
          />
        </MuiPickersUtilsProvider>

        <Button
          color="primary"
          className={classes.clearFilters}
          size="small"
          variant="outlined"
          onClick={clearFilters}
          disableElevation
        >
          {t("clearFilters")}
        </Button>
      </Box>
      <Button
        color="primary"
        className={classes.exportCSV}
        size="large"
        variant="outlined"
        onClick={exportCSV}
        disableElevation
        startIcon={<ImportExport />}
      >
        {t("export")}
      </Button>
    </Box>
  );
};

export default AdminReportsFilter;
