import { makeStyles } from "@material-ui/core/styles";

export const useTicketCheckDialog = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: "center",
    paddingTop: theme.spacing(1),
    minWidth: 320,
    maxWidth: 400,
  },
  dialogContent: {
    paddingBottom: theme.spacing(2),
    borderRadius: 10,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainer: {
    minHeight: 280,
    minWidth: 440,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dialogClose: {
    marginRight: theme.spacing(2),
    marginLeft: "auto",
    marginTop: theme.spacing(2),
    color: "#000",
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    fontSize: 80,
    marginBottom: theme.spacing(1),
  },
  svg: {
    width: "100%",
    height: 80,
  },
  svgPaidOut: {
    height: 80,
  },
  iconCanceled: {
    color: "#FF9C07",
  },
  iconActive: {
    color: "#F6C272",
  },
  iconPaidOut: {
    color: "#8CBDE7",
  },
  iconLosing: {
    color: "#FF4D4D",
  },
  iconWinning: {
    color: "#0AAF60",
  },
  bold: {
    fontWeight: 600,
  },
  button: {
    width: "70%",
    minWidth: 220,
    height: 42,
    color: "#fff",
    marginTop: theme.spacing(2),
  },
  buttonCanceled: {
    backgroundColor: "#FF9C07",
  },
  buttonActive: {
    backgroundColor: "#F6C272",
  },
  buttonPaidOut: {
    backgroundColor: "#8CBDE7",
  },
  buttonLosing: {
    backgroundColor: "#FF4D4D",
  },
  buttonWinning: {
    backgroundColor: "#0AAF60",
  },
  sumCancel: {
    color: "#FF9C07",
  },
  sumWin: {
    color: "#0AAF60",
  },
  printIcon: {
    paddingRight: theme.spacing(1),
    width: 28,
    height: 28,
  },
  paddingTop: {
    paddingTop: theme.spacing(0.6),
  },
}));
