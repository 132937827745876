import { makeStyles } from "@material-ui/core/styles";

export const useWorkersTableStyles = makeStyles((theme) => ({
  iconPrimary: {
    color: theme.palette.primary.main,
  },
  tablePaper: {
    flex: 1,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    padding: theme.spacing(3),
  },
  table: {
    tableLayout: "fixed",
  },
  nameContainer: {
    display: "flex",
    justifyItems: "center",
  },
  nameText: {
    paddingTop: 3,
    paddingLeft: theme.spacing(0.5),
  },
  green: {
    color: theme.palette.primary.main,
  },
  button: {
    minHeight: 40,
    fontSize: 14,
    "&.MuiButton-outlined": {
      color: "#959595",
    },
    "&.MuiButton-outlined .MuiButton-startIcon": {
      color: theme.palette.primary.main,
    },
  },
  topControlPanel: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(1),
  },
}));
