import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import usePasswordStrengthHelpStyle from "./PasswordStrengthHelp.styles";
import PasswordCheck from "./PasswordCheck";

const PasswordStrengthHelp = ({ password, setIsPasswordValid }) => {
  const classes = usePasswordStrengthHelpStyle();
  const { t } = useTranslation();
  const [isGoodLength, setIsGoodLength] = useState(false);
  const [containSpecial, setContainSpecial] = useState(false);
  const [containsCapitalAndLower, setContainsCapitalAndLower] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);

  useEffect(() => {
    const checks = {
      length: false,
      special: false,
      capitalLetter: false,
      number: false,
    };

    if (password) {
      checks.length = password?.length > 5;
      checks.special = /[^A-Za-z 0-9]/g.test(password);
      checks.number = password.search(/\d/) >= 0;
      checks.capitalLetter =
        password.search(/[A-Z]/) >= 0 && password.search(/.*[a-z].*/) >= 0;
      if (Object.values(checks).every(Boolean)) {
        setIsPasswordValid(true);
      } else {
        setIsPasswordValid(false);
      }
    }
    setIsGoodLength(checks.length);
    setContainSpecial(checks.special);
    setContainsCapitalAndLower(checks.capitalLetter);
    setContainsNumber(checks.number);
  }, [password, setIsPasswordValid]);

  return (
    <Paper elevation={3} className={classes.passwordHelper}>
      <Typography variant="subtitle1" className={classes.title}>
        {t("passwordMustContain")}
      </Typography>
      <div className={classes.passwordRequiramentsError}>
        <PasswordCheck requirement={isGoodLength} text={t("minSymbols")} />
        <PasswordCheck
          requirement={containSpecial}
          text={t("specialCharacter")}
        />
        <PasswordCheck
          requirement={containsCapitalAndLower}
          text={t("capitalAndLowerLetter")}
        />
        <PasswordCheck
          requirement={containsNumber}
          text={t("containsNumber")}
        />
      </div>
    </Paper>
  );
};

export default PasswordStrengthHelp;
