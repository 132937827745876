import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Close } from "@material-ui/icons";
import { IApiPlayerEntity } from "../../../../Shared/types/Players";
import { useDispatch } from "react-redux";
import moment from "moment";

import { editPlayer } from "../../../actions/PlayersActions";
import { IPlayersAnalysisTableRow } from "../../../constants/Interfaces";
import { useEditPlayerDialogStyles } from "./EditPlayerDialog.style";
import Toast from "../../../../Shared/components/utils/Toast";
import { handleError } from "../../../../Shared/utils/ErrorHandler";
import AddPlayerForm from "../../cashier/players/AddPlayerForm";

interface IEditPlayerDialog {
  open: boolean;
  handleClose: () => void;
  playerToEdit?: IPlayersAnalysisTableRow;
}

const EditPlayerDialog = ({
  handleClose,
  open,
  playerToEdit,
}: IEditPlayerDialog) => {
  const [error, setError] = useState<string>();
  const classes = useEditPlayerDialogStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handlePlayerEdit = async (values: IApiPlayerEntity) => {
    if (!playerToEdit) return;
    setSubmitting(true);
    try {
      const editedPlayer: IApiPlayerEntity = {
        ...values,
        alteredManually: true,
        birthDate: moment(values.birthDate).format('YYYY-MM-DDT00:00:00.000'),
      };
      await dispatch(editPlayer(playerToEdit.playerId, editedPlayer));
      handleClose();
    } catch (e: any) {
      handleError(e?.status, setError);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Close onClick={handleClose} className={classes.dialogClose} />

      <DialogTitle disableTypography={true}>
        <Typography align="center" variant="h4">
          {t("editPlayer")}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <AddPlayerForm
          player={playerToEdit}
          submitting={submitting}
          isEditing
          onEdit={handlePlayerEdit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditPlayerDialog;
