import { makeStyles } from "@material-ui/core/styles";

export const useAdminMenuStyles = makeStyles((theme) => ({
  menu: {
    width: "100%",
  },
  subMenu: {
    position: "fixed",
    transform: `translateX(${350 + theme.spacing(2)}px) translateY(-100%)`,
    width: 200,
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "#fff",
    zIndex: theme.zIndex.drawer,
  },
  listItem: {
    textAlign: "center",
    height: 50,
    width: "100%",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  listItemActive: {
    backgroundColor: theme.palette.primary.main,
  },

  listItemText: {
    color: theme.palette.primary.main,
  },
  listItemTextActive: {
    color: "#fff",
  },

  activeItemIcon: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    color: theme.palette.primary.main,
  },

  noBottomBorder: {
    borderBottom: "none",
  },
}));
