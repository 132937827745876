import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import SkeletonField from "../../../Shared/components/utils/SkeletonField";
import { usePlayerInfoStyles } from "./PlayerInfo.styles";
import PlayerIcon from "../../../Shared/components/utils/PlayerIcon";
import {
  IApiPlayerEntity,
  PlayerDataStatus,
  TPlayerState,
} from "../../../Shared/types/Players";
import { IAppState } from "../../../Shared/store/Store";

interface IPlayerFields {
  title: string;
  dataKey: keyof IApiPlayerEntity;
}

const PlayerInfo = () => {
  const { t } = useTranslation();
  const classes = usePlayerInfoStyles();

  const playersState: TPlayerState = useSelector<IAppState, TPlayerState>(
    (state: IAppState) => state.playersState
  );

  const fields: IPlayerFields[] = [
    {
      title: t("nationalIdentificationNumber"),
      dataKey: "nationalIdentificationNumber",
    },
    { title: t("nationality"), dataKey: "citizenship" },
    { title: t("birthDate"), dataKey: "birthDate" },
  ];

  const formateValues = (dataKey: keyof IApiPlayerEntity) => {
    if (playersState.selectedPlayer) {
      if (dataKey === "birthDate") {
        return playersState.selectedPlayer.birthDate?.split("T")[0];
      }
      return playersState.selectedPlayer[dataKey];
    }
    return "";
  };

  return (
    <div className={classes.container}>
      <div className={classes.playerFields}>
        {playersState.selectedPlayer ? (
          <>
            {/* Name */}
            <Typography
              align="center"
              variant="h5"
              className={clsx(
                classes.name,
                (playersState.selectedPlayer.gamblingEligibility !==
                  PlayerDataStatus.Allowed &&
                 playersState.selectedPlayer.gamblingEligibility !==
                  PlayerDataStatus.Inconclusive) && classes.blocked,
                playersState.selectedPlayer.gamblingEligibility ===
                  PlayerDataStatus.Inconclusive && classes.unknown
              )}
            >
              {(playersState.selectedPlayer as any).playerName ||
                `${playersState.selectedPlayer.firstName} ${playersState.selectedPlayer.lastName}`}
            </Typography>
            {fields.map((field, i) => (
              <>
                <Typography key={i} variant="subtitle1">
                  <b>{field.title}</b>
                </Typography>
                <Typography key={field.dataKey} variant="body1">
                  {formateValues(field.dataKey)}
                </Typography>
              </>
            ))}
          </>
        ) : (
          <>
            <SkeletonField className={classes.name} />
            <SkeletonField styles={{ width: "95px" }} />
            <SkeletonField />
            <SkeletonField styles={{ width: "80px" }} />
            <SkeletonField />
            <SkeletonField styles={{ width: "50px" }} />
            <SkeletonField />
          </>
        )}
      </div>

      <PlayerIcon
        player={playersState.selectedPlayer}
        playerFindLoading={playersState.isLoadingPlayerFind}
      />
    </div>
  );
};

export default PlayerInfo;
