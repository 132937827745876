import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { useAddPlayerStatusStyles } from "./AddPlayerStatus.styles";
import { PlayerDataStatus } from "../../../../Shared/types/Players";
import clsx from "clsx";
import PlayerIcon from "../../../../Shared/components/utils/PlayerIcon";
import { IPlayersTableRow } from "../../../constants/Interfaces";
import PlayerDataRows from "./PlayerDataRows";

interface IAddPlayerStatus {
  player: Partial<IPlayersTableRow> | undefined;
  onClick: (player: Partial<IPlayersTableRow>) => void;
}

const AddPlayerStatus: React.FC<IAddPlayerStatus> = ({ player, onClick }) => {
  const classes = useAddPlayerStatusStyles();
  if (!player) return <></>;

  return (
    <Card
      variant="outlined"
      onClick={() => player && onClick(player)}
      className={clsx(
        classes.card,
        player.gamblingEligibility === PlayerDataStatus.Allowed && classes.greenBorder,
        (player.gamblingEligibility !== PlayerDataStatus.Allowed &&
         player.gamblingEligibility !== PlayerDataStatus.Inconclusive) && classes.redBorder
      )}
    >
      <CardContent className={classes.cardContent}>
        <Typography
          variant="h5"
          align="center"
          className={clsx(
            classes.fullWidth,
            player.gamblingEligibility === PlayerDataStatus.Inconclusive && classes.orangeText,
            (player.gamblingEligibility !== PlayerDataStatus.Allowed &&
             player.gamblingEligibility !== PlayerDataStatus.Inconclusive) && classes.redText
          )}
        >
          {player.name}
        </Typography>

        <PlayerDataRows player={player} />

        <PlayerIcon player={player} className={classes.playerIcon} />
      </CardContent>
    </Card>
  );
};

export default AddPlayerStatus;
