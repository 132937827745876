import React from "react";
import { Typography } from "@material-ui/core";
import { useTableMoneyCellStyles } from "./TableMoneyCell.styles";

interface ITableMoneyCell {
  sum: number;
}

const TableMoneyCell: React.FC<ITableMoneyCell> = ({ sum }) => {
  const classes = useTableMoneyCellStyles();

  return (
    <div className={sum > 15000 ? classes.over15000 : ""}>
      <Typography className={classes.small}>{!sum ? "" : parseFloat(sum.toString()).toFixed(2)}</Typography>
    </div>
  );
};
export default TableMoneyCell;
