import React from "react";
import { Field } from "react-final-form";
import {
  Select,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { useFormSelectStyles } from "./FormSelect.styles";
import clsx from "clsx";

interface IFormSelect {
  id: string;
  name: string;
  options: any;
  variant?: "standard" | "outlined" | "filled" | undefined;
  required?: boolean;
  selectClass?: string;
  height?: string;
  fullWidth?: boolean;
  label?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  type?: string;
  className?: string;
  optionClass?: string;
  optionValue: string;
  optionDisplay: string;
}

export const FormSelect: React.FC<IFormSelect> = ({
  id,
  name,
  options,
  optionValue,
  optionDisplay,
  variant,
  required,
  selectClass,
  height,
  fullWidth,
  label,
  autoComplete,
  autoFocus,
  type,
  className,
  optionClass,
}) => {
  const classes = useFormSelectStyles();
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <FormControl
          required={required}
          error={!!meta.touched && meta.error}
          variant={variant}
          className={className}
          fullWidth={fullWidth}
        >
          {label && <InputLabel>{label}</InputLabel>}
          <Select
            id={id}
            label={label}
            style={{ height: height }}
            className={clsx(selectClass, classes.selection)}
            inputProps={{
              classes: {
                icon: classes.arrowIcon,
              },
            }}
            displayEmpty
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            type={type}
            {...input}
          >
            {options.map((opt: any) => (
              <MenuItem className={optionClass} key={opt[optionValue]} value={opt[optionValue]}>
                {opt[optionDisplay] || "-"}
              </MenuItem>
            ))}
          </Select>
          {!!meta.error && meta.touched && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default FormSelect;
