import { makeStyles } from "@material-ui/core/styles";

export const useTableMoneyCellStyles = makeStyles((theme) => ({
  over15000: {
    backgroundColor: "#FF9C07",
    color: "#FFF",
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.2),
    borderRadius: 10,
  },
  small: {
    fontSize: 12,
  },
}));
