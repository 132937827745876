import React from "react";
import { Typography, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PageHeader from "Shared/components/PageHeader";
import { useAdminLogReportsStyles } from "./AdminLogReports.styles";
import LogReportsTableBlock from "ToTo/components/admin/logReports/LogReportsTableBlock";

const ToToAdminLogReports: React.FC = () => {
  const classes = useAdminLogReportsStyles();
  const { t } = useTranslation();

  return (
    <>
      <PageHeader
        leftSide={
          <Typography variant="h5">
            <b>{t("logReport")}</b>
          </Typography>
        }
      />
      <Paper elevation={3} className={classes.paper}>
        <LogReportsTableBlock />
      </Paper>
    </>
  );
};

export default ToToAdminLogReports;
