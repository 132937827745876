import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { useHeaderInputsStyles } from "./HeaderInputs.styles";

interface IHeaderInputs {
  setBetModalOpen: (open: boolean) => void;
  inputPlaceHolder: string;
  inputId: string;
  firstButtonLabel: string;
  secondButtonLabel: string;
  inputValue: string | undefined;
  inputChange: (value: string) => void;
  handleFirstButton: () => void;
}

const HeaderInputs: React.FC<IHeaderInputs> = ({
  setBetModalOpen,
  inputPlaceHolder,
  inputId,
  firstButtonLabel,
  secondButtonLabel,
  inputValue,
  inputChange,
  handleFirstButton,
}) => {
  const classes = useHeaderInputsStyles();

  return (
    <Grid container alignItems="center">
      <TextField
        className={classes.smallInput}
        id={inputId}
        type="text"
        placeholder={inputPlaceHolder}
        size="small"
        variant="outlined"
        value={inputValue || ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          inputChange(event.target.value)
        }
        InputProps={{
          classes: {
            input: classes.inputPlaceholder,
          },
        }}
      />
      <Button
        className={classes.smallButton}
        disableElevation
        color="primary"
        variant="contained"
        size="small"
        onClick={handleFirstButton}
      >
        {firstButtonLabel}
      </Button>
      <Button
        className={classes.smallButton}
        disableElevation
        color="primary"
        variant="contained"
        size="small"
        onClick={() => setBetModalOpen(true)}
      >
        {secondButtonLabel}
      </Button>
    </Grid>
  );
};

export default HeaderInputs;
