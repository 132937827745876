import React from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Box, Grid, Button } from "@material-ui/core";
import { ReactComponent as Logo } from "../assets/icons/logo.svg";
import { useLoginStyles } from "./Login.styles";
import { setCurrentApp } from "../../Shared/actions/AppActions";
import { APPLICATIONS } from "../constants/Applications";
import { APPLICATION_ITEM } from "../constants/Storage";
import { ROUTES } from "../constants/Routes";
import { ROUTES as GAMING_ROUTES } from "../../Gaming/constants/Routes";

const Login: React.FC = () => {
  const classes = useLoginStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const onAppSelect = async (app: APPLICATIONS) => {
    dispatch(setCurrentApp(app));
    localStorage.setItem(APPLICATION_ITEM, app);
    if (app === APPLICATIONS.PerlasGaming) {
      history.push(GAMING_ROUTES.ADMIN.PLAYERS);
    } else {
      history.push(ROUTES.ADMIN.MAIN);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.leftSideContainer}>
        <div className={classes.logoContainer}>
          <Logo />
        </div>
      </div>
      <div className={classes.rightSideContainer}>
        <Box mx={3} width={400}>
          <Typography className={classes.title} variant="h4">
            {`${t("selectSystem")}:`}
          </Typography>
          <Grid container justify="center">
            <Button
              variant="outlined"
              color="primary"
              disableElevation
              onClick={() => onAppSelect(APPLICATIONS.PerlasGaming)}
              className={clsx(classes.bigButton, classes.greenButton)}
            >
              <Typography variant="h5" className={classes.disableUppercase}>
                ID Check
              </Typography>
            </Button>
            <Button
              variant="outlined"
              disableElevation
              onClick={() => onAppSelect(APPLICATIONS.ToTo)}
              className={clsx(classes.bigButton, classes.grayButton)}
            >
              <Typography variant="h5" className={classes.disableUppercase}>
                ToTo
              </Typography>
            </Button>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Login;
