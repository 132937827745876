import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { IAppState } from "../store/Store";
import userManager from "../helpers/userManagement";
import { RoleDescription } from "../constants/Roles";
import { ROUTES } from "../constants/Routes";

interface IPrivaterRoute {
  roles?: Array<string>;
  component: React.ElementType;
  exact?: boolean;
  path: string | string[];
}

const matchRole = (userRoles: string, neededRoles: Array<string>) =>
  neededRoles.indexOf(userRoles) > -1 ? true : false;

const PrivateRoute: React.FC<IPrivaterRoute> = ({
  component: Component,
  roles,
  exact,
  path,
  ...rest
}) => {
  const history = useHistory();
  const oidc: any = useSelector<IAppState, any>(
    (state: IAppState) => state.oidc
  );

  if (history.location.pathname !== "/callback") {
    localStorage.setItem("lastRoute", history.location.pathname);
  }

  if (!oidc.user) {
    userManager.signinRedirect();
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (roles && !matchRole(oidc.user.profile.role, roles)) {
          // role not authorised so redirect to home page
          if (oidc.user.profile.role === RoleDescription.Admin) {
            return <Redirect to={{ pathname: ROUTES.ADMIN.MAIN }} />;
          } else {
            return <Redirect to={{ pathname: "/" }} />;
          }
        }

        if (oidc.user.expires_at * 1000 < new Date().getTime()) {
          userManager.signoutRedirect();
        }
        // authorised so return component
        return <Component {...props} exact={exact} path={path} {...rest} />;
      }}
    />
  );
};

export default PrivateRoute;
