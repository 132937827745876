import { makeStyles } from "@material-ui/core/styles";

export const useLoginStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  logoContainer: {
    width: 270,
  },
  leftSideContainer: {
    backgroundImage: "url(/footballPlayers.svg)",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "50%",
    height: "100%",
  },
  rightSideContainer: {
    width: "50%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  bigButton: {
    borderRadius: 15,
    height: 100,
    width: "100%",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
  },
  greenButton: {
    color: theme.palette.primary.main,
  },
  grayButton: {
    marginTop: theme.spacing(2.5),
    color: "#303030",
    borderColor: "#000000",
  },
  disableUppercase: {
    textTransform: "none",
  },
  title: {
    fontSize: 30,
    marginBottom: theme.spacing(5),
  },
}));
