import React from "react";
import { useAdminMainStyles } from "./AdminMain.styles";
import UserTab from "../../../Shared/components/UserTab";
import AdminPanel from "../../components/admin/AdminPanel";
import AdminRouter from "../../router/AdminRouter";

const AdminMain: React.FC = () => {
  const classes = useAdminMainStyles();

  return (
    <div className={classes.root}>
      <div className={classes.leftSideContainer}>
        <UserTab hideSites />
        <AdminPanel />
      </div>
      <div className={classes.rightSideContainer}>
        <AdminRouter />
      </div>
    </div>
  );
};

export default AdminMain;
