import i18n from "../config/i18next";

export const handleError = (
  status: number,
  setError: (error: string) => void,
  genericError?: string
) => {
  switch (status) {
    case 401:
      setError(i18n.t("unauthorized"));
      break;

    case 403:
      setError(i18n.t("forbidden"));
      break;

    case 404:
      setError(i18n.t("notFound"));
      break;

    case 500:
      setError(i18n.t("internal"));
      break;
    default:
      return setError(genericError || i18n.t("genericError"));
  }
};
