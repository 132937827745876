import {
  TAnalysisActions,
  GetAnalysisPlayers,
  GetAnalysisStats,
  IApiAnalysisPlayersEntity,
  IAnalysisStats,
} from "../../Shared/types/Analysis";
import { apiCall } from "../../Shared/actions/BaseAction";
import { API_END_POINTS } from "../constants/Api";
import { PAGE_LIMIT } from "../../Shared/constants/Table";

export const getAnalysisPlayersList = (
  page: number,
  storeId?: number,
  searchQuery?: string,
  minDate?: Date,
  maxDate?: Date,
  minSumPerShift?: number,
  currentShiftOnly?: boolean
) => {
  const limit = PAGE_LIMIT;
  return apiCall<TAnalysisActions, null, Array<IApiAnalysisPlayersEntity>>(
    GetAnalysisPlayers,
    "GET",
    API_END_POINTS.ANALYSIS,
    true,
    null,
    {},
    {
      limit,
      Term: searchQuery || undefined,
      MinDate: minDate || undefined,
      MaxDate: maxDate || undefined,
      PageNumber: page || undefined,
      CurrentShiftOnly: currentShiftOnly || undefined,
      StoreId: storeId || undefined,
      MinSumPerShift: minSumPerShift || undefined,
    },
    "json",
    {
      page,
      searchQuery,
    }
  );
};

export const getAnalysisStats = (
  storeId?: number,
  searchQuery?: string,
  minDate?: Date,
  maxDate?: Date,
  minSumPerShift?: number,
  currentShiftOnly?: boolean
) => {
  const limit = PAGE_LIMIT;
  return apiCall<TAnalysisActions, null, IAnalysisStats>(
    GetAnalysisStats,
    "GET",
    API_END_POINTS.ANALYSIS_STATS,
    true,
    null,
    {},
    {
      limit,
      Term: searchQuery || undefined,
      MinDate: minDate || undefined,
      MaxDate: maxDate || undefined,
      CurrentShiftOnly: currentShiftOnly || undefined,
      StoreId: storeId || undefined,
      MinSumPerShift: minSumPerShift || undefined,
    },
  );
};