import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { Typography, Paper, Grid, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PageHeader, { BoldText, GreenText } from "Shared/components/PageHeader";
import AdminReportsWrapper from "ToTo/components/admin/reports/AdminReportsWrapper";
import { useAdminReportsStyles } from "./AdminReports.styles";
import { IAdminDateFilter } from "Shared/constants/Table";
import { getReportsStats } from "ToTo/actions/BettingEventsReportActions";
import { handleError } from "Shared/utils/ErrorHandler";
import Toast from "Shared/components/utils/Toast";
import { TBettingEventsReportState } from "ToTo/types/BettingEvents";
import { IAppState } from "Shared/store/Store";
import { TStoreState } from "Shared/types/Stores";

const ToToAdminReports: React.FC = () => {
  const classes = useAdminReportsStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number | null>(null);
  const [dateFilter, setDateFilter] = useState<Partial<IAdminDateFilter>>();

  const reportState: TBettingEventsReportState = useSelector<
    IAppState,
    TBettingEventsReportState
  >((state: IAppState) => state.bettingEventsReportState);

  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );

  useEffect(() => {
    if (currentPage === null) {
      setCurrentPage(1);
      return;
    }

    (async () => {
      try {
        if (
          !storesState?.selectedStore?.id &&
          storesState?.selectedStore?.id !== undefined
        ) {
          return;
        }
        await dispatch(
          getReportsStats({
            MinDate: dateFilter?.from
              ? format(new Date(dateFilter.from), "yyyy-MM-dd'T'HH:mm:ss")
              : undefined,
            MaxDate: dateFilter?.to
              ? format(new Date(dateFilter.to), "yyyy-MM-dd'T'HH:mm:ss")
              : undefined,
            PageNumber: currentPage,
            Term: searchTerm,
            StoreId: storesState?.selectedStore?.id,
          })
        );
      } catch (err: any) {
        handleError(err?.status, setError);
      }
    })();
  }, [
    currentPage,
    searchTerm,
    dateFilter,
    dispatch,
    storesState?.selectedStore?.id,
  ]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  return (
    <>
      <PageHeader
        leftSide={
          <Typography variant="h5">
            <b>{t("eventsReport")}</b>
          </Typography>
        }
        rightSide={
          <Grid container alignItems="baseline">
            <GreenText display="inline">{t("totalBets")}: </GreenText>
            <BoldText display="inline">
              {reportState.reportsStatsLoading ? (
                <CircularProgress size={15} color="primary" />
              ) : (
                `${reportState.reportsStats?.totalInAmount || 0} eur`
              )}
            </BoldText>

            <GreenText display="inline">{t("totalPayouts")}: </GreenText>
            <BoldText display="inline">
              {reportState.reportsStatsLoading ? (
                <CircularProgress size={15} color="primary" />
              ) : (
                `${reportState.reportsStats?.totalOutAmount || 0} eur`
              )}
            </BoldText>
          </Grid>
        }
      />

      <Paper elevation={3} className={classes.paper}>
        <AdminReportsWrapper
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
        />
      </Paper>
    </>
  );
};

export default ToToAdminReports;
