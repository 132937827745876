import React from "react";
import { useTable, Column, TableInstance, useGlobalFilter } from "react-table";
import {
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  Box,
} from "@material-ui/core";
import Loading from "./utils/Loading";
import { useTableSimpleStyles } from "./TableSimple.styles";
import { IBettingEventTicketActionModal } from "ToTo/types/BettingEventTicketActions";

type Data = object;

interface ITableSimple {
  columns: Column<Data>[];
  data: Array<IBettingEventTicketActionModal>;
  isLoading?: boolean;
}

const TableSimple: React.FC<ITableSimple> = ({ columns, data, isLoading }) => {
  const classes = useTableSimpleStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<Data>(
    {
      columns,
      data,
    },
    useGlobalFilter
  ) as TableInstance<object>;

  return (
    <>
      <Table {...getTableProps()} stickyHeader className={classes.table}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {!isLoading && (
          <TableBody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} className={classes.row}>
                  {row.cells.map((cell: any) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                            {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ height: "70%", width: "100%" }}
        >
          <Loading width={100} />
        </Box>
      )}
    </>
  );
};

export default TableSimple;
