import {
  initialBettingEventState,
  TBettingEventTicketState,
  BettingEventTicketCancel,
  TBettingEventTicketActions,
  BettingEventTicketPost,
  BettingEventTicketCheck,
  BettingEventTicketStatusChange,
  BettingEventTicketExtend
} from "../types/BettingEventTickets";
import { Reducer } from "redux";

export const bettingEventTicketReducer: Reducer<
  TBettingEventTicketState,
  TBettingEventTicketActions
> = (state = initialBettingEventState, action) => {
  switch (action.type) {
    case BettingEventTicketCancel.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case BettingEventTicketExtend.SUCCESS: {
      return {
        ...state,
        isLoadingExpand: false,
      }
    }
    case BettingEventTicketStatusChange.SUCCESS:
    case BettingEventTicketPost.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case BettingEventTicketCheck.SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    }
    case BettingEventTicketExtend.FAILED: {
      return {
        ...state,
        isLoadingExpand: false,
      }
    }
    case BettingEventTicketStatusChange.FAILED:
    case BettingEventTicketCheck.FAILED:
    case BettingEventTicketPost.FAILED:
    case BettingEventTicketCancel.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case BettingEventTicketExtend.REQUEST: {
      return {
        ...state,
        isLoadingExpand: true,
      }
    }
    case BettingEventTicketStatusChange.REQUEST:
    case BettingEventTicketCheck.REQUEST:
    case BettingEventTicketPost.REQUEST:
    case BettingEventTicketCancel.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default: {
      return state;
    }
  }
};
