import { makeStyles } from "@material-ui/core/styles";

export const usePlayerFormStyles = makeStyles((theme) => ({
  root: {},
  container: {
    display: "flex",
    flexDirection: "column",
    gridColumnGap: theme.spacing(1),
  },
  error: {
    color: "red",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1.5),
    minHeight: 50,
    "&.MuiButton-containedPrimary ": {
      color: "white",
    },
    "&.MuiButton-label": {
      display: "flex",
      alignItems: "end",
      fontSize: 14,
    },
  },
  fullWidth: {
    gridColumn: "auto / span 2",
    marginBottom: theme.spacing(0.5),
  },
}));
