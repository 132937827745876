export const API_END_POINTS = {
  ADMIN_EVENT: "/bettingEvents",
  ADMIN_EVENT_POST: "/bettingEvents",
  ADMIN_EVENT_CLOSE: (bettingEventId: number) =>
    `/bettingEvents/${bettingEventId}/close`,
  ADMIN_EVENT_ENTER_RESULTS: (bettingEventId: number) =>
    `/bettingEvents/${bettingEventId}/enterResults`,
  BETTING_EVENT_CANCEL: (bettingEventId: number) =>
    `/bettingEvents/${bettingEventId}/cancel`,
  ADMIN_EVENTS_REPORT: "/bettingEvents/report",
  ADMIN_EVENTS_EXPORT: "/bettingEvents/export",
  //tickets
  BETTING_EVENT_TICKET_CANCEL: (ticketId: string) =>
    `/bettingEventTickets/${ticketId}/cancel`,
  BETTING_EVENT_TICKET_POST: (id: number) => `/bettingEvents/${id}/tickets`,
  BETTING_EVENT_TICKET_CHECK: (ticketId: string) =>
    `/bettingEventTickets/${ticketId}/get`,
  BETTING_EVENT_TICKET_REFUND: (ticketNumber: string, storeId: number) => `/bettingEventTickets/store/${storeId}/ticket/${ticketNumber}/refund`,
  BETTING_EVENT_TICKET_PAYOUT: (ticketNumber: string, storeId: number) => `/bettingEventTickets/store/${storeId}/ticket/${ticketNumber}/payout`,
  BETTING_EVENT_TICKET_NOT_WON: (ticketNumber: string, storeId: number) => `/bettingEventTickets/store/${storeId}/ticket/${ticketNumber}/didNotWin`, 
  RESERVE_FUNDS_GET: "/reserveFund",
  REPORT_STATISTICS: "/bettingEvents/reportStatistics",
  BETTING_EVENT_TICKET_ACTIONS: '/bettingEventTicketActions',
  EXTEND_EXPIRED_TICKET: (ticketId: string) =>
  `/bettingEventTickets/${ticketId}/extendExpired`,
};
