import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import { useRouteMatch, useHistory } from "react-router-dom";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import { ROUTES } from "../../constants/Routes";
import { useAdminMenuStyles } from "./AdminMenu.styles";
import { useTranslation } from "react-i18next";

const AdminMenu: React.FC = () => {
  const classes = useAdminMenuStyles();
  const [adminSub, showAdminSub] = useState(false);
  const subMenu = useRef<any>();
  const history = useHistory();
  const { t } = useTranslation();

  const isViewingPlayers = useRouteMatch([
    ROUTES.ADMIN.PLAYER,
    ROUTES.ADMIN.PLAYERS,
    ROUTES.ADMIN.MAIN,
    "/",
  ]);

  const handleClickOutside = (e: any) => {
    if (!subMenu.current?.contains(e.target)) {
      if (subMenu.current) {
        showAdminSub(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const submenuItems: { title: string; route: string }[] = [
    { title: t("employees"), route: ROUTES.ADMIN.WORKERS },
  ];

  return (
    <List className={classes.menu}>
      <ListItem
        button
        className={classes.listItem}
        onClick={() => history.push(ROUTES.ADMIN.PLAYERS)}
      >
        <ListItemText className={classes.listItemText}>
          {t("playersAnalysis")}
        </ListItemText>
        {isViewingPlayers?.isExact && (
          <ListItemIcon className={classes.activeItemIcon}>
            <KeyboardArrowRight />
          </ListItemIcon>
        )}
      </ListItem>
      <ListItem
        button
        className={classes.listItem}
        onClick={() => showAdminSub(!adminSub)}
      >
        <ListItemText className={classes.listItemText}>
          {t("administration")}
        </ListItemText>
        {[ROUTES.ADMIN.WORKERS, ROUTES.ADMIN.SETTINGS].includes(
          history.location.pathname
        ) && (
          <ListItemIcon className={classes.activeItemIcon}>
            <KeyboardArrowRight />
          </ListItemIcon>
        )}
      </ListItem>
      {adminSub && (
        <div className={classes.subMenu} ref={subMenu}>
          {submenuItems.map((item, i) => (
            <ListItem
              key={item.route}
              button
              className={clsx(
                classes.listItem,
                history.location.pathname === item.route &&
                  classes.listItemActive,
                i === submenuItems.length - 1 && classes.noBottomBorder
              )}
              onClick={() => {
                showAdminSub(false);
                history.push(item.route);
              }}
            >
              <ListItemText
                className={clsx(
                  classes.listItemText,
                  history.location.pathname === item.route &&
                    classes.listItemTextActive
                )}
              >
                {item.title}
              </ListItemText>
            </ListItem>
          ))}
        </div>
      )}
    </List>
  );
};

export default AdminMenu;
