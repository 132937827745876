import { makeStyles } from "@material-ui/core/styles";

export const usePlayerPossibleFalseAlarmStyles = makeStyles((theme) => ({
    dialogTitle: {
      textAlign: "center",
      fontSize: "32px",
    },
    dialogContent: {
      minWidth: 430,
      paddingBottom: theme.spacing(2),
      paddingTop: 0,
      borderRadius: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    dialogClose: {
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: "#CECECE",
      fontSize: "12px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    transactionTitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    transactionRows: {
      display: "grid",
      gridColumnGap: theme.spacing(1),
      minWidth: 230,
      maxWidth: 430,
    },
    error: {
      paddingTop: theme.spacing(1),
      color: "red",
    },
  }));
