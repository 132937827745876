import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { format, isToday, addDays } from "date-fns";
import { Link, Tooltip, Typography, Paper } from "@material-ui/core";
import { Info, Edit } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import PlayersTable from "../../components/admin/players/PlayersTable";
import { IAppState } from "../../../Shared/store/Store";
import {
  PlayersTableKeys,
  IPlayersAnalysisTableRow,
  AdminFilterStorage,
  IAdminMoneyFilter,
} from "../../constants/Interfaces";
import { IAdminDateFilter } from "Shared/constants/Table";
import { IApiPlayerEntity, TPlayerState } from "../../../Shared/types/Players";
import { getPlayersList } from "../../actions/PlayersActions";
import { ROUTES } from "../../constants/Routes";
import { getAnalysisPlayersList } from "../../actions/AnalysisActions";
import {
  IApiAnalysisPlayersEntity,
  TAnalysisState,
} from "../../../Shared/types/Analysis";
import { useAdminPlayersStyles } from "./AdminPlayers.styles";
import Toast from "../../../Shared/components/utils/Toast";
import { handleError } from "../../../Shared/utils/ErrorHandler";
import { changeCurrentPlayer } from "../../actions/PlayersActions";
import { getPageCount } from "../../../Shared/helpers/Utils";
import AdminTableFilters from "../../components/admin/players/tableHelpers/AdminTableFilters";
import TableMoneyCell from "../../components/admin/players/tableHelpers/TableMoneyCell";
import HeaderWrapper from "../../components/admin/adminPlayers/HeaderWrapper";
import EditPlayerDialog from "../../components/admin/adminPlayers/EditPlayerDialog";
import { STORAGE_ITEMS } from "Gaming/constants/main.constants";

const formateTableData = (
  players: Array<IApiPlayerEntity>,
  analysis: Array<IApiAnalysisPlayersEntity>
) => {
  const rowsData: IPlayersAnalysisTableRow[] = [];

  for (let i = 0; i < analysis.length; i++) {
    const analysisPlayer = analysis[i];
    if (!analysisPlayer) continue;

    const people = players.find((rec) => rec.id === analysisPlayer.playerId);
    rowsData.push({
      ...analysisPlayer,
      ...people,
      name:
        `${people?.firstName} ${people?.lastName}` ||
        analysisPlayer?.playerName,
      firstName: people?.firstName || analysisPlayer.playerName.split(" ")[0],
      lastName: people?.lastName || analysisPlayer.playerName.split(" ")[1],
      birthDate: people?.birthDate,
      citizenship: people?.citizenship,
      nationalIdentificationNumber: people?.nationalIdentificationNumber,
      latestTransactionInStore: analysisPlayer.latestTransactionInStore
    });
  }
  return rowsData;
};

const AdminPlayers = () => {
  const classes = useAdminPlayersStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  // States
  const [data, setData] = useState<IPlayersAnalysisTableRow[]>([]);
  const [pages, setPages] = useState<Array<number>>();
  const [error, setError] = useState<string>();
  const [playerDialog, setPlayerDialog] = useState<boolean>(false);
  const [playerToEdit, setPlayerToEdit] = useState<IPlayersAnalysisTableRow>();

  const storageMoney = localStorage.getItem(AdminFilterStorage.Money);
  const [moneyFilter, setMoneyFilter] = useState<IAdminMoneyFilter>(
    storageMoney ? JSON.parse(storageMoney) : { "10kSum": false, "15k": false }
  );
  // Redux states
  const playersState: TPlayerState = useSelector<IAppState, TPlayerState>(
    (state: IAppState) => state.playersState
  );
  const analysisState: TAnalysisState = useSelector<IAppState, TAnalysisState>(
    (state: IAppState) => state.analysisState
  );

  //table filter state
  const [storeFilter, setStoreFilter] = useState<number>(
    Number(localStorage.getItem(AdminFilterStorage.Site))
  );
  const [searchQuery, setSearchQuery] = useState<string | undefined>(
    analysisState.searchQuery
  );

  const storageDate = localStorage.getItem(AdminFilterStorage.Date);
  const today = new Date();
  const [dateFilter, setDateFilter] = useState<Partial<IAdminDateFilter>>(
    storageDate
      ? JSON.parse(storageDate)
      : {
          from: new Date(
            new Date(today.getFullYear(), today.getMonth(), 1).setHours(
              10,
              0,
              0
            )
          ),
          to: "",
        }
  );

  // fetch data for initial load and when changing filter
  useEffect(() => {
    (async () => {
      try {
        await dispatch(
          getAnalysisPlayersList(
            analysisState.page || 1,
            storeFilter || undefined,
            searchQuery,
            dateFilter.from as Date,
            dateFilter.to as Date,
            moneyFilter["1k"] ? 1000 : moneyFilter["15k"] ? 15000 : undefined
          )
        );
      } catch (err: any) {
        handleError(err?.status, setError);
      }
    })();
  }, [
    dateFilter.from,
    dateFilter.to,
    dispatch,
    moneyFilter,
    storeFilter,
    searchQuery,
    analysisState.page,
  ]);

  useEffect(() => {
    (async () => {
      if (!playersState.data) {
        try {
          await dispatch(getPlayersList());
        } catch (err: any) {
          handleError(err?.status, setError);
        }
      }
    })();
  }, [dispatch, playersState.data]);

  //formate data for table
  useEffect(() => {
    if (playersState.data && analysisState.data) {
      const formedTableData = formateTableData(
        playersState.data,
        analysisState.data
      );
      setData(formedTableData);
      if (analysisState.totalPages != null) {
        getPageCount(analysisState.totalPages, setPages);
      }
    }
  }, [analysisState.data, analysisState.totalPages, playersState.data]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  const handlePlayerEdit = (player: IPlayersAnalysisTableRow) => {
    setPlayerDialog(true);
    setPlayerToEdit(player);
  };

  const columns = [
    {
      Header: `${t("name")}`,
      accessor: PlayersTableKeys.name,
      Cell: (cell: any) => (
        <Typography className={classes.big}>{cell.value}</Typography>
      ),
    },
    {
      Header: t("bidSum"),
      accessor: PlayersTableKeys.totalShiftInAmountSum,
      Cell: (cell: any) => <TableMoneyCell sum={cell.value} />,
    },
    {
      Header: t("cashInSum"),
      accessor: PlayersTableKeys.totalShiftOutAmountSum,
      Cell: (cell: any) => <TableMoneyCell sum={cell.value} />,
    },
    {
      Header: `${t("shift")}`,
      accessor: PlayersTableKeys.shift,
      Cell: (cell: any) => {
        const from = new Date(cell.value);
        const to = addDays(from, 1);

        return (
          <Typography className={classes.small}>{`${format(
            from,
            "MM-dd"
          )}/${format(to, "MM-dd")}`}</Typography>
        );
      },
    },
    {
      Header: `${t("lastChange")}`,
      accessor: PlayersTableKeys.latestTransactionInStore,
      Cell: (cell: any) => {
        const date = cell.value.endsWith("Z")
          ? new Date(cell.value.slice(0, -1))
          : new Date(cell.value);
        return (
          <Typography className={classes.small}>
            {isToday(date)
              ? format(date, "HH:mm:ss")
              : format(date, "yyyy-MM-dd HH:mm:ss")}
          </Typography>
        );
      },
    },
    {
      Header: `${t("lastSite")}`,
      accessor: PlayersTableKeys.storeName,
      Cell: (cell: any) => (
        <Typography className={classes.small}>{cell.value}</Typography>
      ),
    },
    {
      Header: "",
      id: "warning",
      Cell: (tableInfo: any) => (
        <div className={classes.toolText}>
          {
            <Tooltip title={`${t("edit")}`}>
              <Edit
                className={classes.green}
                onClick={() => handlePlayerEdit(tableInfo.row.original)}
              />
            </Tooltip>          
          }
          <Link
            className={clsx(classes.small, classes.green, classes.moreLink)}
            onClick={() => {
              history.push(
                `${ROUTES.ADMIN.PLAYER_NO_ID}/${tableInfo.row.original.id}`
              );
              localStorage.setItem(
                STORAGE_ITEMS.ADMIN_PLAYERS_FILTERS.SELECTED_SHIFT,
                tableInfo.row.original.shift
              );
              dispatch(changeCurrentPlayer(tableInfo.row.original));
            }}
          >
            {t("review")}
          </Link>
          <Tooltip title={`${t("info")}`}>
            <Info
              className={classes.green}
              onClick={() =>
                dispatch(changeCurrentPlayer(tableInfo.row.original))
              }
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const changePage = async (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    try {
      await dispatch(
        getAnalysisPlayersList(
          newPage,
          storeFilter || undefined,
          searchQuery,
          dateFilter.from as Date,
          dateFilter.to as Date,
          moneyFilter["1k"] ? 1000 : moneyFilter["15k"] ? 15000 : undefined
        )
      );
    } catch (err: any) {
      handleError(err?.status, setError);
    }
  };

  return (
    <>
      <HeaderWrapper />
      <Paper elevation={3} className={classes.tablePaper}>
        <AdminTableFilters
          storeFilter={storeFilter}
          setStoreFilter={setStoreFilter}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          moneyFilter={moneyFilter}
          setMoneyFilter={setMoneyFilter}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <PlayersTable
          columns={columns}
          data={data}
          activePage={analysisState.page || 1}
          pages={pages}
          changePage={changePage}
          isLoading={playersState.isLoading || analysisState.isLoading}
        />
      </Paper>
      <EditPlayerDialog
        open={playerDialog}
        handleClose={() => setPlayerDialog(false)}
        playerToEdit={playerToEdit}
      />
    </>
  );
};

export default AdminPlayers;
