import { AxiosError, AxiosResponse } from "axios";
import { IEventParticipant } from "../constants/Interfaces";

export interface IBettingEventTicket {
  content: any;
}

export interface TBettingEventState {
  readonly data?: IBettingEventTicket;
  readonly isLoading?: boolean;
  readonly eventsTableData?: Array<IBettingEvent>;
  readonly reserveFunds?: number;
  readonly isLoadingFunds?: boolean;
}

export interface TBettingEventsReportState {
  readonly hasNext?: boolean;
  readonly hasPrevious?: boolean;
  readonly pageSize?: number;
  readonly totalCount?: number;
  readonly totalPages?: number;
  readonly data?: IBettingEvent[];
  readonly isLoading?: boolean;
  readonly reportsStats?: IReportStats;
  readonly reportsStatsLoading?: boolean;
}

export interface IParticipants {
  number: number;
  name?: string;
  id: number;
}

export interface IBettingEvent {
  id: number;
  title?: string;
  startsOn: string;
  bettingEndsOn: string;
  prizePoolPercentage: number;
  ticketPrice: number;
  guaranteedMinimumPrizePool: number;
  userId: number;
  status: number;
  participants: Array<IParticipants>;
  totalBetsAmount: number;
  totalWinningsAmount: number;
  totalPaidOutAmount: number;
  soldTicketAmount: number;
  cancelledTicketAmount: number;
  winningTicketAmount: number;
  eventEndedOn: string | null;
  winningSingleTicketPrize: number;
}

export interface IBettingEventsReport {
  hasNext: boolean;
  hasPrevious: boolean;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  currentPage: number;
  data: IBettingEvent[];
}

export interface IEventWinnerId {
  predictedWinnerParticipantId: number;
}

export interface IReserveFunds {
  currentAmount: number;
}

export interface IReportStats {
  totalInAmount: number;
  totalOutAmount: number;
}

export interface INewBettingEvent {
  title: string;
  userId?: number;
  startsOn: string;
  bettingEndsOn: string;
  prizePoolPercentage: number;
  ticketPrice: number;
  guaranteedMinimumPrizePool: number;
  participants: IEventParticipant[];
}

export interface INewBettingEventFormated {
  title: string;
  userId: number;
  startsOn: string;
  bettingEndsOn: string;
  prizePoolPercentage: number;
  ticketPrice: number;
  guaranteedMinimumPrizePool: number;
  participants: IEventParticipant[];
}

export const initialBettingEventState: TBettingEventState = {
  data: undefined,
  reserveFunds: 0,
  isLoading: false,
  isLoadingFunds: false,
};

export const initialBettingEventsReportState: TBettingEventsReportState = {
  data: undefined,
  reportsStats: undefined,
  reportsStatsLoading: false,
  isLoading: false,
};

export enum BettingEventUpdate {
  UPDATE_EVENT_STATUS = "UPDATE_EVENT_STATUS",
}

export enum ReserveFundGet {
  REQUEST = "RESERVE_FUND_GET_REQUEST",
  SUCCESS = "RESERVE_FUND_GET_SUCCESS",
  FAILED = "RESERVE_FUND_GET_FAILED",
}

export enum BettingEventsGet {
  REQUEST = "BETTING_EVENTS_GET_REQUEST",
  SUCCESS = "BETTING_EVENTS_GET_SUCCESS",
  FAILED = "BETTING_EVENTS_GET_FAILED",
}

export enum BettingEventPost {
  REQUEST = "BETTING_EVENT_POST_REQUEST",
  SUCCESS = "BETTING_EVENT_POST_SUCCESS",
  FAILED = "BETTING_EVENT_POST_FAILED",
}

export enum BettingEventCancel {
  REQUEST = "BETTING_EVENT_CANCEL_REQUEST",
  SUCCESS = "BETTING_EVENT_CANCEL_SUCCESS",
  FAILED = "BETTING_EVENT_CANCEL_FAILED",
}

export enum BettingEventsReportGet {
  REQUEST = "BETTING_EVENTS_REPORT_GET_REQUEST",
  SUCCESS = "BETTING_EVENTS_REPORT_GET_SUCCESS",
  FAILED = "BETTING_EVENTSREPORT__GET_FAILED",
}

export enum BettingReportExport {
  REQUEST = "BETTING_REPORT_EXPORT_GET_REQUEST",
  SUCCESS = "BETTING_REPORT_EXPORT_GET_SUCCESS",
  FAILED = "BETTING_REPORT_EXPORT_GET_FAILED",
}

export enum BettingReportStatsGet {
  REQUEST = "BETTING_REPORT_STATS_GET_REQUEST",
  SUCCESS = "BETTING_REPORT_STATS_GET_SUCCESS",
  FAILED = "BETTING_REPORT_STATS_GET_FAILED",
}

export type TBettingEventUpdate = {
  type: BettingEventUpdate.UPDATE_EVENT_STATUS;
  payload: IBettingEvent;
};

export type TBettingReportStatsGetAction =
  | {
      type: BettingReportStatsGet.REQUEST;
    }
  | {
      type: BettingReportStatsGet.SUCCESS;
      payload: AxiosResponse<IReportStats>;
    }
  | {
      type: BettingReportStatsGet.FAILED;
      payload: AxiosError;
    };

export type TReserveFundGetAction =
  | {
      type: ReserveFundGet.REQUEST;
    }
  | {
      type: ReserveFundGet.SUCCESS;
      payload: AxiosResponse<IReserveFunds>;
    }
  | {
      type: ReserveFundGet.FAILED;
      payload: AxiosError;
    };

export type TBettingEventCancelAction =
  | {
      type: BettingEventCancel.REQUEST;
    }
  | {
      type: BettingEventCancel.SUCCESS;
      payload: AxiosResponse<IBettingEvent>;
    }
  | {
      type: BettingEventCancel.FAILED;
      payload: AxiosError;
    };

export type TBettingEventPostAction =
  | {
      type: BettingEventPost.REQUEST;
    }
  | {
      type: BettingEventPost.SUCCESS;
      payload: AxiosResponse<IBettingEvent>;
    }
  | {
      type: BettingEventPost.FAILED;
      payload: AxiosError;
    };

export type TBettingEventsGetAction =
  | {
      type: BettingEventsGet.REQUEST;
    }
  | {
      type: BettingEventsGet.SUCCESS;
      payload: AxiosResponse<Array<IBettingEvent>>;
    }
  | {
      type: BettingEventsGet.FAILED;
      payload: AxiosError;
    };

export type TBettingEventsReportGetAction =
  | {
      type: BettingEventsReportGet.REQUEST;
    }
  | {
      type: BettingEventsReportGet.SUCCESS;
      payload: AxiosResponse<IBettingEventsReport>;
    }
  | {
      type: BettingEventsReportGet.FAILED;
      payload: AxiosError;
    };

export type TBettingEventsActions =
  | TBettingEventPostAction
  | TReserveFundGetAction
  | TBettingEventCancelAction
  | TBettingReportStatsGetAction
  | TBettingEventsGetAction
  | TBettingEventUpdate
  | TBettingEventsReportGetAction;

export interface IBettingEventReportFilters {
  StoreId?: number | string;
  Term?: string;
  MinDate?: string;
  MaxDate?: string;
  PageNumber?: number;
  Limit?: number;
  Total?: number;
}
