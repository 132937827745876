import { makeStyles } from "@material-ui/core/styles";

export const useTableSimpleStyles = makeStyles((theme) => ({
  table: {
    tableLayout: "fixed",
  },
  row: {
    "&:hover": {
      background: "rgba(0,0,0,.085)",
      cursor: "pointer",
    },
  },
  nameContainer: {
    display: "flex",
    justifyItems: "center",
  },
  nameText: {
    paddingTop: 3,
    paddingLeft: theme.spacing(0.5),
  },
}));
