import { makeStyles } from "@material-ui/core/styles";

export const useAdminWorkersStyles = makeStyles((theme) => ({
  root: {},
  green: {
    color: theme.palette.primary.main,
  },
  overflowText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 215,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
}));
