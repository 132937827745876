import "core-js/stable";
import React, { StrictMode } from "react";
import { OidcProvider } from 'redux-oidc';
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import store from "./Shared/store/Store";
import { Provider } from "react-redux";
import App from "./App";
import "./Shared/config/i18next";
import userManager from './Shared/helpers/userManagement'

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
      <OidcProvider store={store} userManager={userManager}>
        <App />
      </OidcProvider>
    </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
