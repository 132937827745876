import { Reducer } from "redux";
import {
  initialTransactionState,
  TTransactionState,
  TTransactionActions,
  TransactionsGet,
  TransactionPut,
  TransactionCancel,
  TransactionsPost,
  PlayerTransactionsGet,
  PlayerTransactionsStatisticsGet,
} from "../types/Transactions";

export const transactionReducer: Reducer<
  TTransactionState,
  TTransactionActions
> = (state = initialTransactionState, action) => {
  switch (action.type) {
    case TransactionCancel.REQUEST: {
      return {
        ...state,
        isLoadingTransactionCancel: true,
      }
    }
    case TransactionsPost.REQUEST:
    case TransactionPut.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PlayerTransactionsGet.REQUEST:
    case TransactionsGet.REQUEST: {
      return {
        ...state,
        page: action.page,
        isLoading: true,
      };
    }
    case TransactionCancel.FAILED: {
      return {
        ...state,
        isLoadingTransactionCancel: false,
      }
    }
    case TransactionsPost.FAILED:
    case TransactionPut.FAILED:
    case PlayerTransactionsGet.FAILED:
    case TransactionsGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case TransactionCancel.SUCCESS: {
      return {
        ...state,
        isLoadingTransactionCancel: false,
        playerTransactions: state.playerTransactions
          ? state.playerTransactions.map((rec) =>
              rec.amountTransactionId === action.payload.data.id
                ? {...rec, isCancelled: true} : rec
            )
          : [],
      };
    }
    case TransactionsPost.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case TransactionsGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    }
    case TransactionPut.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: state.data
          ? state.data.map((rec) =>
              rec.id === action.payload.data.id ? action.payload.data : rec
            )
          : [],
      };
    }
    case PlayerTransactionsGet.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        playerTransactions: action.payload.data.data,
        selectedPlayerId: action.payload.data.data[0]?.playerId,
        totalPages: action.payload.data.totalPages,
      };
    }

    case PlayerTransactionsStatisticsGet.REQUEST: {
      return {
        ...state,
        isPlayerStatisticsLoading: true,
      };
    }
    case PlayerTransactionsStatisticsGet.FAILED: {
      return {
        ...state,
        isPlayerStatisticsLoading: false,
      };
    }
    case PlayerTransactionsStatisticsGet.SUCCESS: {
      return {
        ...state,
        playerTransactionsStatistics: action.payload.data,
        isPlayerStatisticsLoading: false,
      };
    }

    default:
      return state;
  }
};
