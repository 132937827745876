import axios, { AxiosResponse, Method, ResponseType } from "axios";
import { Dispatch } from "redux";
import { TDispatch } from "../types/Thunk";
import { SetAppError } from "../types/Error";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiCall = <
  TAction = any,
  TRequestData = any,
  TResponse extends {} = {}
>(
  action: any,
  method: Method,
  url: string,
  authorized: boolean = false,
  data: TRequestData | null = {} as TRequestData,
  headers: any = {},
  params: any = {},
  responseType: ResponseType = "json",
  dispatchParams: any = {}
) => {
  return async (
    dispatch: TDispatch<TAction>, getState: any
  ): Promise<AxiosResponse<TResponse>> => {
    dispatch({
      type: action.REQUEST,
      ...dispatchParams,
    });
    try {
      if (authorized) {
        apiClient.interceptors.request.use((request) => {
          request.headers["Authorization"] = `Bearer ${getState()?.oidc?.user?.access_token}`;
          return request;
        });
      }

      return await apiClient
        .request<TResponse>({
          data,
          method,
          params,
          url,
          headers,
          responseType,
        })
        .then((res: AxiosResponse<TResponse>) => {
          dispatch({
            type: action.SUCCESS,
            payload: res,
          });

          return res;
        });
    } catch (err: any) {
      dispatch({
        type: action.FAILED,
        payload: err,
      });
      throw err.response;
    }
  };
};

interface IErrorPayload {
  message: string;
  code: number;
}

export const errorAction = (payload: IErrorPayload) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SetAppError.SUCCESS,
      payload,
    });
  };
};
