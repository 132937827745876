import {
  IApiUserEntity,
  TUserActions,
  UsersGet,
  UsersPost,
  UsersPut,
} from "../../Shared/types/Users";
import { apiCall } from "../../Shared/actions/BaseAction";
import { API_END_POINTS } from "../constants/Api";
import { IWorkerData } from "../../Shared/types/Worker";

export const getUsersList = () => {
  return apiCall<TUserActions, null, Array<IApiUserEntity>>(
    UsersGet,
    "GET",
    API_END_POINTS.USERS,
    true
  );
};

export const postUser = (user: IWorkerData) => {
  return apiCall<TUserActions, IWorkerData, IApiUserEntity>(
    UsersPost,
    "POST",
    API_END_POINTS.USERS,
    true,
    user,
  );
}

export const editUser = (user: any, userId: number) => {
  return apiCall<TUserActions, IWorkerData, IApiUserEntity>(
    UsersPut,
    "PUT",
    API_END_POINTS.USERS_EDIT(userId),
    true,
    user
  );
}

export const changeUserPassword = (userId: number, password: string) => {
  return apiCall<TUserActions, Record<string, string>, any>(
    UsersPut,
    "PUT",
    API_END_POINTS.USERS_CHANGE_PWD(userId),
    true,
    {
      password
    }
  );
}