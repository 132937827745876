import { IPlayersTableRow, PlayersTableKeys } from "Gaming/constants/Interfaces";
import _ from "lodash";
import { PlayerDataStatus } from "Shared/types/Players";

export const checkLtIdentification = (value: number | string) => {
  let code = "";
  if (value) {
    code = value.toString();
  }
  if (code.length !== 11) return false;
  let b = 1,
    c = 3,
    d = 0,
    e = 0,
    i,
    digit;
  let lastDigit;
  for (i = 0; i < 10; i++) {
    digit = parseInt(code[i]);
    d += digit * b;
    e += digit * c;
    b++;
    if (b === 10) b = 1;
    c++;
    if (c === 10) c = 1;
  }
  d = d % 11;
  e = e % 11;
  if (d < 10) {
    lastDigit = d;
  } else if (e < 10) {
    lastDigit = e;
  } else {
    lastDigit = 0;
  }
  return Number(code[code.length - 1]) === lastDigit;
};

const isPlayerBlocked = (playerData: any, key: PlayersTableKeys) =>{
  return playerData.original[key] != 
         null && 
         playerData.original[key] !==
         PlayerDataStatus.Allowed && 
         playerData.original[key] !==
         PlayerDataStatus.Inconclusive;
}

export const checkIfPlayerIsBlocked = (playerData : any) => {
  const isGamblingEligibilityUndefined = 
                      typeof(playerData.original[PlayersTableKeys.gamblingEligibility]) === "undefined";
  
  if (isGamblingEligibilityUndefined){
      return isPlayerBlocked(playerData, PlayersTableKeys.gamblingCheckResult);
  }

  return isPlayerBlocked(playerData, PlayersTableKeys.gamblingEligibility);
}

export const checkIfPlayerIsInconclusive = (playerData : any) => {
  const isGamblingEligibilityUndefined = 
                      typeof(playerData.original[PlayersTableKeys.gamblingEligibility]) === "undefined";
  
  if (isGamblingEligibilityUndefined){
      return playerData.original[PlayersTableKeys.gamblingCheckResult] ===
             PlayerDataStatus.Inconclusive;
  }

  return playerData.original[PlayersTableKeys.gamblingEligibility] ===
         PlayerDataStatus.Inconclusive
}

export const isPlayerForbiddenInEuOrOnDatoOrUn = (playerStatus: PlayerDataStatus | undefined) => {
  const statuses = [PlayerDataStatus.ForbiddenEu, 
                    PlayerDataStatus.ForbiddenOnDato,
                    PlayerDataStatus.ForbiddenUn];

  return _.includes(statuses, playerStatus);
}

export const callPlayerForbiddenPopup = 
            (player: any, 
             onPlayerSelect: (player: Partial<IPlayersTableRow> | undefined) => void) =>{
  if(player){
    onPlayerSelect({
      id: player.playerId!,
      gamblingEligibility: player.gamblingEligibility,
    });
  }
}