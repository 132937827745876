import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// MUI
import { Typography, Grid, Button } from "@material-ui/core";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { ROUTES } from "../../constants/Routes";
import { IApiPlayerEntity, TPlayerState } from "../../../Shared/types/Players";
import TransactionCancelDialog from "../../components/admin/transactions/TransactionCancelDialog";
import { useAdminSinglePlayerStyles } from "./AdminSinglePlayer.styles";
import TableWrapper from "../../components/admin/adminPlayer/TableWrapper";
import PageHeader, {
  BoldText,
  GreenText,
} from "../../../Shared/components/PageHeader";
import { IAppState } from "../../../Shared/store/Store";
import {
  changeCurrentPlayer,
  getPlayersList,
} from "../../actions/PlayersActions";
import { handleError } from "../../../Shared/utils/ErrorHandler";
import Toast from "../../../Shared/components/utils/Toast";
import { IPlayerTableWrapperFilter } from "../../constants/Interfaces";
import { getPlayerTransactionsStatistics } from "../../actions/TransactionsActions";
import Loading from "../../../Shared/components/utils/Loading";
import { TTransactionState } from "../../../Shared/types/Transactions";

interface IAdminSinglePlayer {
  match?: any;
}

const AdminSinglePlayer: React.FC<IAdminSinglePlayer> = ({ match }) => {
  const { t } = useTranslation();
  const playerId = parseInt(match.params.id);
  const dispatch = useDispatch();
  const classes = useAdminSinglePlayerStyles();
  const history = useHistory();
  const [player, setPlayer] = useState<IApiPlayerEntity>();
  const [cancelDialog, setCancelDialog] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState();
  const [error, setError] = useState("");

  const playersState: TPlayerState = useSelector<IAppState, TPlayerState>(
    (state: IAppState) => state.playersState
  );
  const transactionsState: TTransactionState = useSelector<
    IAppState,
    TTransactionState
  >((state: IAppState) => state.transactionsState);

  const cancelTransaction = (row: any) => {
    setSelectedTransaction(row);
    setCancelDialog(true);
  };

  const onFilterChange = useCallback(
    async (filter: IPlayerTableWrapperFilter) => {
      try {
        await dispatch(getPlayerTransactionsStatistics(playerId, filter));
      } catch (err: any) {
        handleError(err?.status, setError);
      }
    },
    []
  );

  useEffect(() => {
    (async () => {
      // get players if no selectedPlayer and data like accessing from url
      if (!playersState.selectedPlayer && !playersState.data) {
        try {
          await dispatch(getPlayersList());
        } catch (err: any) {
          handleError(err?.status, setError);
        }
      }
      if (playersState.selectedPlayer) {
        setPlayer(playersState.selectedPlayer);
      } else {
        const foundPlayer = playersState.data?.find(
          (rec) => rec.id === playerId
        );
        if (foundPlayer) {
          setPlayer(foundPlayer);
          dispatch(changeCurrentPlayer(foundPlayer));
        }
      }
    })();
  }, [dispatch, playerId, playersState.data, playersState.selectedPlayer]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  return (
    <>
      {player && (
        <PageHeader
          leftSide={
            <Grid container alignItems="center">
              <Button
                onClick={() => {
                  history.push(ROUTES.ADMIN.PLAYERS);
                }}
              >
                <KeyboardArrowLeft className={classes.green} />
              </Button>
              <Typography variant="h5" className={classes.headerName}>
                {`${player.firstName} ${player.lastName}`}
              </Typography>
            </Grid>
          }
          rightSide={
            <Grid container alignItems="baseline">
              <GreenText display="inline">{t("totalBets")}: </GreenText>
              <BoldText display="inline">
                {transactionsState.isPlayerStatisticsLoading ? (
                  <Loading width={20} />
                ) : (
                  `${transactionsState.playerTransactionsStatistics?.totalInAmount} eur`
                )}
              </BoldText>

              <GreenText display="inline">{t("totalPayouts")}: </GreenText>
              <BoldText display="inline">
                {transactionsState.isPlayerStatisticsLoading ? (
                  <Loading width={20} />
                ) : (
                  `${transactionsState.playerTransactionsStatistics?.totalOutAmount} eur`
                )}
              </BoldText>
            </Grid>
          }
        />
      )}
      <TableWrapper
        player={player}
        cancelTransaction={cancelTransaction}
        onFilterChange={onFilterChange}
      />
      {cancelDialog && (
        <TransactionCancelDialog
          open={cancelDialog}
          transaction={selectedTransaction}
          onClose={() => {
            setCancelDialog(false);
            setSelectedTransaction(undefined);
          }}
        />
      )}
    </>
  );
};

export default AdminSinglePlayer;
