import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { IPlayersTableRow } from "../../../constants/Interfaces";

interface IAddPlayerData {
  player: Partial<IPlayersTableRow> | undefined;
}

const AddPlayerDataRows: React.FC<IAddPlayerData> = ({ player }) => {
  const { t } = useTranslation();

  if (!player) return <></>;

  return (
    <>
      <Typography variant="body2">
        {`${t("nationalIdentificationNumber")}: `}
      </Typography>
      <Typography variant="body2">
        {player.nationalIdentificationNumber}
      </Typography>

      <Typography variant="body2">{t("birthDate")}: </Typography>
      <Typography variant="body2">
        {player.birthDate
          ? format(new Date(player.birthDate), "yyyy-MM-dd")
          : ""}
      </Typography>

      <Typography variant="body2">{t("nationality")}: </Typography>
      <Typography variant="body2">{player.citizenship}</Typography>
    </>
  );
};

export default AddPlayerDataRows;
