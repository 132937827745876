import { AxiosResponse } from "axios";
import { TDispatch } from "../../Shared/types/Thunk";
import { apiClient, apiCall } from "../../Shared/actions/BaseAction";
import {
  BettingEventsGet,
  TBettingEventState,
  IBettingEvent,
  BettingEventPost,
  BettingEventCancel,
  INewBettingEventFormated,
  BettingEventUpdate,
  ReserveFundGet,
  TReserveFundGetAction,
  IReserveFunds,
} from "../types/BettingEvents";
import { API_END_POINTS } from "../constants/Api";
import { AdminEventStatus } from "../constants/Interfaces";
import { differenceInMilliseconds, isBefore } from "date-fns";

const getDatesDiff = (date: string) => {
  return Math.abs(differenceInMilliseconds(new Date(date), new Date()));
};

const setTimerForStatusChange = (
  events: Array<IBettingEvent>,
  dispatch: TDispatch<any>
) => {
  if (events) {
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      if (event.status === AdminEventStatus.Active) {
        const diff = getDatesDiff(event.bettingEndsOn);
        // eslint-disable-next-line no-loop-func
        setTimeout(() => {
          if (!isBefore(new Date(), new Date(event.bettingEndsOn))) {
            dispatch(updateEventStatus(event));
            setTimerForStatusChange(
              [{ ...event, status: event.status + 1 }],
              dispatch
            );
          }
        }, diff);
      } else if (event.status === AdminEventStatus.Pending) {
        const diff = getDatesDiff(event.startsOn);
        setTimeout(() => {
          if (!isBefore(new Date(), new Date(event.bettingEndsOn))) {
            dispatch(updateEventStatus(event));
          }
        }, diff);
      }
    }
  }
};

export const updateEventStatus = (event: IBettingEvent) => {
  return (dispatch: TDispatch<any>): IBettingEvent => {
    const updateEvent = {
      ...event,
      status: event.status + 1,
    };
    dispatch({
      type: BettingEventUpdate.UPDATE_EVENT_STATUS,
      payload: updateEvent,
    });
    return updateEvent;
  };
};

export const getBettingEvents = () => {
  return async (dispatch: TDispatch<any>): Promise<AxiosResponse<any>> => {
    dispatch({
      type: BettingEventsGet.REQUEST,
    });

    try {
      const response = await apiClient.request({
        method: "GET",
        url: API_END_POINTS.ADMIN_EVENT,
        data: {},
      });

      dispatch({
        type: BettingEventsGet.SUCCESS,
        payload: response,
      });
      setTimerForStatusChange(response.data, dispatch);
      return response;
    } catch (error: any) {
      dispatch({
        type: BettingEventsGet.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const postBettingEvent = (event: INewBettingEventFormated) => {
  return async (
    dispatch: TDispatch<any>
  ): Promise<AxiosResponse<IBettingEvent>> => {
    dispatch({
      type: BettingEventPost.REQUEST,
    });
    try {
      const response = await apiClient.request({
        method: "POST",
        url: API_END_POINTS.ADMIN_EVENT_POST,
        data: {
          userId: event.userId,
          title: event.title,
          startsOn: event.startsOn,
          bettingEndsOn: event.bettingEndsOn,
          prizePoolPercentage: event.prizePoolPercentage,
          ticketPrice: event.ticketPrice,
          guaranteedMinimumPrizePool: event.guaranteedMinimumPrizePool,
          participants: event.participants,
        },
      });
      setTimerForStatusChange([response.data], dispatch);
      dispatch({
        type: BettingEventPost.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error: any) {
      dispatch({
        type: BettingEventPost.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const cancelBettingEvent = (id: number) => {
  return apiCall<TBettingEventState, null, IBettingEvent>(
    BettingEventCancel,
    "PUT",
    API_END_POINTS.BETTING_EVENT_CANCEL(id),
    true
  );
};

export const postBettingEventResults = (
  eventId: number,
  WinnerParticipantId?: number,
  DidNotParticipate?: number[]
) => {
  return async (
    dispatch: TDispatch<any>
  ): Promise<AxiosResponse<IBettingEvent>> => {
    dispatch({
      type: BettingEventPost.REQUEST,
    });
    try {
      const response: any = await apiClient.request({
        method: "POST",
        url: API_END_POINTS.ADMIN_EVENT_ENTER_RESULTS(eventId),
        data: {
          WinnerParticipantId,
          DidNotParticipate,
        },
      });

      await dispatch(updateEventStatus(response.data as IBettingEvent));

      dispatch({
        type: BettingEventPost.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error: any) {
      dispatch({
        type: BettingEventPost.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getReserveFunds = () => {
  return apiCall<TReserveFundGetAction, null, IReserveFunds>(
    ReserveFundGet,
    "GET",
    API_END_POINTS.RESERVE_FUNDS_GET,
    true
  );
};
