import { makeStyles } from "@material-ui/core/styles";

export const useAdminSinglePlayerStyles = makeStyles((theme) => ({
  headerName: {
    fontWeight: "bold",
    paddingLeft: theme.spacing(1),
  },
  tablePaper: {
    width: "100%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(1),
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    padding: theme.spacing(3),
  },
  green: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  orange: {
    color: "#FFC107",
  },
  small: {
    fontSize: 12,
  },
  big: {
    fontSize: 14,
  },
  centerizeCell: {
    display: "flex",
    justifyContent: "center",
    padding: `6px 6px`,
    minWidth: 64,
  },
  documentIcon: {
    marginRight: 5,
  },
  playerName: {
    display: "flex",
    alignItems: "center",
    padding: `6px 0`,
  },
}));
