import { FC } from 'react'
import {
	Dialog,
	DialogContent,
	Typography,
	Button,
	Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
	Close,
} from "@material-ui/icons";
import clsx from "clsx";
import { useEventWinnerModalStyles } from "./EventWinnerSelect.styles";
import { useConfirmNobodyWonsStyles } from "./ConfirmNobodyWons.styles";

interface IConfirmNobodyWons {
	onNobodyWon: () => void;
	setConfirmNobodyWonsOpen: (val: boolean) => void;
}

const ConfirmNobodyWons: FC<IConfirmNobodyWons> = ({ onNobodyWon, setConfirmNobodyWonsOpen }) => {
	const classes = useEventWinnerModalStyles();
	const classesConfirmModal = useConfirmNobodyWonsStyles();

	const { t } = useTranslation();

	return (
		<Dialog open onClose={() => setConfirmNobodyWonsOpen(false)}>
			<Close onClick={() => setConfirmNobodyWonsOpen(false)} />
			<DialogContent className={classesConfirmModal.dialogContent}>
				<Box position="relative" mt={-1.5} mb={2}>
					<Typography align="center" variant="h5">
						{t("nobodyWonConfirmTitle")}
					</Typography>
				</Box>
				<Box position="relative" mt={1} mb={2}>
					<Typography align="center" variant="body1">
						{t("nobodyWonConfirmContent")}
					</Typography>
				</Box>
				{/* Buttons */}
				<Box
					display="flex"
					gridGap={16}
					alignItems="center"
					justifyContent="space-between"
					mt={1}
				>
					<Button
						variant="outlined"
						className={clsx(classes.button, classesConfirmModal.redButton)}
						onClick={() => setConfirmNobodyWonsOpen(false)}
						color="primary"
						type="submit"
						disableElevation
						size="small"
					>
						<Typography variant="caption">{t("back")}</Typography>
					</Button>
					<Button
						variant="outlined"
						className={clsx(classes.button, classes.green)}
						onClick={onNobodyWon}
						color="primary"
						type="submit"
						disableElevation
						size="small"
					>
						<Typography variant="caption">{t("confirm")}</Typography>
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	)
};


export default ConfirmNobodyWons;
