import { makeStyles } from "@material-ui/core/styles";

export const useWorkerFormStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: theme.spacing(1),
  },
  error: {
    color: "red",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
    minWidth: 160,
    minHeight: 50,
    "&.MuiButton-containedPrimary ": {
      color: "white",
    },
    "&.MuiButton-label": {
      display: "flex",
      alignItems: "end",
      fontSize: 14,
    },
  },
  inputIconClickable: {
    cursor: "pointer",
  },
  passwordStrengthContainer: {
    position: "relative",
  },
  input: {
    marginTop: theme.spacing(2),
  },
  chip: {
    margin: 2,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  arrowIcon: {
    color: "#DADADA !important",
  },
  selection: {
    "& > .MuiSelect-select:focus": {
      backgroundColor: "unset",
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
}));
