import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Paper } from "@material-ui/core";
import PlayerInfo from "../player/PlayerInfo";
import AdminMenu from "./AdminMenu";
import { ROUTES } from "../../constants/Routes";
import { useAdminPanelStyles } from "./AdminPanel.styles";


const AdminPanel = () => {
  const classes = useAdminPanelStyles();
  const isViewingPlayers = useRouteMatch([
    ROUTES.ADMIN.PLAYER,
    ROUTES.ADMIN.PLAYERS,
    ROUTES.ADMIN.MAIN,
  ]);

  return (
    <Paper elevation={3} className={classes.container}>
      <AdminMenu />
      <div className={classes.player}>
        {isViewingPlayers ? <PlayerInfo /> : ""}
      </div>
    </Paper>
  );
};

export default AdminPanel;
