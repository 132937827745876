import {
  initialTicketActionsState,
  TBettingEventTicketActionsState,
  TBettingEventTicketActionActions,
  BettingEventTicketActionsGet,
} from "../types/BettingEventTicketActions";
import { Reducer } from "redux";

export const bettingEventTicketActionsReducer: Reducer<
  TBettingEventTicketActionsState,
  TBettingEventTicketActionActions
> = (state = initialTicketActionsState, action) => {
  switch (action.type) {
    case BettingEventTicketActionsGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BettingEventTicketActionsGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case BettingEventTicketActionsGet.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        totalPages: action.payload.data.totalPages,
        data: action.payload.data.data,
      };
    }
    default: {
      return state;
    }
  }
};
