import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  passwordRequirements: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  requirementItem: {
    display: 'flex',
    justifyItems: 'center',
  },
  checkBox: {
    marginRight: theme.spacing(1),
  },
  greenCheckBox: {
    marginRight: theme.spacing(1),
    color: theme.palette.success.main,
  },
  dot: {
    marginRight: theme.spacing(0.5),
  },
  requirement: {
    marginTop: 'auto',
  },
  greenRequirement: {
    marginTop: 'auto',
    color: theme.palette.success.main,
  },
  passwordHelper: {
    position: 'absolute',
    width: 250,
    height: 145,
    padding: theme.spacing(2),
    zIndex: '9999',
    marginTop: theme.spacing(1.5),
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 5,
    left: 0,
    right: 0,
    marginRight: 'auto',
    marginLeft: 'auto',
    '&:before': {
      verticalAlign: 'middle',
      content: '""',
      margin: 'auto',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '95%',
      width: 16,
      height: 16,
      background: "#FFF",
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderRight: `1px solid ${theme.palette.primary.main}`,
      transform: 'rotate(-45deg)',
      '&::-webkit-transform': 'rotate(-45deg)',
      '&::-moz-transform': 'rotate(-45deg)',
    },
  },
}));
