import React, { useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import { ROUTES } from "../../constants/Routes";
import { useAdminMenuStyles } from "./AdminMenu.styles";
import { useTranslation } from "react-i18next";

const AdminMenu: React.FC = () => {
  const classes = useAdminMenuStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [links] = useState([
    { name: t("events"), route:[ ROUTES.ADMIN.EVENTS, ROUTES.ADMIN.MAIN, '/'] },
    { name: t("reports"), route: [ROUTES.ADMIN.REPORTS] },
    { name: t("logReport"), route: [ROUTES.ADMIN.LOG_REPORTS] },
  ]);

  return (
    <List className={classes.menu}>
      {links.map((link, index) => (
        <ListItem
          key={index}
          button
          className={classes.listItem}
          onClick={() => history.push(link.route[0])}
        >
          <ListItemText
            className={clsx(
              link.route.includes(history.location.pathname) &&
                classes.listItemTextActive,
              classes.listItemText
            )}
          >
            {link.name}
          </ListItemText>
          {link.route.includes(history.location.pathname) && (
            <ListItemIcon className={classes.activeItemIcon}>
              <KeyboardArrowRight />
            </ListItemIcon>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default AdminMenu;
