import { AxiosResponse } from "axios";
import { format } from "date-fns";
import {
  BettingEventsReportGet,
  BettingReportExport,
  IBettingEventReportFilters,
  BettingReportStatsGet,
  IReportStats,
} from "../types/BettingEvents";
import { apiClient } from "Shared/actions/BaseAction";
import { TDispatch } from "Shared/types/Thunk";
import { API_END_POINTS } from "ToTo/constants/Api";

export const getBettingEventsReport = (
  filters?: IBettingEventReportFilters
) => {
  return async (dispatch: TDispatch<any>): Promise<AxiosResponse<any>> => {
    dispatch({
      type: BettingEventsReportGet.REQUEST,
    });

    try {
      const response = await apiClient.request({
        method: "GET",
        url: API_END_POINTS.ADMIN_EVENTS_REPORT,
        data: {},
        params: filters,
      });

      dispatch({
        type: BettingEventsReportGet.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error: any) {
      dispatch({
        type: BettingEventsReportGet.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const exportBettingEvents = (filters: IBettingEventReportFilters) => {
  const fileName = `${format(new Date(), "yyyy-MM-dd-HH_mm_ss")}.csv`;
  return async (dispatch: TDispatch<any>): Promise<AxiosResponse<any>> => {
    dispatch({ type: BettingReportExport.REQUEST });
    try {
      const response = await apiClient.request({
        method: "GET",
        url: API_END_POINTS.ADMIN_EVENTS_EXPORT,
        data: {},
        headers: {
          "Content-Type": "text/csv",
          "Content-Disposition": `attachment;filename=${fileName}`,
        },
        params: filters,
        responseType: "arraybuffer",
      });

      dispatch({ type: BettingReportExport.SUCCESS, payload: response });
      return response;
    } catch (error: any) {
      dispatch({ type: BettingReportExport.FAILED, payload: error });
      throw error;
    }
  };
};

export const getReportsStats = (filters?: IBettingEventReportFilters) => {
  return async (
    dispatch: TDispatch<BettingReportStatsGet>
  ): Promise<AxiosResponse<IReportStats>> => {
    dispatch({
      type: BettingReportStatsGet.REQUEST,
    });

    try {
      const response = await apiClient.request({
        method: "GET",
        url: API_END_POINTS.REPORT_STATISTICS,
        data: {},
        params: filters,
      });
      dispatch({
        type: BettingReportStatsGet.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error: any) {
      dispatch({
        type: BettingReportStatsGet.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
