import { AxiosResponse } from "axios";
import {
  IApiTransactionEntity,
  TTransactionActions,
  TransactionsPost,
  TransactionPut,
  TransactionsGet,
  TransactionGet,
  PlayerTransactionsGet,
  PlayerTransactionDetails,
  PlayerTransactionsExport,
  PlayerTransactionsStatisticsGet,
  IApiTransactionsStatisticsEntity,
  TransactionCancel,
  IAllTransactionExportFilters,
} from "../../Shared/types/Transactions";
import { apiCall, apiClient } from "../../Shared/actions/BaseAction";
import { API_END_POINTS } from "../constants/Api";
import { PAGE_LIMIT } from "../../Shared/constants/Table";
import { TDispatch } from "../../Shared/types/Thunk";
import { format } from "date-fns";
import { IPlayerTableWrapperFilter } from "../constants/Interfaces";

export const getTransactionsList = () => {
  return apiCall<TTransactionActions, null, Array<IApiTransactionEntity>>(
    TransactionsGet,
    "GET",
    API_END_POINTS.TRANSACTIONS,
    true
  );
};

export const getPlayerTransactionsList = (
  playerId: number,
  page: number,
  storeId?: number,
  minDate?: Date,
  maxDate?: Date,
  minSumPerShift?: number
) => {
  const limit = PAGE_LIMIT;
  return apiCall<TTransactionActions, null, any>(
    PlayerTransactionsGet,
    "GET",
    API_END_POINTS.PLAYER_TRANSACTIONS(playerId),
    true,
    null,
    {},
    {
      limit,
      MinDate: minDate || undefined,
      MaxDate: maxDate || undefined,
      PageNumber: page || undefined,
      MinSumPerShift: minSumPerShift || undefined,
      StoreId: storeId || undefined,
    },
    "json",
    {
      page,
    }
  );
};

export const getTransaction = (id: number) => {
  return apiCall<TTransactionActions, null, IApiTransactionEntity>(
    TransactionGet,
    "GET",
    API_END_POINTS.TRANSACTION(id),
    true
  );
};

export const postTransaction = (data: IApiTransactionEntity) => {
  return apiCall<
    TTransactionActions,
    IApiTransactionEntity,
    IApiTransactionEntity
  >(
    TransactionsPost,
    "POST",
    API_END_POINTS.TRANSACTIONS,
    true,
    data,
    {
      accept: "text/plain",
    },
    {},
    "text"
  );
};

export const putTransaction = (id: number, data: IApiTransactionEntity) => {
  return apiCall<
    TTransactionActions,
    IApiTransactionEntity,
    IApiTransactionEntity
  >(TransactionPut, "PUT", API_END_POINTS.TRANSACTION(id), true, data);
};

export const cancelTransaction = (id: number) => {
  return apiCall<
    TTransactionActions,
    IApiTransactionEntity,
    IApiTransactionEntity
  >(TransactionCancel, "PUT", API_END_POINTS.CANCEL_TRANSACTION(id), true);
};

export const getPlayerTransactionsStatistics = (
  playerId: number,
  filter: IPlayerTableWrapperFilter
) => {
  return apiCall<TTransactionActions, null, IApiTransactionsStatisticsEntity>(
    PlayerTransactionsStatisticsGet,
    "GET",
    API_END_POINTS.PLAYER_TRANSACTIONS_STATISTICS(playerId),
    true,
    null,
    {},
    {
      StoreId: filter.storeId || undefined,
      MinDate: filter.minDate || undefined,
      MaxDate: filter.maxDate || undefined,
      MinSumPerShift: filter.minSumPerShift || undefined,
    }
  );
};

export const getTransactionCancellationDetails = (id: number) => {
  return async (dispatch: TDispatch<any>): Promise<AxiosResponse<any>> => {
    dispatch({ type: PlayerTransactionDetails.REQUEST });
    try {
      const response = await apiClient.request({
        method: "GET",
        url: API_END_POINTS.TRANSACTION_CANCELLATION_DETAILS(id),
        data: {},
      });

      dispatch({ type: PlayerTransactionDetails.SUCCESS, payload: response });
      return response;
    } catch (error: any) {
      dispatch({ type: PlayerTransactionDetails.FAILED, payload: error });
      throw error;
    }
  };
};

export const exportPlayerTransactions = (id: number) => {
  const fileName = `${format(new Date(), "yyyy-MM-dd-HH_mm_ss")}.csv`;
  return async (dispatch: TDispatch<any>): Promise<AxiosResponse<any>> => {
    dispatch({ type: PlayerTransactionsExport.REQUEST });
    try {
      const response = await apiClient.request({
        method: "GET",
        url: API_END_POINTS.PLAYER_TRANSACTIONS_EXPORT(id),
        data: {},
        headers: {
          "Content-Type": "text/csv",
          "Content-Disposition": `attachment;filename=${fileName}`,
        },
        responseType: "arraybuffer",
      });

      dispatch({ type: PlayerTransactionsExport.SUCCESS, payload: response });
      return response;
    } catch (error: any) {
      dispatch({ type: PlayerTransactionsExport.FAILED, payload: error });
      throw error;
    }
  };
};

export const exportAllTransactions = (
  filters: IAllTransactionExportFilters
) => {
  const fileName = `${format(new Date(), "yyyy-MM-dd-HH_mm_ss")}.csv`;
  return async (dispatch: TDispatch<any>): Promise<AxiosResponse<any>> => {
    dispatch({ type: PlayerTransactionsExport.REQUEST });
    try {
      const response = await apiClient.request({
        method: "GET",
        url: API_END_POINTS.TRANSACTIONS_EXPORT,
        data: {},
        headers: {
          "Content-Type": "text/csv",
          "Content-Disposition": `attachment;filename=${fileName}`,
        },
        params: {
          ...filters,
        },
        responseType: "arraybuffer",
      });

      dispatch({ type: PlayerTransactionsExport.SUCCESS, payload: response });
      return response;
    } catch (error: any) {
      dispatch({ type: PlayerTransactionsExport.FAILED, payload: error });
      throw error;
    }
  };
};
