import { makeStyles } from "@material-ui/core/styles";

export const useConfirmModalStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: "center",
    paddingBottom: 0,
  },
  dialogContent: {
    minWidth: 430,
    paddingBottom: theme.spacing(2),
    borderRadius: 10,
  },
  dialogClose: {
    marginRight: theme.spacing(2),
    marginLeft: "auto",
    marginTop: theme.spacing(2),
    color: '#0C0C0C',
    fontSize: 23,
    "&:hover": {
      cursor: "pointer",
    },
  },
  confirmDialog: {
    minWidth: 300,
  },
  redButton: {
    backgroundColor: "#FF4C4C",
    "&:hover": {
      backgroundColor: "#D13A3A",
    },
    color: 'white',
    width: 95,
    height: 35,
  },
  button: {
    backgroundColor: '#0AAF60',
    width: 95,
    height: 35,
  },
}));
