import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Paper,
  Grid,
  Typography,
  withStyles,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { PostAdd, Cancel } from "@material-ui/icons";
import PageHeader from "Shared/components/PageHeader";
import EventsTable from "ToTo/components/EventsTable";
import EventCreateModal from "ToTo/components/admin/EventCreateModal";
import { AdminEventsTableKeys } from "ToTo/constants/Interfaces";
import EventWinnerSelect from "ToTo/components/admin/EventWinnerSelect";
import TicketDeleteModal from "ToTo/components/admin/TicketDeleteModal";
import HeaderInputs from "ToTo/components/admin/HeaderInputs";
import {
  getBettingEvents,
  cancelBettingEvent,
  getReserveFunds,
} from "ToTo/actions/BettingEventsActions";
import { TBettingEventState, IBettingEvent } from "ToTo/types/BettingEvents";
import { IAppState } from "Shared/store/Store";
import Toast from "Shared/components/utils/Toast";
import { handleError } from "Shared/utils/ErrorHandler";
import { AdminEventStatus } from "ToTo/constants/Interfaces";
import {
  cancelBettingEventTicket,
  extendBettingEventTicket,
} from "ToTo/actions/BettingEventTicketActions";
import { useAdminEventsStyles } from "./AdminEvents.styles";

const HeaderLabel = withStyles({
  root: {
    color: "#000",
    fontSize: 14,
    marginLeft: 8,
  },
})(Typography) as typeof Typography;

const SumLabel = withStyles({
  root: {
    fontSize: 16,
    color: "#0AAF60",
    fontWeight: "bolder",
  },
})(Typography) as typeof Typography;

const Events: React.FC = () => {
  const classes = useAdminEventsStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectingWinner, setSelectingWinner] = useState<IBettingEvent>();
  const [deleting, setDeleting] = useState(false);
  const [creating, setCreating] = useState(false);
  const [tableData, setTableData] = useState<Array<IBettingEvent>>([]);
  const [error, setError] = useState("");
  const [cancelEvent, setCancelEvent] = useState<number>();
  const bettingEventState: TBettingEventState = useSelector<
    IAppState,
    TBettingEventState
  >((state: IAppState) => state.bettingEventsState);

  // get reserve funds for headear
  useEffect(() => {
    (async () => {
      if (!bettingEventState.reserveFunds) {
        try {
          await dispatch(getReserveFunds());
        } catch (err: any) {
          handleError(err?.status, setError);
        }
      }
    })();
  }, [dispatch, bettingEventState.reserveFunds]);

  useEffect(() => {
    (async () => {
      if (!bettingEventState.eventsTableData) {
        try {
          await dispatch(getBettingEvents());
        } catch (err: any) {
          handleError(err?.status, setError);
        }
      }
      if (bettingEventState.eventsTableData) {
        const filteredData = bettingEventState.eventsTableData.filter((event) =>
          [
            AdminEventStatus.Active,
            AdminEventStatus.Pending,
            AdminEventStatus.Ended,
          ].includes(event.status)
        );
        setTableData(filteredData);
      }
    })();
  }, [dispatch, bettingEventState.eventsTableData]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  const columns = [
    {
      Header: `${t("title")}`,
      accessor: AdminEventsTableKeys.title,
      Cell: (cell: any) => (
        <Typography className={classes.big}>{cell.value}</Typography>
      ),
    },
    {
      Header: t("status"),
      accessor: AdminEventsTableKeys.status,
      Cell: (cell: any) => (
        <Typography className={classes.small}>
          {t(`adminEventStatuses.${cell.value}`)}
        </Typography>
      ),
    },
    {
      Header: t("bidSum"),
      accessor: AdminEventsTableKeys.totalBetsAmount,
      Cell: (cell: any) => (
        <Typography className={classes.small}>{cell.value || 0}€</Typography>
      ),
    },
    {
      Header: `${t("ticketAmount")}`,
      accessor: AdminEventsTableKeys.soldTicketAmount,
      Cell: (cell: any) => (
        <Typography className={classes.small}>{cell.value || 0}</Typography>
      ),
    },
    {
      Header: `${t("eventDate")}`,
      accessor: AdminEventsTableKeys.startsOn,
      Cell: (cell: any) => (
        <Typography className={classes.small}>
          {format(new Date(cell.value), "yyyy-MM-dd HH:mm:ss")}
        </Typography>
      ),
    },
    {
      Header: `${t("betsEndAt")}`,
      accessor: AdminEventsTableKeys.bettingEndsOn,
      Cell: (cell: any) => (
        <Typography className={classes.small}>
          {format(new Date(cell.value), "yyyy-MM-dd HH:mm:ss")}
        </Typography>
      ),
      headerStyle: {
        backgroundColor: "red",
      },
    },
    {
      Header: "",
      id: "warning",
      Cell: (tableInfo: any) => (
        <Grid container>
          {tableInfo.row.original.status === AdminEventStatus.Ended && (
            <Button
              onClick={() => {
                setSelectingWinner(tableInfo.row.original);
              }}
            >
              <Tooltip title={`${t("summarizeResults")}`}>
                <PostAdd className={classes.green} />
              </Tooltip>
            </Button>
          )}
          {[AdminEventStatus.Active, AdminEventStatus.Ended].includes(
            tableInfo.row.original.status
          ) && (
            <Button
              onClick={() => {
                setCancelEvent(tableInfo.row.original.id);
                setDeleting(true);
              }}
            >
              <Tooltip title={`${t("cancel")}`}>
                <Cancel className={classes.red} />
              </Tooltip>
            </Button>
          )}
        </Grid>
      ),
    },
  ];

  const createEvent = () => {
    // Called when 'create event' is pressed in header
    if (creating) return;
    setCreating(true);
  };

  const [ticketId, setTicketId] = useState<string>();

  const deleteTicket = (ticketId: string | undefined) => {
    // Called when 'cancel ticket' is pressed in header
    setTicketId(ticketId);
    setDeleting(true);
  };

  const onDelete = async () => {
    // Called when user confirms ticketId deletion or cancel event
    try {
      if (ticketId) {
        await dispatch(cancelBettingEventTicket(ticketId));
        toast.success(<Toast text={t("ticketCancelled")} cleanup={() => {}} />);
      }
      if (cancelEvent) {
        await dispatch(cancelBettingEvent(cancelEvent));
        toast.success(<Toast text={t("eventCancelled")} cleanup={() => {}} />);
      }
    } catch (err: any) {
      handleError(err?.status, setError, t("couldntCancelThisTicket"));
    }
    setTicketId(undefined);
    setDeleting(false);
    if (cancelEvent) {
      setCancelEvent(undefined);
    }
  };

  const onTicketExtend = async (ticketId: string | undefined) => {
    // called when "extend ticket" is pressed in header
    try {
      if (ticketId) {
        await dispatch(extendBettingEventTicket(ticketId));
        toast.success(<Toast text={t("ticketExtended")} cleanup={() => {}} />);
      }
    } catch (err: any) {
      handleError(err?.status, setError, t("couldntExtendThisTicket"));
    }
  };

  const handleDeleteModalClose = () => {
    setDeleting(false);
    setCancelEvent(undefined);
    setTicketId(undefined);
  };

  return (
    <>
      <EventCreateModal open={creating} onClose={() => setCreating(false)} />
      {deleting && (
        <TicketDeleteModal
          open={deleting}
          ticketId={ticketId}
          cancelEvent={cancelEvent}
          onClose={handleDeleteModalClose}
          onConfirm={onDelete}
        />
      )}

      <PageHeader
        leftSide={
          <Grid container alignItems="center" className={classes.leftGrid}>
            <HeaderInputs
              createEvent={createEvent}
              deleteTicket={deleteTicket}
              extendTicket={onTicketExtend}
            />
          </Grid>
        }
        rightSide={
          <Grid container alignItems="baseline">
            <SumLabel display="inline">{t("reserveFondFunds")}:</SumLabel>
            <HeaderLabel display="inline">
              {bettingEventState.isLoadingFunds ? (
                <CircularProgress size={15} color="primary" />
              ) : (
                `${bettingEventState.reserveFunds} eur`
              )}
            </HeaderLabel>
          </Grid>
        }
      />

      <Paper elevation={3} className={classes.paper}>
        <EventsTable columns={columns} events={tableData} />
      </Paper>

      {selectingWinner && (
        <EventWinnerSelect
          open
          event={selectingWinner}
          onClose={() => setSelectingWinner(undefined)}
        />
      )}
    </>
  );
};

export default Events;
