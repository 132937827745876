import { makeStyles } from "@material-ui/core/styles";

export const useAddPlayerStatusStyles = makeStyles((theme) => ({
  card: {
    minWidth: 280,
    maxWidth: 350,
    marginBottom: theme.spacing(2),
    overflow: "visible",
  },
  cardContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px !important`,
    position: "relative",
    "&:hover": {
      cursor: "pointer",
    },
  },
  fullWidth: {
    gridColumn: "auto / span 2",
    marginBottom: theme.spacing(0.5),
  },

  greenBorder: {
    borderColor: "#0AAF60",
  },
  redBorder: {
    borderColor: "#FB2424",
  },
  orangeText: {
    color: "#F57C00",
  },
  redText: {
    color: "#FB2424",
  },
  playerIcon: {
    width: 24,
    height: "unset",
    position: "absolute",
    right: theme.spacing(0.5),
    bottom: theme.spacing(0.5),
  },
}));
