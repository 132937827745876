import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  FormControl,
  Button,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Close, PersonAdd } from "@material-ui/icons";
import { useConfirmIdDialogStyles } from "./ConfirmIdDialog.styles";
import Loading from "../../../../Shared/components/utils/Loading";

interface IConfirmIdDialog {
  open: boolean;
  handleClose: () => void;
  submitting: boolean;
  handlePlayerCreate?: () => void;
}

const ConfirmIdDialog: React.FC<IConfirmIdDialog> = ({
  open,
  handleClose,
  submitting,
  handlePlayerCreate,
}) => {
  const classes = useConfirmIdDialogStyles();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    await handlePlayerCreate?.();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Close onClick={handleClose} className={classes.dialogClose} />

      <DialogTitle disableTypography={true}>
        <Typography align="center" variant="h4" className={classes.dialogTitle}>
          {t("incorrectIdentification")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography align="center">
            {t("incorrectIdentificationTip")}
          </Typography>
        </DialogContentText>
        <DialogActions className={classes.dialogContent}>
          <FormControl>
            <Button
              name="check"
              className={classes.buttonWarning}
              size="large"
              variant="contained"
              disableElevation
              onClick={handleClose}
            >
              {submitting ? (
                <Loading width={20} circleColor="white" />
              ) : (
                t("cancel")
              )}
            </Button>
          </FormControl>

          <FormControl>
            <Button
              name="create"
              color="primary"
              className={classes.button}
              size="large"
              variant="outlined"
              disableElevation
              startIcon={<PersonAdd />}
              onClick={handleSubmit}
            >
              {submitting ? (
                <Loading width={20} circleColor="white" />
              ) : (
                t("confirm")
              )}
            </Button>
          </FormControl>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmIdDialog;
