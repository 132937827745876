import React, { ReactElement } from "react";
import {
  Typography,
  Button,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { useTicketCheckDialog } from "./TicketCheckDialog.styles";
import { useTranslation } from "react-i18next";

const TicketButtonWrap: React.FC<{
  expired: boolean;
  children: ReactElement<any, any>;
  disabled?: boolean;
}> = ({ expired, children, disabled }) => {
  const { t } = useTranslation();
  if (!expired && !disabled) return <>{children}</>;
  return <Tooltip title={`${t("TicketExpired")}`}>{children}</Tooltip>;
};

interface ITicketCheckButton {
  loading?: boolean;
  handleClick: () => void;
  icon?: JSX.Element;
  text: string;
  buttonClass: string;
  disabled?: boolean;
  expired?: boolean;
}

const TicketCheckButton: React.FC<ITicketCheckButton> = ({
  loading,
  handleClick,
  icon,
  text,
  buttonClass,
  disabled,
  expired,
}) => {
  const classes = useTicketCheckDialog();

  return (
    <TicketButtonWrap expired={!!expired} disabled={disabled}>
      {/* div is needed for tooltip to display something when button is disabled */}
      <div>
        <Button
          variant="contained"
          className={buttonClass}
          disableElevation
          size="small"
          onClick={handleClick}
          disabled={loading || disabled || expired}
        >
          {loading ? (
            <CircularProgress
              style={{ width: 16, height: 16, marginRight: 10 }}
            />
          ) : (
            icon
          )}
          <Typography className={classes.paddingTop} variant="caption">
            {text}
          </Typography>
        </Button>
      </div>
    </TicketButtonWrap>
  );
};

export default TicketCheckButton;
