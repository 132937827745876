import { makeStyles } from "@material-ui/core/styles";

export const useSelectionTableStyles = makeStyles((theme) => ({
  searchBox: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  searchIcon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  green: {
    color: theme.palette.primary.main,
  },
  checkedIcon: {
    fontSize: 14,
  },
  selectionTable: {
    tableLayout: "fixed",
    height: 250,
    position: "relative",
    overflowY: "auto",
    display: "block",
    "& .MuiTableCell-root": {
      paddingLeft: 5,
      paddingRight: 5,
    },
    "& .MuiTableCell-body": {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  row: {
    "&:hover": {
      background: "rgba(255,255,255,.085)",
      cursor: "pointer",
    },
  },
  smallCell: {
    width: "70px !important",
  },
  largeCell: {
    width: "75% !important",
  },
  warningCell: {
    width: "8% !important",
  },
  fullWidthCell: {
    width: "100% !important",
  },
}));
