import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Dialog,
  Typography,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useWorkerEditPasswordDialogStyles } from "./WorkerEditPasswordDialog.styles";
import EditPasswordForm from "./EditPasswordForm";
import { changeUserPassword } from "../../../actions/UsersActions";
import Toast from "../../../../Shared/components/utils/Toast";
import { handleError } from "../../../../Shared/utils/ErrorHandler";

interface IWorkerEditPasswordDialog {
  open: boolean;
  onClose: () => void;
  userId?: number;
}

const WorkerEditPasswordDialog: React.FC<IWorkerEditPasswordDialog> = ({
  open,
  onClose,
  userId,
}) => {
  const classes = useWorkerEditPasswordDialogStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();

  const handleSubmit = async (newPassword: string) => {
    try {
      if (userId) {
        await dispatch(changeUserPassword(userId, newPassword));
        toast.success(
          <Toast text={t("userPasswordChanged")} cleanup={() => {}} />
        );
        onClose();
      }
    } catch (err: any) {
      handleError(err?.status, setError);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
      setError(undefined);
    }
  }, [error]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Close onClick={onClose} className={classes.dialogClose} />
      <DialogTitle disableTypography={true}>
        <Typography align="center" variant="h4">
          {t("changePassword")}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <EditPasswordForm onSubmit={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

export default WorkerEditPasswordDialog;
