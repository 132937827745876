import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  cancelTransaction,
  getTransactionCancellationDetails,
} from "../../../actions/TransactionsActions";
import { handleError } from "../../../../Shared/utils/ErrorHandler";
import Toast from "../../../../Shared/components/utils/Toast";
import Loading from "../../../../Shared/components/utils/Loading";
import TransactionCancelDialogTitle from "./TransactionCancelDialogTitle";
import TransactionCancelDialogContent from "./TransactionCancelDialogContent";
import { useTransactionEditDialogStyles } from "./TransactionEditDialog.styles";
import {
  IApiTransactionCancellationDetails,
  TTransactionState,
} from "../../../../Shared/types/Transactions";
import { IAppState } from "../../../../Shared/store/Store";

interface ITransactionCancelDialog {
  transaction: any;
  open: boolean;
  onClose: () => void;
}

const TransactionCancelDialog: React.FC<ITransactionCancelDialog> = ({
  transaction,
  open,
  onClose,
}) => {
  const classes = useTransactionEditDialogStyles();
  const dispatch = useDispatch();
  const [backError, setBackError] = useState("");
  const [
    cancellationDetails,
    setCancellationDetails,
  ] = useState<IApiTransactionCancellationDetails>();
  const [loading, setLoading] = useState(false);

  const transactionsState: TTransactionState = useSelector<
    IAppState,
    TTransactionState
  >((state: IAppState) => state.transactionsState);

  useEffect(() => {
    if (backError) {
      toast.error(
        <Toast text={backError} isError cleanup={() => setBackError("")} />
      );
    }
  }, [backError]);

  const handleClose = () => {
    onClose?.();
  };

  const handleDelete = async () => {
    if (transaction.isCancelled) {
      return;
    }

    setLoading(true);
    try {
      await dispatch(cancelTransaction(transaction.amountTransactionId));
    } catch (e: any) {
      handleError(e?.status, setBackError);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (!transaction) return;

    const { amountTransactionId, isCancelled } = transaction;
    if (!amountTransactionId || !isCancelled) return;

    (async () => {
      setLoading(true);
      try {
        const response: any = await dispatch(
          getTransactionCancellationDetails(amountTransactionId)
        );
        setCancellationDetails(response.data);
      } catch (e: any) {
        handleError(e?.status, setBackError);
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, transaction]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Close onClick={handleClose} className={classes.dialogClose} />
      <DialogTitle disableTypography={true} className={classes.dialogTitle}>
        <TransactionCancelDialogTitle transaction={transaction} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {loading || transactionsState.isLoadingTransactionCancel ? (
          <Loading width={30} />
        ) : (
          <TransactionCancelDialogContent
            loading={loading}
            transaction={transaction}
            cancellationDetails={cancellationDetails}
            onClose={onClose}
            onDelete={handleDelete}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TransactionCancelDialog;
