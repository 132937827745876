import { makeStyles } from "@material-ui/core/styles";

export const usePageHeaderStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 10,
    minHeight: 50,
    padding: theme.spacing(2),
  },
  left: {
    display: "flex",
    flexGrow: 1,
  },
  right: {
    display: "flex",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  }
}));
