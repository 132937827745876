import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Paper } from "@material-ui/core";
import PlayerForm from "./PlayerForm";
import PlayerInfo from "../player/PlayerInfo";
import AddPlayerDialog from "./players/AddPlayerDialog";
import { IPlayersTableRow } from "../../constants/Interfaces";
import { TStoreState } from "../../../Shared/types/Stores";
import { IAppState } from "../../../Shared/store/Store";
import { changeCurrentPlayer, findPlayer } from "../../actions/PlayersActions";
import { handleError } from "../../../Shared/utils/ErrorHandler";
import { getSocket } from "../../../Shared/utils/WebSockets";
import Toast from "../../../Shared/components/utils/Toast";
import { usePlayerTabStyles } from "./PlayerTab.styles";
import { IApiPlayerEntity } from "../../../Shared/types/Players";
import ScanConfirmationModal from "./ScanConfirmationModal";
import { addPlayerToStore } from "../../actions/StoresActions";
import PlayerCommentModal from "./PlayerCommentModal";
import { callPlayerForbiddenPopup, isPlayerForbiddenInEuOrOnDatoOrUn } from "Shared/helpers/Players";

interface IPlayerTab {
  onPlayerSelect: (player: Partial<IPlayersTableRow> | undefined) => void;
}

const PlayerTab: React.FC<IPlayerTab> = ({ onPlayerSelect }) => {
  const classes = usePlayerTabStyles();
  const dispatch = useDispatch();
  const socket = getSocket();

  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );

  const [newPlayerDialog, setNewPlayerDialog] = useState(false);
  const [scanDialog, setScanDialog] = useState(false);
  const [error, setError] = useState<string>();
  const [scanResult, setScanResult] = useState<string[]>();
  const [notFoundId, setNotFoundId] = useState<string>();
  const [commentDialog, setCommentDialog] = useState(false);

  // commentAboutPlayer also opens dialog if value is truthy
  const [commentAboutPlayer, setCommentAboutPlayer] = useState<string>();

  const handleScannerData = (data: string) => {
    let groups = data
      .split(/[\s↵]+/)
      .join("")
      .trim()
      .match(/^I<[A-Z]{3}\d{8}<\d(\d{11})<+.+\d([A-Z]+)<+([A-Z]+)<+/i);
    if (!groups) {
      const [type, unformatedSurename, name, _code, passportCode] = data
        .split("<")
        .filter(Boolean);
      const surename = unformatedSurename.replace("LTU", "");
      const internationalId = passportCode.substr(passportCode.length - 11);
      groups = [type, internationalId, surename, name];
    }
    setScanResult(groups);
    setScanDialog(true);
    handleScannedPlayerComments(String(groups[1]));
  };

  const handleScannedPlayerComments = (identificationNumber: string) => {
    if (storesState.storePlayers) {
      const scannedPlayer = storesState.storePlayers.find(
        (player) => player.nationalIdentificationNumber === identificationNumber
      );
      if (scannedPlayer?.note) {
        setCommentAboutPlayer(scannedPlayer?.note);
      }
    }
  };

  socket.onmessage = (event) => {
    if (!event?.data) return;
    const { data } = event;
    handleScannerData(data);
  };

  useEffect(() => {
    if (!!commentAboutPlayer) setCommentDialog(true);
  }, [commentAboutPlayer]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  const changePlayer = (player: IApiPlayerEntity) => {
    if (player) {
      dispatch(changeCurrentPlayer(player));
      dispatch(
        addPlayerToStore({
          ...(player as IApiPlayerEntity),
          ...(!(player as any).playerName && {
            playerName: `${player.firstName} ${player.lastName}`,
          }),
          latestTransactionInStore: new Date().toISOString(),
        })
      );

      setScanResult(undefined);
      setNotFoundId(undefined);
      if (player.note) {
        setCommentAboutPlayer(player.note);
      }
    }
  };

  const handleSubmit = async (
    nationalIdentificationNumber: string | undefined
  ) => {
    if (!nationalIdentificationNumber) {
      setNewPlayerDialog(true);
      return;
    }

    try {
      if (storesState.selectedStore && storesState.selectedStore.id) {
        let foundPlayer: any;
        foundPlayer = await dispatch(
          findPlayer(
            storesState.selectedStore.id,
            nationalIdentificationNumber,
            changePlayer
          )
        );

        if(isPlayerForbiddenInEuOrOnDatoOrUn(foundPlayer.gamblingEligibility)){
          callPlayerForbiddenPopup(foundPlayer, onPlayerSelect);
        }
      }
    } catch (err: any) {
      // means player was not found open dialog
      if (err?.response?.data.title === "Not Found") {
        setNotFoundId(nationalIdentificationNumber);
        setNewPlayerDialog(true);
        return;
      }
      switch (err?.response?.status) {
        default: {
          handleError(err?.response?.status, setError);
        }
      }
    }
  };

  const handleScanAccept = async () => {
    const nationalIdentificationNumber = String(scanResult?.[1]);
    if (!scanResult) return;

    setScanDialog(false);
    handleSubmit(nationalIdentificationNumber);
  };

  return (
    <>
      <Paper elevation={3} className={classes.container}>
        <PlayerInfo />
        <div className={classes.inputs}>
          <PlayerForm
            onSubmit={handleSubmit}
            onAddNew={() => setNewPlayerDialog(true)}
          />
        </div>
      </Paper>

      {/* modals */}
      <AddPlayerDialog
        open={newPlayerDialog}
        onClose={() => {
          setNewPlayerDialog(false);
          setScanResult(undefined);
          setNotFoundId(undefined);
        }}
        onPlayerSelect={onPlayerSelect}
        scanResult={scanResult}
        notFoundId={notFoundId}
      />

      <ScanConfirmationModal
        open={scanDialog}
        setOpen={setScanDialog}
        onConfirm={() => handleScanAccept()}
        fullName={`${scanResult ? scanResult[3] : ""} 
          ${scanResult ? scanResult[2] : ""}`}
      />

      <PlayerCommentModal
        commentDialog={commentDialog}
        commentAboutPlayer={commentAboutPlayer}
        handleClose={() => {
          setCommentDialog(false);
        }}
        handleOnExited={() => {
          setCommentAboutPlayer(undefined);
        }}
      />
    </>
  );
};

export default PlayerTab;
