import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useScanConfirmationStyles } from "./ScanConfirmationModal.styles";
interface IScanConfirmationModal {
  open: boolean;
  onConfirm: () => void;
  setOpen: (set: boolean) => void;
  fullName: string;
}

const ScanConfirmationModal: React.FC<IScanConfirmationModal> = ({
  open,
  onConfirm,
  setOpen,
  fullName,
}) => {
  const classes = useScanConfirmationStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Close onClick={() => setOpen(false)} className={classes.dialogClose} />
      <DialogTitle disableTypography={true}>
        <Typography align="center" variant="h4">
          {t("scanningCompleted")}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography align="center">
          <Trans
            i18nKey="scanningCompleted_html"
            values={{
              name: fullName,
            }}
            components={{
              bold: <strong />,
            }}
          />
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          gridGap={8}
          justifyContent="space-between"
          mt={2}
        >
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={() => onConfirm()}
            style={{ color: "#fff" }}
          >
            {t("yes")}
          </Button>
          <Button
            className={classes.button}
            color="primary"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            {t("no")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ScanConfirmationModal;
