import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { useTable, Column, TableInstance, useGlobalFilter } from "react-table";
import clsx from "clsx";
import {
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Table,
  Box,
} from "@material-ui/core";
import { Block, HelpOutline } from "@material-ui/icons";
import { PlayerDataStatus, TPlayerState } from "../../../Shared/types/Players";
import { IAppState } from "../../../Shared/store/Store";
import { TStoreState } from "../../../Shared/types/Stores";
import { getStorePlayersList } from "../../actions/StoresActions";
import { PlayersTableKeys, IPlayersTableRow } from "../../constants/Interfaces";
import { usePlayerTableStyles } from "./PlayersTable.styles";
import Toast from "../../../Shared/components/utils/Toast";
import { handleError } from "../../../Shared/utils/ErrorHandler";
import { getPlayersList } from "../../actions/PlayersActions";
import TablePagination from "../../../Shared/components/TablePagination";
import { getPageCount } from "../../../Shared/helpers/Utils";
import Loading from "../../../Shared/components/utils/Loading";
import SearchField from "../../../Shared/components/utils/SearchField";
import PlayerCommentModal from "./PlayerCommentModal";

type Data = object;

interface IPlayersTable {
  columns: Column<Data>[];
  onPlayerSelect: (row: IPlayersTableRow) => void;
}

const PlayersTable: React.FC<IPlayersTable> = ({ columns, onPlayerSelect }) => {
  const classes = usePlayerTableStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  // table
  const [data, setData] = useState<Array<IPlayersTableRow> | []>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [pages, setPages] = useState<Array<number>>();
  //redux state
  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );
  const playersState: TPlayerState = useSelector<IAppState, TPlayerState>(
    (state: IAppState) => state.playersState
  );

  useEffect(() => {
    (async () => {
      if (storesState.selectedStore && storesState.selectedStore.id) {
        try {
          await dispatch(getStorePlayersList(storesState.selectedStore.id, 1));
        } catch (err: any) {
          handleError(err?.status, setError);
        }
      }
    })();
  }, [dispatch, storesState.selectedStore]);

  useEffect(() => {
    (async () => {
      if (!playersState.data) {
        try {
          await dispatch(getPlayersList());
        } catch (err: any) {
          handleError(err?.status, setError);
        }
      }
    })();
  }, [playersState.data, dispatch]);

  useEffect(() => {
    if (storesState.storePlayers && playersState.data) {
      const rowsData = [];
      for (let i = 0; i < storesState.storePlayers?.length; i++) {
        const storePlayer = storesState.storePlayers[i];
        const foundPlayer = playersState.data.find(
          (player) => player.id === storePlayer.playerId
        );
        if (storePlayer && foundPlayer) {
          rowsData.push({
            ...foundPlayer,
            ...storePlayer,
            name: storePlayer.playerName,
            firstName: storePlayer.playerName.split(" ")[0],
            lastName: storePlayer.playerName.split(" ")[1],
            gamblingEligibility: storePlayer.gamblingEligibility,
            birthDate: storePlayer?.birthDate,
            citizenship: storePlayer?.citizenship,
            nationalIdentificationNumber:
              storePlayer?.nationalIdentificationNumber,
            latestTransactionInStore: storePlayer.latestTransactionInStore
          });
        }
      }
      setData(rowsData);
      if (storesState.totalStorePlayersPages != null) {
        getPageCount(storesState.totalStorePlayersPages, setPages);
      }
    }
  }, [
    playersState.data,
    storesState.storePlayers,
    storesState.totalStorePlayersPages,
  ]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<Data>(
      {
        columns,
        data,
      },
      useGlobalFilter
    ) as TableInstance<object>;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(
      (e: React.ChangeEvent<{ value: string }>, storeId: number) =>
        handleSearch?.(e, storeId),
      300
    ),
    []
  );

  const handleChange = async (e: React.ChangeEvent<{ value: string }>) => {
    setSearchQuery(e.target.value);
    if (e.target.value?.length > 2 || !e.target.value.length) {
      if (storesState.selectedStore?.id) {
        debouncedSearch(e, storesState.selectedStore?.id);
      }
    }
  };

  const handleSearch = async (
    e: React.ChangeEvent<{ value: string }>,
    storeId: number
  ) => {
    try {
      await dispatch(getStorePlayersList(storeId, 1, e.target.value));
    } catch (err: any) {
      handleError(err?.status, setError);
    }
  };

  const changePage = async (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
    try {
      if (storesState.selectedStore && storesState.selectedStore.id) {
        await dispatch(
          getStorePlayersList(
            storesState.selectedStore.id,
            newPage,
            searchQuery
          )
        );
      }
    } catch (err: any) {
      handleError(err?.status, setError);
    }
  };

  const handleClear = async () => {
    setSearchQuery("");
    try {
      if (storesState.selectedStore && storesState.selectedStore.id) {
        await dispatch(
          getStorePlayersList(storesState.selectedStore.id, 1, "")
        );
      }
    } catch (err: any) {
      handleError(err?.status, setError);
    }
  };

  const getRowLimitLevelClass = (player: any) => {
    if (
      player[PlayersTableKeys.totalShiftInAmountSum] >= 15000 ||
      player[PlayersTableKeys.totalShiftOutAmountSum] >= 15000
    ) {
      return classes.secondLevelLimit;
    }
    if (
      player[PlayersTableKeys.totalShiftInAmountSum] >= 1000 ||
      player[PlayersTableKeys.totalShiftOutAmountSum] >= 1000
    ) {
      return classes.firstLevelLimit;
    }
    return "";
  };

  return (
    <Paper elevation={3} className={classes.tablePaper}>
      <SearchField
        handleChange={handleChange}
        handleClear={handleClear}
        value={searchQuery}
      />
      <Table {...getTableProps()} stickyHeader className={classes.table}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {!storesState.isPlayersLoading && (
          <TableBody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  className={clsx(
                    getRowLimitLevelClass(row.original),
                    classes.row
                  )}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        onClick={() => {
                          cell.column.id !== "warning" &&
                            onPlayerSelect?.(row.original);
                        }}
                      >
                        <div
                          className={clsx(
                            classes.nameContainer,
                            (row.original[
                              PlayersTableKeys.gamblingEligibility
                            ] !== PlayerDataStatus.Inconclusive &&
                            row.original[
                              PlayersTableKeys.gamblingEligibility
                            ] !== PlayerDataStatus.Allowed) && classes.blocked,
                            row.original[
                              PlayersTableKeys.gamblingEligibility
                            ] === PlayerDataStatus.Inconclusive &&
                              classes.unknown
                          )}
                        >
                          {cell.column.id === "playerName" && (
                            <>
                              {(row.original[
                                PlayersTableKeys.gamblingEligibility
                              ] !== PlayerDataStatus.Allowed &&
                              row.original[
                                PlayersTableKeys.gamblingEligibility
                              ] !== PlayerDataStatus.Inconclusive) && <Block />}
                              {row.original[
                                PlayersTableKeys.gamblingEligibility
                              ] === PlayerDataStatus.Inconclusive && (
                                <HelpOutline />
                              )}
                            </>
                          )}
                          <span className={classes.nameText}>
                            {cell.render("Cell")}
                          </span>
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      {storesState.isPlayersLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ height: "70%", width: "100%" }}
        >
          <Loading width={100} />
        </Box>
      )}
      <TablePagination
        activePage={currentPage}
        pages={pages}
        changePage={changePage}
      />
    </Paper>
  );
};

export default PlayersTable;
