import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

interface ILoading {
  width: number;
  circleColor?: string;
}

const Loading: React.FC<ILoading> = ({ width, circleColor }) => {
  return (
    <CircularProgress
      color="primary"
      style={{ width, height: "auto", color: circleColor ? circleColor : "" }}
    />
  );
};

export default Loading;
