import { makeStyles } from "@material-ui/core/styles";

export const useHeaderInputsStyles = makeStyles((theme) => ({
  smallButton: {
    fontSize: 11,
    marginLeft: theme.spacing(1),
  },
  smallInput: {
    width: 200,
    "& .MuiOutlinedInput-root": {
      fontSize: 12,
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 8,
      paddingBottom: 6,
    },
  },
  inputPlaceholder: {
    "&::placeholder": {
      opacity: 1,
    },
  },
}));
