import React from "react";
import { Paper } from "@material-ui/core";
import { useCashierReportsStyles } from "./CashierReports.styles";

const CashierReports: React.FC = () => {
  const classes = useCashierReportsStyles();

  return (
      <Paper className={classes.paper}>
        <div>reports</div>
      </Paper>
  );
};

export default CashierReports;
