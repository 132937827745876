import { makeStyles } from "@material-ui/core/styles";

export const useTicketDeleteStyles = makeStyles((theme) => ({
  green: {
    color: '#0AAF60',
  },
  red: {
    color: "red",
  },
}));
