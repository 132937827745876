import React, { useState } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import {
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IAppState } from "Shared/store/Store";
import { useHeaderInputs } from "./HeaderInputs.styles";
import { TBettingEventTicketState } from "ToTo/types/BettingEventTickets";

// Helper component for PageHeader input and 2 buttons
interface IHeaderInputs {
  createEvent: () => void;
  deleteTicket: (ticketId?: string) => void;
  extendTicket: (ticketId?: string) => void;
}
const HeaderInputs: React.FC<IHeaderInputs> = ({
  createEvent,
  deleteTicket,
  extendTicket,
}) => {
  const classes = useHeaderInputs();
  const { t } = useTranslation();
  const [ticketId, setTicketId] = useState<string>();
  const [extendTicketId, setExtendTicketId] = useState<string>();

  const bettingEventTicketState: TBettingEventTicketState = useSelector<
    IAppState,
    TBettingEventTicketState
  >((state: IAppState) => state.bettingEventTicketState);

  return (
    <>
      <div className={classes.flex}>
        <TextField
          className={classes.smallInput}
          id="ticketId"
          type="text"
          placeholder={t("ticketId")}
          size="small"
          variant="outlined"
          value={ticketId || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setTicketId(event.target.value)
          }
          InputProps={{
            classes: {
              input: classes.inputPlaceholder,
            },
          }}
        />
        <Button
          className={classes.smallButton}
          disableElevation
          color="primary"
          variant="contained"
          size="small"
          onClick={() => deleteTicket(ticketId)}
        >
          {t("cancelTicket")}
        </Button>
        <Button
          className={classes.smallButton}
          disableElevation
          color="primary"
          variant="contained"
          size="small"
          onClick={() => createEvent()}
        >
          {t("createEvent")}
        </Button>
      </div>
      <div className={clsx(classes.flex, classes.mtCustom)} >
        <TextField
          className={classes.smallInput}
          id="extendTicketId"
          type="text"
          placeholder={t("ticketId")}
          size="small"
          variant="outlined"
          value={extendTicketId || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setExtendTicketId(event.target.value)
          }
          InputProps={{
            classes: {
              input: classes.inputPlaceholder,
            },
          }}
        />
        <Button
          className={classes.buttonLoader}
          disableElevation
          color="primary"
          variant="contained"
          size="small"
          onClick={() => extendTicket(extendTicketId)}
          endIcon={
            bettingEventTicketState.isLoadingExpand ? (
              <CircularProgress className={classes.buttonIcon} />
            ) : (
              <span className={classes.buttonIconPlaceHolder} />
            )
          }
        >
          {t("extendTicket")}
        </Button>
      </div>
    </>
  );
};

export default HeaderInputs;
