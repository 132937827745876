import React from "react";
import { Paper, Typography, withStyles } from "@material-ui/core";
import { usePageHeaderStyles } from "./PageHeader.styles";
import clsx from "clsx";
import Loading from "./utils/Loading";

interface IPageHeader {
  leftSide: React.ReactNode;
  rightSide?: React.ReactNode | null;
  className?: string;
  isLoading?: boolean;
}

export const GreenText = withStyles({
  root: {
    color: "#0AAF60",
    fontSize: 16,
    "&:not(:first-child)": {
      marginLeft: 30,
    },
  },
})(Typography) as typeof Typography;

export const BoldText = withStyles({
  root: {
    fontSize: 14,
    color: "#000",
    fontWeight: "bolder",
    marginLeft: 4,
  },
})(Typography) as typeof Typography;

const PageHeader: React.FC<IPageHeader> = ({
  leftSide,
  rightSide,
  className,
  isLoading,
}) => {
  const classes = usePageHeaderStyles();

  return (
    <Paper elevation={3} className={clsx(classes.root, className)}>
      {isLoading ? (
        <div className={classes.loadingContainer}>
          <Loading width={25} />
        </div>
      ) : (
        <>
          <div className={classes.left}>{leftSide}</div>
          <div className={classes.right}>{rightSide}</div>
        </>
      )}
    </Paper>
  );
};

export default PageHeader;
