import { makeStyles } from "@material-ui/core/styles";

export const useConfirmNobodyWonsStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: "center",
    paddingTop: 0,
  },
  dialogContent: {
    minWidth: 430,
    paddingBottom: theme.spacing(2),
    borderRadius: 10,
  },
  redButton: {
    color: theme.palette.error.main,
  }
}));
