import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import AppSelect from "../containers/AppSelect";
import GamingRouter from "../../Gaming/router/MainRouter";
import ToToRouter from "../../ToTo/router/MainRouter";
import { ROUTES } from "../constants/Routes";
import { TAppState } from "../types/App";
import { APPLICATIONS } from "../constants/Applications";
import { IAppState } from "../store/Store";
import CallBackPage from '../components/CallbackComponent';

function GlobalRouter() {
  const appState: TAppState = useSelector<IAppState, TAppState>(
    (state: IAppState) => state.appState
  );

  return (
    <>
      <CssBaseline />
      <Switch>
        <Route path={ROUTES.APP_SELECT} component={AppSelect} />
        <Route path={ROUTES.CALLBACK} component={CallBackPage} />
        {appState.activeApp === APPLICATIONS.ToTo ? (
          <ToToRouter />
        ) : (
          <GamingRouter />
        )}
      </Switch>
    </>
  );
}

export default GlobalRouter;
