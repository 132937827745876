import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
} from "@material-ui/core";
import { format, isValid } from "date-fns/esm";
import { useTranslation } from "react-i18next";
import { Close, Error } from "@material-ui/icons";
import moment from "moment";
import AddPlayerStatus from "./AddPlayerStatus";
import AddPlayerForm from "./AddPlayerForm";
import {
  IApiPlayerEntity,
  PlayerDataStatus,
  TPlayerState,
} from "../../../../Shared/types/Players";
import { useDispatch, useSelector } from "react-redux";
import { findPlayer, postPlayer } from "../../../actions/PlayersActions";
import { IAppState } from "../../../../Shared/store/Store";
import { IPlayersTableRow } from "../../../constants/Interfaces";
import { useAddPlayerStyles } from "./AddPlayerDialog.styles";
import Toast from "../../../../Shared/components/utils/Toast";
import { handleError } from "../../../../Shared/utils/ErrorHandler";
import {
  IApiStorePlayersEntity,
  TStoreState,
} from "../../../../Shared/types/Stores";
import Loading from "../../../../Shared/components/utils/Loading";
import {
  ID_CODE_LTU,
  CITIZENSHIP_LITHUANIA,
} from "../../../constants/main.constants";
import { getBirthDateFromNationalId } from "../../../../Shared/helpers/Date";
import { addPlayerToStore } from "../../../actions/StoresActions";
import { callPlayerForbiddenPopup, checkLtIdentification, isPlayerForbiddenInEuOrOnDatoOrUn } from "Shared/helpers/Players";
import PlayerCommentModal from "../PlayerCommentModal";

interface IAddPlayerDialog {
  open: boolean;
  onClose: () => void;
  onPlayerSelect: (player: Partial<IPlayersTableRow> | undefined) => void;
  scanResult?: string[];
  notFoundId?: string;
}

interface IDialogError {
  primary: string;
  secondary: string;
}

const AddPlayerDialog: React.FC<IAddPlayerDialog> = ({
  open,
  onClose,
  onPlayerSelect,
  scanResult,
  notFoundId,
}) => {
  // null means no players found
  const [player, setPlayer] = useState<
    Partial<IPlayersTableRow> | undefined | null
  >(undefined);
  const [error, setError] = useState<IDialogError | undefined>();
  const [backendError, setBackendError] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [newPlayer, setNewPlayer] = useState<
    Partial<IApiPlayerEntity> | undefined | null
  >();
  const initialNationalId = scanResult?.length ? scanResult[1] : undefined;
  const [initialPlayerData, setInitialPlayerData] =
    useState<Partial<IApiPlayerEntity>>();
  const classes = useAddPlayerStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const playersState: TPlayerState = useSelector<IAppState, TPlayerState>(
    (state: IAppState) => state.playersState
  );
  const storesState: TStoreState = useSelector<IAppState, TStoreState>(
    (state: IAppState) => state.storesState
  );

  const [commentDialog, setCommentDialog] = useState(false);
  const [commentAboutPlayer, setCommentAboutPlayer] = useState<string>();

  const handleSetPlayerWithBirthDate = useCallback(() => {
    const birthDate = getBirthDateFromNationalId(String(notFoundId));
    if (birthDate) {
      setInitialPlayerData({
        nationalIdentificationNumber: notFoundId,
        birthDate: format(birthDate, "yyyy-MM-dd"),
      });
    } else {
      setInitialPlayerData({
        nationalIdentificationNumber: notFoundId,
      });
    }
  }, [notFoundId]);

  //effect triggers searching manually and not finding a person
  useEffect(() => {
    if (notFoundId) {
      if (checkLtIdentification(notFoundId)) {
        handleSetPlayerWithBirthDate();
      } else {
        setInitialPlayerData({
          nationalIdentificationNumber: notFoundId,
        });
      }
    }
  }, [handleSetPlayerWithBirthDate, notFoundId]);

  useEffect(() => {
    if (playersState.data && newPlayer) {
      const found = playersState.data?.find(
        (p) =>
          p.nationalIdentificationNumber ===
          newPlayer?.nationalIdentificationNumber
      );
      if (found) {
        onPlayerSelect({
          ...found,
          name: `${found.firstName} ${found.lastName}`,
          citizenship: found.citizenship,
          birthDate: found.birthDate,
          nationalIdentificationNumber: found.nationalIdentificationNumber,
          playerId: found.id,
          gamblingEligibility: found.gamblingEligibility,
        });
      } else {
        return;
      }
    }

    //gambling eligibility starts with 0 thats why explicit check for allowed state
    if (player && playersState.data && 
       (playersState.data![0].gamblingEligibility || 
        playersState.data![0].gamblingEligibility === PlayerDataStatus.Allowed)){
      setPlayer({
        ...player,
        gamblingEligibility: playersState.data![0].gamblingEligibility
      });
    }
  }, [newPlayer, onPlayerSelect, playersState.data]);

  useEffect(() => {
    if (backendError) {
      toast.error(
        <Toast
          text={backendError}
          isError
          cleanup={() => setError(undefined)}
        />
      );
    }
  }, [backendError]);

  const handleClose = () => {
    setError(undefined);
    setPlayer(undefined);
    setSubmitting(false);
    setInitialPlayerData(undefined);

    onClose?.();
  };

  const handlePlayerCreate = async (values: IApiPlayerEntity) => {
    if (!playersState.data) return;
    setSubmitting(true);

    if (player) {
      setError({ primary: t("playerDuplicateError"), secondary: "" });
      setSubmitting(false);
      return;
    }
    if (!storesState?.selectedStore || !storesState?.selectedStore.id) return;

    const newPlayer: IApiPlayerEntity = {
      firstName: values.firstName,
      lastName: values.lastName,
      nationalIdentificationNumber: values.nationalIdentificationNumber,
      birthDate: moment(values.birthDate).format("YYYY-MM-DDT00:00:00.000"),
      citizenship: values.citizenship,
      restrictedFromGambling: values.restrictedFromGambling,
      gamblingEligibility: values.gamblingEligibility,
      forced: values.forced,
      alteredManually: !!!scanResult,
    };
    try {
      const result: any = await dispatch(
        postPlayer(storesState.selectedStore.id, newPlayer)
      );
      if (result.data?.id) {
        const playerToStore: IApiStorePlayersEntity = {
          ...newPlayer,
          ...result.data,
          playerId: result.data?.id,
          playerName: `${newPlayer.firstName} ${newPlayer.lastName}`,
          playerFirstName: newPlayer.firstName || "",
          playerLastName: newPlayer.lastName || "",
          storeShiftInAmountSum: 0,
          storeShiftOutAmountSum: 0,
          latestTransactionInStore: new Date().toISOString(),
        };
        dispatch(addPlayerToStore(playerToStore));
      }

      setNewPlayer(newPlayer);
      handleClose();
    } catch (e: any) {
      handleError(e?.status, setBackendError);
    }
    setSubmitting(false);
  };

  const checkPlayer = (player: IApiPlayerEntity, fromScanner: boolean) => {
    if (player) {
      dispatch(
        addPlayerToStore({
          ...(player as IApiPlayerEntity),
          totalShiftInAmountSum: 0,
          storeShiftInAmountSum: 0,
          storeShiftOutAmountSum: 0,
          totalShiftOutAmountSum: 0,
          latestTransactionInStore: new Date().toISOString(),
        })
      );
      setPlayer({
        ...player,
        id: player.playerId,
        name: player.playerName,
      });
    }
  };

  const handleNoPlayerFound = () => {
    if (scanResult?.length) {
      const birthdate = getBirthDateFromNationalId(
        Number(scanResult[1]).toString()
      );
      setInitialPlayerData({
        nationalIdentificationNumber: scanResult[1],
        firstName: scanResult[3],
        lastName: scanResult[2],
        birthDate: isValid(birthdate)
          ? moment(birthdate).format("YYYY-MM-DDT00:00:00.000")
          : undefined,
        citizenship:
          scanResult[0]?.indexOf(ID_CODE_LTU) <= 2
            ? CITIZENSHIP_LITHUANIA
            : undefined,
      });
    }

    setError({
      primary: t("playerNotFound"),
      secondary: t("createPlayerOrRescan"),
    });
    setPlayer(null);
  };

  const handlePlayerCheck = async (values: Partial<IApiPlayerEntity>) => {
    // Lookup for the input player
    if (!playersState.data) return;
    if (!storesState.selectedStore || !storesState.selectedStore.id) return;
    setSubmitting(true);
    setError(undefined);

    try {
      let foundPlayer: any;
      foundPlayer = await dispatch(
        findPlayer(
          storesState.selectedStore.id,
          values.nationalIdentificationNumber,
          (player: IApiPlayerEntity) =>
            checkPlayer(player, !!initialNationalId),
          values.firstName,
          values.lastName,
          values.birthDate
        )
      );

      if(foundPlayer && foundPlayer.note){
        setCommentDialog(true);
        setCommentAboutPlayer(foundPlayer.note);
      }

      if(isPlayerForbiddenInEuOrOnDatoOrUn(foundPlayer.gamblingEligibility)){
        callPlayerForbiddenPopup(foundPlayer, onPlayerSelect);
      }
      
    } catch (err: any) {
      switch (err?.response?.status) {
        case 404: {
          handleNoPlayerFound();
          break;
        }
        default: {
          handleError(err?.response?.status, setBackendError);
          break;
        }
      }
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (open && initialNationalId) {
      handlePlayerCheck({
        nationalIdentificationNumber: String(initialNationalId),
      });
    }
  }, [open, initialNationalId]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Close onClick={handleClose} className={classes.dialogClose} />

      <DialogTitle disableTypography={true}>
        <Typography align="center" variant="h4">
          {notFoundId ? "" : t("lookupPlayer")}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {error && (
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            className={classes.error}
          >
            <Typography variant="subtitle1" className={classes.notFound}>
              <Error className={classes.errorIcon} />
              {error.primary}
            </Typography>
            {error.secondary.length > 0 && (
              <Typography variant="body2">{error.secondary}</Typography>
            )}
          </Grid>
        )}
        {player && (
          <AddPlayerStatus
            player={player}
            onClick={() => onPlayerSelect(player)}
          />
        )}

        {playersState.isLoadingPlayerFind && (
          <>
            <Typography variant="body2" align="center">
              {t("checking")}...
            </Typography>
            <Loading width={40} />
          </>
        )}

        <AddPlayerForm
          player={initialPlayerData}
          canCreateNew={player === null}
          onCreateNew={handlePlayerCreate}
          onCheck={(values: Partial<IApiPlayerEntity>) =>
            handlePlayerCheck(values)
          }
          submitting={submitting}
        />
      </DialogContent>

      {commentDialog ? 
        <PlayerCommentModal
        commentDialog={commentDialog}
        commentAboutPlayer={commentAboutPlayer}
        handleClose={() => {
          setCommentDialog(false);
        }}
        handleOnExited={() => {
          setCommentAboutPlayer(undefined);
        }}/> : null}
      
      
    </Dialog>
  );
};

export default AddPlayerDialog;
