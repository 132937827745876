import React from "react";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

interface ITransactionCancelDialogTitle {
  transaction: any;
}

const TransactionCancelDialogTitle: React.FC<ITransactionCancelDialogTitle> = ({
  transaction,
}) => {
  const { t } = useTranslation();
  if (!transaction) return null;

  if (transaction?.isCancelled) {
    return <Typography variant="h4">{t("transactionCancelled")}</Typography>;
  }
  return (
    <>
      <Typography variant="h4">
        {`${t("date")} ${
          transaction?.createdOn && format(transaction?.createdOn, "yyyy-MM-dd")
        }`}
      </Typography>
      <Typography variant="caption">
        {`${transaction?.name}, ${transaction?.latestTransactionStoreName}`}
      </Typography>
    </>
  );
};

export default TransactionCancelDialogTitle;
