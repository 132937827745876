import { makeStyles } from "@material-ui/core/styles";

export const useNewEventFormStyles = makeStyles((theme) => ({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: theme.spacing(2),
    minWidth: 340,
  },
  fullWidth: {
    gridColumn: "auto / span 2",
  },
  error: {
    color: "red",
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1.5),
    height: 40,
    width: "40%",
    alignSelf: "center",
    color: "#FFF",
    "&.MuiButton-containedPrimary ": {
      color: "white",
    },
    "&.MuiButton-label": {
      display: "flex",
      alignItems: "end",
      fontSize: 14,
    },
  },
  calendarIcon: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  removeIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  participantsCard: {
    minHeight: 150,
    maxHeight: 180,
    overflow: "auto",
    border: `1px solid ${theme.palette.primary.main}`,
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing(1)}px 0`,
    position: "relative",
  },
  participant: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    "& .MuiInputBase-root": {
      borderBottom: "1px #000000 solid",
    },
    marginTop: 1,
  },
  input: {},
  dateSelector: {
    "& .MuiDialogContent-root ": {
      backgroundColor: "white",
    },
    "& .MuiDialogActions-root ": {
      backgroundColor: "white",
    },
  },
  green: {
    color: theme.palette.primary.main,
  },
  newParticipantTooltip: {
    position: "absolute",
    left: "50%",
    top: 45,
  },
}));
