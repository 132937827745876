import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, Grid } from "@material-ui/core";
import { useEventWinnerModalStyles } from "./EventWinnerSelect.styles";
import { EmojiEventsOutlined } from "@material-ui/icons";
import ConfirmModal from "Shared/components/ConfirmModal";
import { IParticipants } from "ToTo/types/BettingEvents";

interface IEventWinnerConfirm {
  open: boolean;
  winner?: IParticipants;
  nonParticipants: IParticipants[];
  onConfirm: () => void;
  onClose: () => void;
}
const EventWinnerConfirm: React.FC<IEventWinnerConfirm> = ({
  open,
  winner,
  nonParticipants,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation();
  const classes = useEventWinnerModalStyles();

  return (
    <ConfirmModal
      open={open}
      title={<Typography variant="h5">{t("winner")}</Typography>}
      onConfirm={onConfirm}
      onClose={onClose}
    >
      {winner && (
        <Grid container justify="center" alignItems="flex-end">
          <Typography>{`${winner.number}. ${winner.name}`}</Typography>
          <EmojiEventsOutlined className={classes.throphyIcon} />
        </Grid>
      )}
      {nonParticipants && (
        <>
          <Box mt={2} mb={1}>
            <Typography align="center" variant="h5">
              {t("nonParticipants")}
            </Typography>
          </Box>
          <Grid
            direction="column"
            container
            alignItems="center"
            justify="flex-end"
          >
            {nonParticipants.map((np) => (
              <Typography>{`${np.number}. ${np.name}`}</Typography>
            ))}
          </Grid>
        </>
      )}
    </ConfirmModal>
  );
};

export default EventWinnerConfirm;
