export const API_END_POINTS = {
  // Players
  PLAYERS: "/players",
  PLAYERS_STORE: (storeId?: number) => `/players/${storeId}`,
  PLAYER_EDIT: (playerId: number) => `/players/${playerId}`,
  PLAYER: (id: number) => `/players/${id}`,
  PLAYER_FIND: (storeId: number | undefined) => `/players/${storeId}/find`,
  PLAYER_FALSE_ALARM: (storeId: number | undefined) => `players/${storeId}/check/gambling/continue`,
  // Stores
  STORES: "/stores",
  AVAILABLE_STORES: "/stores/available",
  STORE_PLAYERS: "/storeplayers",
  // Transactions
  TRANSACTIONS: `/transactions`,
  TRANSACTION: (id: number) => `/transactions/${id}`,
  PLAYER_TRANSACTIONS: (id: number) => `/transactions/player/${id}`,
  CANCEL_TRANSACTION: (id: number) => `/transactions/${id}/cancel`,
  PLAYER_TRANSACTIONS_EXPORT: (id: number) =>
    `/transactions/player/${id}/export`,
  TRANSACTION_CANCELLATION_DETAILS: (id: number) =>
    `/transactions/${id}/cancellationDetails`,
  PLAYER_TRANSACTIONS_STATISTICS: (id: number) =>
    `/transactions/player/${id}/statistics`,
  TRANSACTIONS_EXPORT: `/transactions/export`,
  // Analysis
  ANALYSIS: `/analysis`,
  ANALYSIS_STATS: "analysis/statistics",
  // Users
  USERS: `/users`,
  USERS_EDIT: (id: number) => `/users/${id}`,
  USERS_CHANGE_PWD: (userId: number) => `users/${userId}/password`,
};

export const API_ERRORS = {
  ID_SHOULD_BE_CHECKED: "DailyAmountExceededIdShouldBeChecked",
  USERNAME_EXISTS: "USERNAME-EXISTS",
};
