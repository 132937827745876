import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Paper, Grid, Typography, Tooltip } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import PageHeader from "../../../Shared/components/PageHeader";
import { useCashierEventsStyles } from "./CashierEvents.styles";
import { useTranslation } from "react-i18next";
import EventsTable from "../../components/EventsTable";
import { CashierEventsTableKeys } from "../../constants/Interfaces";
import CashierEventWinnerSelect from "../../components/cashier/Events/CashierEventWinnerSelect";
import CashierTakeBetModal from "../../components/cashier/CashierTakeBetModal";
import HeaderInputs from "../../components/header/HeaderInputs";
import { getBettingEvents } from "../../actions/BettingEventsActions";
import { TBettingEventState, IBettingEvent } from "../../types/BettingEvents";
import { IAppState } from "../../../Shared/store/Store";
import Toast from "../../../Shared/components/utils/Toast";
import { handleError } from "../../../Shared/utils/ErrorHandler";
import { AdminEventStatus } from "../../constants/Interfaces";
import TicketCheckDialog from "../../components/cashier/Events/TicketCheckDialog";
import { checkBettingEventTicket } from "../../actions/BettingEventTicketActions";
import { format } from "date-fns";

const Events: React.FC = () => {
  const classes = useCashierEventsStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<IBettingEvent>();
  const [betModalOpen, setBetModalOpen] = useState(false);
  const [eventModalFromTable, setEventModalFromTable] = useState(false);
  const [openCheckTicketDialog, setOpenCheckTicketDialog] = useState<boolean>(
    false
  );
  const [ticketId, setTicketId] = useState<string>();
  const [error, setError] = useState("");
  const [tableData, setTableData] = useState<Array<IBettingEvent>>([]);

  const bettingEventState: TBettingEventState = useSelector<
    IAppState,
    TBettingEventState
  >((state: IAppState) => state.bettingEventsState);

  const filterTableData = (data: Array<IBettingEvent>) => {
    const newData = [...data];
    return newData.filter((event) => event.status === AdminEventStatus.Active);
  };

  useEffect(() => {
    (async () => {
      if (!bettingEventState.eventsTableData) {
        try {
          await dispatch(getBettingEvents());
        } catch (err: any) {
          handleError(err?.status, setError);
        }
      }
      if (bettingEventState.eventsTableData) {
        const filtered = filterTableData(bettingEventState.eventsTableData);
        setTableData(filtered);
      }
    })();
  }, [dispatch, bettingEventState.eventsTableData]);

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  const columns = [
    {
      Header: `${t("title")}`,
      accessor: CashierEventsTableKeys.title,
      Cell: (cell: any) => (
        <Typography className={classes.big}>{cell.value}</Typography>
      ),
    },
    {
      Header: t("status"),
      accessor: CashierEventsTableKeys.status,
      Cell: (cell: any) => (
        <Typography className={classes.small}>
          {t(`adminEventStatuses.${cell.value}`)}
        </Typography>
      ),
    },
    {
      Header: t("fixedBidSum"),
      accessor: CashierEventsTableKeys.ticketPrice,
      Cell: (cell: any) => (
        <Typography className={classes.small}>{cell.value}€</Typography>
      ),
    },
    {
      Header: `${t("eventDate")}`,
      accessor: CashierEventsTableKeys.startsOn,
      Cell: (cell: any) => (
        <Typography className={classes.small}>
          {format(new Date(cell.value), "yyyy-MM-dd HH:mm:ss")}
        </Typography>
      ),
    },
    {
      Header: `${t("betsEndAt")}`,
      accessor: CashierEventsTableKeys.bettingEndsOn,
      Cell: (cell: any) => (
        <Typography className={classes.small}>
          {format(new Date(cell.value), "yyyy-MM-dd HH:mm:ss")}
        </Typography>
      ),
      headerStyle: {
        backgroundColor: "red",
      },
    },
    {
      Header: "",
      id: "add",
      Cell: (cellInfo: any) => (
        <Grid container alignItems="center">
          <Button
            onClick={() => {
              setSelectedRow(cellInfo.row.original);
              setEventModalFromTable(true);
            }}
          >
            <Tooltip title={`${t("selectAWinner")}`}>
              <AddCircleOutline className={classes.green} />
            </Tooltip>
          </Button>
        </Grid>
      ),
    },
  ];

  const CheckTicket = async () => {
    try {
      if (ticketId) {
        await dispatch(checkBettingEventTicket(ticketId));
        setOpenCheckTicketDialog(true);
      }
    } catch (err: any) {
      switch (err?.status) {
        case 404: {
          setError(t("noTicket"));
          break;
        }
        default: {
          handleError(err?.response?.status, setError);
          break;
        }
      }
    }
  };

  return (
    <>
      <CashierTakeBetModal
        open={betModalOpen}
        onClose={() => setBetModalOpen(false)}
        setSelectedEvent={setSelectedRow}
        events={tableData}
      />
      <PageHeader
        leftSide={
          <HeaderInputs
            setBetModalOpen={setBetModalOpen}
            inputPlaceHolder={t("ticketId")}
            inputId="ticketId"
            firstButtonLabel={t("checkTicket")}
            secondButtonLabel={t("acceptBet")}
            inputValue={ticketId}
            handleFirstButton={CheckTicket}
            inputChange={(value: string) => setTicketId(value)}
          />
        }
        rightSide={null}
      />
      <Paper elevation={3} className={classes.paper}>
        <EventsTable columns={columns} events={tableData} />
      </Paper>
      <CashierEventWinnerSelect
        onClose={() => {
          setSelectedRow(undefined);
          setEventModalFromTable(false);
        }}
        selectedEvent={selectedRow}
        openBetModal={() => setBetModalOpen(true)}
        closeBetModal={() => setBetModalOpen(false)}
        openedFromTable={eventModalFromTable}
      />
      {
        openCheckTicketDialog && 
        <TicketCheckDialog
        open={openCheckTicketDialog}
        handleClose={() => setOpenCheckTicketDialog(false)}
      />
      }
    </>
  );
};

export default Events;
