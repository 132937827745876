import { makeStyles } from "@material-ui/core/styles";

export const useAdminTableFiltersStyles = makeStyles((theme) => ({
  iconPrimary: {
    color: theme.palette.primary.main,
  },

  selectionForm: {
    width: 160,
    "& .MuiOutlinedInput-root": {
      fontSize: 12,
    },
  },
  selectionLabel: {
    fontSize: 12,
  },
  selection: {
    fontSize: 12,
  },

  dateBox: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingRight: 0,
    fontSize: 12,
    width: 135,
    "& .MuiIconButton-root": {
      paddingLeft: "0 !important",
    },
  },

  exportCSV: {
    marginLeft: "auto",
    textTransform: "none",
    "&.MuiButton-outlined": {
      color: "#959595",
    },
    "&.MuiButton-outlined .MuiButton-startIcon": {
      color: "#959595",
    },
  },
  clearFilters: {
    lineHeight: "100%",
    fontSize: 12,
    height: 35,
    width: 170,
    padding: 0,
    paddingTop: 2,
    "&.MuiButton-outlined .MuiButton-startIcon": {
      color: theme.palette.primary.main,
    },
  },
  checkboxLabel: {
    fontSize: 14,
  },
  arrowIcon: {
    color: "#DADADA !important",
  },

  tableSearchSimple: {
    fontSize: 12,
    borderBottom: "1px solid #C4C4C4",
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 165,
  },
}));
