export const ROUTES = {
  CASHIER_MAIN: "/",
  ADMIN: {
    MAIN: "/admin",
    ADMINISTRATION: "/administration",
    PLAYERS: "/admin/players",
    WORKERS: "/admin/workers",
    SETTINGS: "/admin/settings",
    // Single player view
    PLAYER: "/admin/player/:id",
    PLAYER_NO_ID: "/admin/player",
  },
};
