import { AxiosError, AxiosResponse } from "axios";
import { IApiStorePlayersEntity } from "./Stores";

export interface IApiAnalysisPlayersEntity extends IApiStorePlayersEntity {
  shift: string;
  latestTransactionStoreId: number;
  latestTransactionStoreName: string;
}

export interface TAnalysisState {
  readonly data?: Array<IApiAnalysisPlayersEntity>;
  readonly totalPages?: number;
  readonly isLoading?: boolean;
  readonly totalPlayerCount?: number;
  readonly page?: number;
  readonly searchQuery?: string;
  readonly uniquePlayersCount?: number;
  readonly newPlayersCount?: number;
  readonly totalInAmount?: number;
  readonly totalOutAmount?: number;
  readonly isLoadingStats?: boolean;
}

export const initialAnalysisState: TAnalysisState = {
  data: undefined,
  totalPages: 0,
  isLoading: false,
  searchQuery: "",
  totalPlayerCount: 0,
  page: 1,
  uniquePlayersCount: 0,
  newPlayersCount: 0,
  totalInAmount: 0,
  totalOutAmount: 0,
  isLoadingStats: false,
};

export interface IGetAnalysisPlayers {
  data: Array<IApiAnalysisPlayersEntity>;
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
  searchQuery: string;
}

export interface IAnalysisStats {
  uniquePlayersCount: number;
  newPlayersCount: number;
  totalInAmount: number;
  totalOutAmount: number;
}

export enum GetAnalysisPlayers {
  REQUEST = "ANALYSIS_PLAYERS_GET_REQUEST",
  SUCCESS = "ANALYSIS_PLAYERS_GET_SUCCESS",
  FAILED = "ANALYSIS_PLAYERS_GET_FAILED",
}

export enum GetAnalysisStats {
  REQUEST = "ANALYSIS_STATS_GET_REQUEST",
  SUCCESS = "ANALYSIS_STATS_GET_SUCCESS",
  FAILED = "ANALYSIS_STATS_GET_FAILED",
}

export type TGetAnalysisStatsAction =
  | {
      type: GetAnalysisStats.REQUEST;
      page: number;
      searchQuery: string;
    }
  | {
      type: GetAnalysisStats.SUCCESS;
      payload: AxiosResponse<IAnalysisStats>;
    }
  | {
      type: GetAnalysisStats.FAILED;
      payload: AxiosError;
    };

export type TGetAnalysisPlayersAction =
  | {
      type: GetAnalysisPlayers.REQUEST;
      page: number;
      searchQuery: string;
    }
  | {
      type: GetAnalysisPlayers.SUCCESS;
      payload: AxiosResponse<IGetAnalysisPlayers>;
    }
  | {
      type: GetAnalysisPlayers.FAILED;
      payload: AxiosError;
    };

export type TAnalysisActions = TGetAnalysisPlayersAction | TGetAnalysisStatsAction;
