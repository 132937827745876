import { AxiosError, AxiosResponse } from "axios";

export interface IApiTransactionEntity {
  id?: number;
  userId?: number;
  playerId?: number;
  storeId?: number;
  inAmount: number; //bidSum
  outAmount: number; //cashInSum
  createdOn?: string;
  updatedOn?: string;
  isCancelled?: boolean;
  hasToBeChecked?: boolean;
}

export interface IApiPlayerTransactionEntity extends IApiTransactionEntity {
  storeName: string;
  playerName: string;
  amountTransactionId: number;
}

export interface IAllTransactionExportFilters {
  StoreId?: number;
  MinDate?: string;
  MaxDate?: string;
  MinSumPerShift?: number;
  PageNumber?: number;
  Limit?: number;
  Total?: number;
}

export interface IApiTransactionCancellationDetails {
  id: number;
  cancelledOn: string;
  createdOn: string;
  inAmount: number;
  outAmount: number;
  userName: string;
}

export interface IApiTransactionsStatisticsEntity {
  totalInAmount: number;
  totalOutAmount: number;
}

export interface TTransactionState {
  readonly data?: Array<IApiTransactionEntity>;
  readonly playerTransactions?: Array<IApiPlayerTransactionEntity>;
  readonly selectedPlayerId?: number;
  readonly totalPages?: number;
  readonly playerTransactionsStatistics?: IApiTransactionsStatisticsEntity;
  readonly isLoading?: boolean;
  readonly isPlayerStatisticsLoading?: boolean;
  readonly isLoadingTransactionCancel?: boolean;
  readonly page?: number;
}

export interface IGetTransactionData {
  data: IApiPlayerTransactionEntity[];
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export const initialTransactionState: TTransactionState = {
  data: undefined,
  playerTransactions: undefined,
  selectedPlayerId: undefined,
  totalPages: undefined,
  playerTransactionsStatistics: undefined,
  isLoadingTransactionCancel: false,
  page: 1,
};

export enum TransactionsGet {
  REQUEST = "TRANSACTIONS_GET_REQUEST",
  SUCCESS = "TRANSACTIONS_GET_SUCCESS",
  FAILED = "TRANSACTIONS_GET_FAILED",
}

export enum PlayerTransactionsGet {
  REQUEST = "PLAYER_TRANSACTIONS_GET_REQUEST",
  SUCCESS = "PLAYER_TRANSACTIONS_GET_SUCCESS",
  FAILED = "PLAYER_TRANSACTIONS_GET_FAILED",
}

export enum PlayerTransactionDetails {
  REQUEST = "PLAYER_TRANSACTION_DETAILS_GET_REQUEST",
  SUCCESS = "PLAYER_TRANSACTION_DETAILS_GET_SUCCESS",
  FAILED = "PLAYER_TRANSACTIO_DETAILSS_GET_FAILED",
}

export enum PlayerTransactionsExport {
  REQUEST = "PLAYER_TRANSACTIONS_EXPORT_REQUEST",
  SUCCESS = "PLAYER_TRANSACTIONS_EXPORT_SUCCESS",
  FAILED = "PLAYER_TRANSACTIONS_EXPORT_FAILED",
}

export enum PlayerTransactionsStatisticsGet {
  REQUEST = "PLAYER_TRANSACTIONS_STATISTICS_GET_REQUEST",
  SUCCESS = "PLAYER_TRANSACTIONS_STATISTICS_GET_SUCCESS",
  FAILED = "PLAYER_TRANSACTIONS_STATISTICS_GET_FAILED",
}

export enum TransactionGet {
  REQUEST = "TRANSACTION_GET_REQUEST",
  SUCCESS = "TRANSACTION_GET_SUCCESS",
  FAILED = "TRANSACTION_GET_FAILED",
}

export enum TransactionsPost {
  REQUEST = "TRANSACTION_POST_REQUEST",
  SUCCESS = "TRANSACTION_POST_SUCCESS",
  FAILED = "TRANSACTION_POST_FAILED",
}

export enum TransactionPut {
  REQUEST = "TRANSACTION_PUT_REQUEST",
  SUCCESS = "TRANSACTION_PUT_SUCCESS",
  FAILED = "TRANSACTION_PUT_FAILED",
}

export enum TransactionCancel {
  REQUEST = "TRANSACTION_CANCEL_REQUEST",
  SUCCESS = "TRANSACTION_CANCEL_SUCCESS",
  FAILED = "TRANSACTION_CANCEL_FAILED",
}

export type TTransactionCancelAction =
  | {
      type: TransactionCancel.REQUEST;
    }
  | {
      type: TransactionCancel.SUCCESS;
      payload: AxiosResponse<IApiTransactionEntity>;
    }
  | {
      type: TransactionCancel.FAILED;
      payload: AxiosError;
    };

export type TTransactionPostAction =
  | {
      type: TransactionsPost.REQUEST;
    }
  | {
      type: TransactionsPost.SUCCESS;
      payload: AxiosResponse<IApiTransactionEntity>;
    }
  | {
      type: TransactionsPost.FAILED;
      payload: AxiosError;
    };

export type TTransactionsGetActions =
  | {
      type: TransactionsGet.REQUEST;
      page: number;
    }
  | {
      type: TransactionsGet.SUCCESS;
      payload: AxiosResponse<Array<IApiTransactionEntity>>;
    }
  | {
      type: TransactionsGet.FAILED;
      payload: AxiosError;
    };

export type TPlayerTransactionsGetActions =
  | {
      type: PlayerTransactionsGet.REQUEST;
      page: number;
    }
  | {
      type: PlayerTransactionsGet.SUCCESS;
      payload: AxiosResponse<IGetTransactionData>;
    }
  | {
      type: PlayerTransactionsGet.FAILED;
      payload: AxiosError;
    };

export type TPlayerTransactionsStatisticsGetActions =
  | {
      type: PlayerTransactionsStatisticsGet.REQUEST;
    }
  | {
      type: PlayerTransactionsStatisticsGet.SUCCESS;
      payload: AxiosResponse<IApiTransactionsStatisticsEntity>;
    }
  | {
      type: PlayerTransactionsStatisticsGet.FAILED;
      payload: AxiosError;
    };

export type TTransactionGetActions =
  | {
      type: TransactionGet.REQUEST;
    }
  | {
      type: TransactionGet.SUCCESS;
      payload: AxiosResponse<IApiTransactionEntity>;
    }
  | {
      type: TransactionGet.FAILED;
      payload: AxiosError;
    };

export type TTransactionPutActions =
  | {
      type: TransactionPut.REQUEST;
    }
  | {
      type: TransactionPut.SUCCESS;
      payload: AxiosResponse<IApiTransactionEntity>;
    }
  | {
      type: TransactionPut.FAILED;
      payload: AxiosError;
    };

export type TTransactionActions =
  | TTransactionsGetActions
  | TPlayerTransactionsGetActions
  | TPlayerTransactionsStatisticsGetActions
  | TTransactionGetActions
  | TTransactionPostAction
  | TTransactionCancelAction
  | TTransactionPutActions;
