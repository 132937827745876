import { AxiosError, AxiosResponse } from "axios";
import { STORE_ITEM } from "../constants/Storage";
import { PlayerDataStatus } from "./Players";
import { IApiTransactionEntity } from "./Transactions";

export interface IApiStoreEntity {
  name: string | null;
  address: string | null;
  id: number | undefined;
}

export interface IApiStorePlayersEntity {
  playerId: number;
  playerName: string;
  playerFirstName: string;
  playerLastName: string;
  totalShiftInAmountSum: number;
  storeShiftInAmountSum: number;
  storeShiftOutAmountSum: number;
  totalShiftOutAmountSum: number;
  latestTransactionInStore: string;
  gamblingEligibility: PlayerDataStatus;
  birthDate: string;
  nationalIdentificationNumber: string | null | undefined;
  citizenship: string | null;
  id: number;
  note?: string;
}

export interface IGetStorePlayersData {
  data: IApiStorePlayersEntity[];
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export interface TStoreState {
  readonly data?: Array<IApiStoreEntity>;
  readonly storePlayers?: Array<IApiStorePlayersEntity>;
  readonly selectedStore?: IApiStoreEntity;
  readonly isLoading?: boolean;
  readonly totalStorePlayersPages?: number;
  readonly isPlayersLoading?: boolean;
}

const selectedStoreItem = localStorage.getItem(STORE_ITEM);
export const initialStoresState: TStoreState = {
  data: undefined,
  selectedStore: selectedStoreItem ? JSON.parse(selectedStoreItem) : undefined,
  storePlayers: undefined,
  isLoading: false,
  totalStorePlayersPages: 0,
  isPlayersLoading: false,
};

export enum StoresGet {
  REQUEST = "STORES_GET_REQUEST",
  SUCCESS = "STORES_GET_SUCCESS",
  FAILED = "STORES_GET_FAILED",
}

export enum AvailableStoresGet {
  REQUEST = "AVAILABLE_STORES_GET_REQUEST",
  SUCCESS = "AVAILABLE_STORES_GET_SUCCESS",
  FAILED = "AVAILABLE_STORES_GET_FAILED",
}

export enum UpdateStorePlayerTransaction {
  UPDATE_TRANSACTION = "STORE_PLAYERS_UPDATE_TRANSACTION",
}

export enum AddPlayerToStore {
  ADD_PLAYER = "ADD_PLAYER_TO_STORE",
}

export enum StoreGetPlayers {
  REQUEST = "STORE_PLAYERS_GET_REQUEST",
  SUCCESS = "STORE_PLAYERS_GET_SUCCESS",
  FAILED = "STORE_PLAYERS_GET_FAILED",
}

export enum EditCurrentStore {
  EDIT = "CURRENT_STORE_EDIT",
}

export type TEditCurrentStore = {
  type: EditCurrentStore.EDIT;
  payload: IApiStoreEntity;
};

export type TAddPlayerToStoreAction = {
  type: AddPlayerToStore.ADD_PLAYER;
  payload: IApiStorePlayersEntity;
};

export type TUpdatePlayerTransactionAction = {
  type: UpdateStorePlayerTransaction.UPDATE_TRANSACTION;
  payload: IApiTransactionEntity;
};

export type TGetAvailableStoresActions =
  | {
      type: AvailableStoresGet.REQUEST;
    }
  | {
      type: AvailableStoresGet.SUCCESS;
      payload: AxiosResponse<Array<IApiStoreEntity>>;
    }
  | {
      type: AvailableStoresGet.FAILED;
      payload: AxiosError;
    };

export type TGetStoresActions =
  | {
      type: StoresGet.REQUEST;
    }
  | {
      type: StoresGet.SUCCESS;
      payload: AxiosResponse<Array<IApiStoreEntity>>;
    }
  | {
      type: StoresGet.FAILED;
      payload: AxiosError;
    };

export type TGetStorePlayersAction =
  | {
      type: StoreGetPlayers.REQUEST;
    }
  | {
      type: StoreGetPlayers.SUCCESS;
      payload: AxiosResponse<IGetStorePlayersData>;
    }
  | {
      type: StoreGetPlayers.FAILED;
      payload: AxiosError;
    };

export type TStoresActions =
  | TGetStoresActions
  | TGetStorePlayersAction
  | TUpdatePlayerTransactionAction
  | TGetAvailableStoresActions
  | TAddPlayerToStoreAction
  | TEditCurrentStore;
