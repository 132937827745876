import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format, isToday } from "date-fns";
import { Typography, Button, Tooltip, Paper } from "@material-ui/core";
import { CancelOutlined, Block } from "@material-ui/icons";
import { useAdminSinglePlayerStyles } from "../../../containers/admin/AdminSinglePlayer.styles";
import { handleError } from "../../../../Shared/utils/ErrorHandler";
import {
  AdminFilterStorage,
  IAdminMoneyFilter,
  IPlayerTableWrapperFilter,
  IPlayerTransactionsTableRow,
  PlayersTableKeys,
} from "../../../constants/Interfaces";
import { IAdminDateFilter } from "Shared/constants/Table";
import PlayersTable from "../players/PlayersTable";
import { IAppState } from "../../../../Shared/store/Store";
import { IApiPlayerEntity } from "../../../../Shared/types/Players";
import {
  IApiPlayerTransactionEntity,
  TTransactionState,
} from "../../../../Shared/types/Transactions";
import { getPlayerTransactionsList } from "../../../actions/TransactionsActions";
import Toast from "../../../../Shared/components/utils/Toast";
import { getPageCount } from "../../../../Shared/helpers/Utils";
import AdminTableFilters from "../players/tableHelpers/AdminTableFilters";
import TableMoneyCell from "../players/tableHelpers/TableMoneyCell";
import { ReactComponent as IdDocument } from "../../../../Shared/assets/icons/user_document.svg";
import { STORAGE_ITEMS } from "Gaming/constants/main.constants";

const formateTableData = (
  transactions: Array<IApiPlayerTransactionEntity>,
  player: IApiPlayerEntity
) => {
  const rowsData = [];
  for (let i = 0; i < transactions.length; i++) {
    const transaction = transactions[i];
    rowsData.push({
      ...transaction,
      firstName: player.firstName,
      lastName: player.lastName,
      name: `${player.firstName} ${player.lastName}`,
      nationalIdentificationNumber: player.nationalIdentificationNumber,
      status: player.gamblingEligibility,
      latestTransactionInStore: transaction.createdOn
        ? transaction.createdOn
        : null,
      latestTransactionStoreName: transaction.storeName,
      wasEdited: transaction.createdOn !== transaction.updatedOn,
      bidSum: transaction.inAmount > 0 ? transaction.inAmount : undefined,
      cashInSum: transaction.outAmount > 0 ? transaction.outAmount : undefined,
      createdOn: transaction.createdOn
        ? new Date(transaction.createdOn)
        : undefined,
      updatedOn: transaction.updatedOn
        ? new Date(transaction.updatedOn)
        : undefined,
    });
  }
  return rowsData;
};

interface ITableWrapper {
  player?: IApiPlayerEntity;
  cancelTransaction: (row: any) => void;
  onFilterChange?: (value: IPlayerTableWrapperFilter) => void;
}

const TableWrapper: React.FC<ITableWrapper> = ({
  player,
  cancelTransaction,
  onFilterChange,
}) => {
  const { t } = useTranslation();
  const classes = useAdminSinglePlayerStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();

  //table state
  const [tableData, setTableData] = useState<
    Partial<IPlayerTransactionsTableRow>[]
  >([]);
  const [pages, setPages] = useState<Array<number>>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  //table filters state
  const [storeFilter, setStoreFilter] = useState<number>(
    Number(localStorage.getItem(AdminFilterStorage.Site))
  );

  const storageMoney = localStorage.getItem(AdminFilterStorage.Money);
  const [moneyFilter, setMoneyFilter] = useState<IAdminMoneyFilter>(
    storageMoney ? JSON.parse(storageMoney) : { "10kSum": false, "15k": false }
  );

  //redux state
  const transactionsState: TTransactionState = useSelector<
    IAppState,
    TTransactionState
  >((state: IAppState) => state.transactionsState);

  const formateDateFilter = () => {
    const shift = localStorage.getItem(
      STORAGE_ITEMS.ADMIN_PLAYERS_FILTERS.SELECTED_SHIFT
    );
    if (shift) {
      const formatedShift = new Date(
        `${localStorage.getItem(
          STORAGE_ITEMS.ADMIN_PLAYERS_FILTERS.SELECTED_SHIFT
        )}Z`
      );
      return {
        from: new Date(formatedShift.setHours(10, 0, 0)),
        to: new Date(
          new Date(formatedShift.setDate(formatedShift.getDate() + 1)).setHours(
            9,
            59,
            59
          )
        ),
      };
    }
    return;
  };

  const [dateFilter, setDateFilter] = useState<
    Partial<IAdminDateFilter> | undefined
  >(formateDateFilter());

  useEffect(() => {
    if (error) {
      toast.error(<Toast text={error} isError cleanup={() => setError("")} />);
    }
  }, [error]);

  // get initial player transactions
  useEffect(() => {
    (async () => {
      try {
        if (player?.id) {
          await dispatch(
            getPlayerTransactionsList(
              player.id,
              1,
              storeFilter || undefined,
              dateFilter?.from as Date,
              dateFilter?.to as Date,
              moneyFilter["1k"] ? 1000 : moneyFilter["15k"] ? 15000 : undefined
            )
          );
        }
      } catch (err: any) {
        handleError(err?.status, setError);
      }
    })();
  }, [dispatch]);

  // this effect triggers when filters are changed
  useEffect(() => {
    (async () => {
      try {
        if (player?.id) {
          await dispatch(
            getPlayerTransactionsList(
              player.id,
              1,
              storeFilter || undefined,
              dateFilter?.from as Date,
              dateFilter?.to as Date,
              moneyFilter["1k"] ? 1000 : moneyFilter["15k"] ? 15000 : undefined
            )
          );
        }
      } catch (err: any) {
        handleError(err?.status, setError);
      }
    })();
  }, [
    dispatch,
    storeFilter,
    dateFilter?.from,
    dateFilter?.to,
    moneyFilter,
    player?.id,
  ]);

  // format table data
  useEffect(() => {
    if (
      player &&
      transactionsState.playerTransactions &&
      transactionsState.playerTransactions?.length >= 0
    ) {
      const rowsData = formateTableData(
        transactionsState.playerTransactions,
        player
      );
      if (rowsData) {
        setTableData(rowsData);
      }
      if (transactionsState.totalPages != null) {
        getPageCount(transactionsState.totalPages, setPages);
      }
    }
  }, [
    player,
    transactionsState.playerTransactions,
    transactionsState.totalPages,
  ]);

  const columns = useMemo(
    () => [
      {
        Header: `${t("name")}`,
        id: PlayersTableKeys.playerName,
        accessor: PlayersTableKeys.playerName,
        Cell: (cell: any) => {
          const { gamblingCheckResult } = cell?.row?.original;

          return (
            <div className={classes.playerName}>
              {!Number.isNaN(gamblingCheckResult) &&
                gamblingCheckResult !== null && (
                  <IdDocument
                    className={clsx(classes.documentIcon, classes.green)}
                  />
                )}
              <Typography className={classes.big}>{cell.value}</Typography>
            </div>
          );
        },
      },
      {
        Header: t("bidSum"),
        id: PlayersTableKeys.inAmount,
        accessor: PlayersTableKeys.inAmount,
        Cell: (cell: any) => <TableMoneyCell sum={cell.value} />,
      },
      {
        Header: t("cashInSum"),
        id: PlayersTableKeys.outAmount,
        accessor: PlayersTableKeys.outAmount,
        Cell: (cell: any) => <TableMoneyCell sum={cell.value} />,
      },
      {
        Header: `${t("transaction")}`,
        id: PlayersTableKeys.latestTransactionInStore,
        accessor: PlayersTableKeys.latestTransactionInStore,
        Cell: (cell: any) => {
          const date = cell.value.endsWith("Z")
          ? new Date(cell.value.slice(0, -1))
          : new Date(cell.value);
          return (
            <Typography className={classes.small}>
              {isToday(date)
                ? format(date, "HH:mm:ss")
                : format(date, "yyyy-MM-dd HH:mm:ss")}
            </Typography>
          );
        },
      },
      {
        Header: `${t("lastSite")}`,
        id: PlayersTableKeys.latestTransactionStoreName,
        accessor: PlayersTableKeys.latestTransactionStoreName,
        Cell: (cell: any) => (
          <Typography className={classes.small}>{cell.value}</Typography>
        ),
      },
      {
        Header: "",
        id: "warning",
        Cell: (tableInfo: any) => {
          const { isCancelled, gamblingCheckResult } = tableInfo?.row?.original;

          if (
            !Number.isNaN(gamblingCheckResult) &&
            gamblingCheckResult !== null
          )
            return null;

          return (
            <Button onClick={() => cancelTransaction?.(tableInfo.row.original)}>
              <Tooltip
                title={`${
                  isCancelled ? t("transactionCancelled") : t("cancel")
                }`}
              >
                {isCancelled ? (
                  <Block className={classes.orange} />
                ) : (
                  <CancelOutlined className={classes.error} />
                )}
              </Tooltip>
            </Button>
          );
        },
      },
    ],
    [
      cancelTransaction,
      classes.big,
      classes.documentIcon,
      classes.error,
      classes.green,
      classes.orange,
      classes.playerName,
      classes.small,
      t,
    ]
  );

  const changePage = async (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
    try {
      if (player?.id) {
        await dispatch(
          getPlayerTransactionsList(
            player?.id,
            newPage,
            storeFilter || undefined,
            dateFilter?.from as Date,
            dateFilter?.to as Date,
            moneyFilter["1k"] ? 1000 : moneyFilter["15k"] ? 15000 : undefined
          )
        );
      }
    } catch (err: any) {
      handleError(err?.status, setError);
    }
  };

  useEffect(() => {
    onFilterChange?.({
      minDate: dateFilter?.from,
      maxDate: dateFilter?.to,
      minSumPerShift: moneyFilter["15k"]
        ? 15000
        : moneyFilter["1k"]
        ? 1000
        : undefined,
      storeId: storeFilter,
    });
  }, [moneyFilter, dateFilter, storeFilter, onFilterChange]);

  return (
    <Paper elevation={3} className={classes.tablePaper}>
      <AdminTableFilters
        storeFilter={storeFilter}
        setStoreFilter={setStoreFilter}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        moneyFilter={moneyFilter}
        setMoneyFilter={setMoneyFilter}
      />
      <PlayersTable
        columns={columns}
        data={tableData}
        activePage={currentPage}
        pages={pages}
        changePage={changePage}
        isLoading={!!transactionsState.isLoading}
      />
    </Paper>
  );
};

export default TableWrapper;
