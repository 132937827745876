import { makeStyles } from "@material-ui/core/styles";

export const useTransactionEditDialogStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: "center",
    paddingBottom: 0,
    fontSize: 32,
  },
  dialogContent: {
    minWidth: 460,
    paddingBottom: theme.spacing(2),
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dialogClose: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: "#CECECE",
    fontSize: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  button: {
    marginTop: theme.spacing(2),
    minHeight: 50,
    minWidth: 340,
    color: "#fff",
    fontSize: 14,
  },
  noButton: {
    minHeight: 50,
    minWidth: 150,
  },
  yesButton: {
    minHeight: 50,
    minWidth: 150,
    color: "#fff",
  },
  cancelledBigIcon: {
    fontSize: 62,
    color: "#FFC107",
    marginBottom: theme.spacing(1.5),
  },
}));
