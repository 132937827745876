import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  Box,
} from "@material-ui/core";
import { useConfirmModalStyles } from "./ConfirmModal.styles";
import { Close } from "@material-ui/icons";

interface IEventWinnerConfirm {
  open: boolean;
  title?: JSX.Element;
  onConfirm: () => void;
  onClose: () => void;
  hideButtons?: boolean;
}
const ConfirmModal: React.FC<IEventWinnerConfirm> = ({
  open,
  title,
  onConfirm,
  onClose,
  hideButtons,
  children,
}) => {
  const { t } = useTranslation();
  const classes = useConfirmModalStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <Close onClick={onClose} className={classes.dialogClose} />
      {title && (
        <DialogTitle disableTypography className={classes.dialogTitle}>
          {title}
        </DialogTitle>
      )}
      <DialogContent className={classes.confirmDialog}>
        {children}
        {!hideButtons && (
          <Box mt={2} mb={4.5}>
            <Grid container justify="space-around">
              <Button
                disableElevation
                variant="contained"
                className={clsx(classes.redButton)}
                size="small"
                onClick={onClose}
              >
                <Typography style={{ paddingTop: 4 }} variant="caption">
                  {t("no")}
                </Typography>
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={classes.button}
                size="small"
                onClick={onConfirm}
              >
                <Typography style={{ paddingTop: 4 }} variant="caption">
                  {t("yes")}
                </Typography>
              </Button>
            </Grid>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;
